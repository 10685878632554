import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Label, Input, Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { isAR, lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import { toast } from 'react-toastify';
import Confirm from '../alert/confirm';
import RadioBox from '../common/Radiobox';
import { CALENDAR_TYPES, DatePicker } from '../common/DatePicker';
import { Select } from '../common/FormSelect';
import { safeReq } from '../../utils/request';
import { date } from '../../utils';
import { useMemo } from 'react';
import Loader from '../common/Loader';

export const Holiday = ({ className, deleteHoliday, updateHolidays, holidays, data = {} }) => {
  const { selectedStore } = useContext(AppContext);
  const [name, setName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [isAnnually, setAnnually] = useState(false);
  const [calendar, setCalendar] = useState();
  const allServicesOption = useMemo(() => ({ value: 0, label: lang.holiday.allServices }), []);
  const [loading, setLoading] = useState(true);
  const isNewHoliday = !data.id;

  const fetchServices = useCallback(() => {
    safeReq(async () => {
      let {
        data: { data: res },
      } = await request.GET(APIs.STORE_SERVICES, { branch_id: selectedStore.id });
      res = res.map(ser => ({ label: isAR ? ser.name.ar : ser.name.en, value: ser.id }));
      const selectedServices = data?.services?.map(ser => ({ label: isAR ? ser.name.ar : ser.name.en, value: ser.id }));
      setServices([allServicesOption, ...res]);
      setSelectedServices(selectedServices?.length ? selectedServices : [allServicesOption]);
      setLoading(false);
    });
  }, [allServicesOption, data.services, selectedStore.id]);

  useEffect(() => {
    // this functions runs even on adding a new holiday; in order to stop it
    // we need to not change the selected store's holidays reference
    fetchServices();
  }, [fetchServices]);

  useEffect(() => {
    if (!isNewHoliday) {
      setName(data.name || '');
      setAnnually(data['every_year']);
      const isHijri = data['is_hijri'];
      const start = isHijri ? date.toGregorianDate(data.start) : new Date(data.start);
      const end = isHijri ? date.toGregorianDate(data.end) : new Date(data.end);
      setCalendar(data['is_hijri'] ? CALENDAR_TYPES.HIJRI : CALENDAR_TYPES.GREGORIAN);
      start && setStartDate(start);
      end && setEndDate(end);
      const services =
        data.services && data.services.length
          ? data.services.map(ser => ({ label: isAR ? ser.name.ar : ser.name.en, value: ser.id }))
          : [allServicesOption];
      setServices(services);
    }
  }, [fetchServices, data, isNewHoliday, allServicesOption]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDateChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onServiceChange = services => {
    services = services || [];
    const isAllServicesOptionSelected = services[services.length - 1]?.value === allServicesOption.value;
    if (isAllServicesOptionSelected) {
      setSelectedServices([allServicesOption]);
    } else {
      setSelectedServices(services.filter(ser => ser.value !== allServicesOption.value));
    }
  };

  const fetchAppointments = useCallback(async () => {
    const {
      data: { data: appointments },
    } = await request.GET(APIs.APPOINTMENTS, {
      branch_id: selectedStore.id,
      from: date.format(startDate, 'yyyy-MM-dd'),
      to: date.format(endDate, 'yyyy-MM-dd'),
    });
    return appointments;
  }, [startDate, endDate, selectedStore.id]);

  const submit = useCallback(async () => {
    const isHijri = calendar === CALENDAR_TYPES.HIJRI;
    const start = isHijri ? date.toHijriDate(startDate) : date.format(startDate, 'yyyy-MM-dd');
    const end = isHijri ? date.toHijriDate(endDate) : date.format(endDate, 'yyyy-MM-dd');

    // check if shift exists
    const foundStart = holidays.some(el => el.start === start);
    const foundEnd = holidays.some(el => el.end === end);

    if (foundStart && foundEnd) {
      toast.error(lang.holiday.repeatedError);
      return;
    }
    const body = {
      branch_id: selectedStore.id,
      id: data.id,
      name,
      start,
      end,
      is_hijri: isHijri,
      every_year: !!isAnnually,
      service_id: selectedServices[0].value === 0 ? [] : selectedServices.map(ser => ser.value),
    };
    try {
      if (!isNewHoliday) {
        body.id = data.id;
        const res = await request.PUT(APIs.HOLIDAY, body);
        updateHolidays(res.data.data);
        toast.success(lang.holiday.updated);
      } else {
        const res = await request.POST(APIs.STORE_HOLIDAYS, body);
        updateHolidays(res.data.data);
        toast.success(lang.holiday.created);
      }
      setIsModalOpen(false);
    } catch (e) {
      console.log({ e });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendar,
    data.id,
    endDate,
    isAnnually,
    isNewHoliday,
    name,
    selectedServices,
    selectedStore.id,
    startDate,
    updateHolidays,
  ]);

  const onSaveClick = useCallback(async () => {
    const appointments = await fetchAppointments();
    let activeAppointments = appointments.filter(appointment =>
      ['confirmed', 'pending', 'accepted'].includes(appointment.status)
    );
    const allServiceSelected =
      selectedServices.filter(s => s.value === 0).length || services.length - 1 === selectedServices.length;
    if (!allServiceSelected) {
      activeAppointments = activeAppointments.filter(appointment =>
        selectedServices.find(s => s.value === appointment.service.id)
      );
    }

    if (activeAppointments.length) {
      setIsModalOpen(true);
    } else {
      submit();
    }
  }, [fetchAppointments, submit, selectedServices, services.length]);

  const onRemove = () => {
    isNewHoliday ? remove() : setDeleteAlert(true);
  };

  const remove = async () => {
    setDeleteAlert(false);
    if (!isNewHoliday) {
      await request.DELETE(APIs.HOLIDAY, { id: data.id });
      toast.success(lang.holiday.deleted);
    }
    deleteHoliday();
  };

  const validate = () => {
    return name && startDate && endDate && selectedServices.length;
  };

  const setIsAnnually = useCallback(() => {
    setAnnually(true);
  }, []);

  const setNotAnnually = useCallback(() => {
    setAnnually(false);
  }, []);

  const onCalendarChange = useCallback(calendar => {
    setCalendar(calendar);
  }, []);

  return (
    <article className={`px-3 pb-3 ${className} `}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="p-0 d-flex">
            <div className="col-12 col-md-5 my-4 mr-6">
              <div>
                <Label for={`name_${data.id}`}>{lang.holiday.name}*</Label>
                <Input onChange={e => setName(e.target.value)} id={`name_${data.id}`} value={name} />
              </div>
              <div className="mt-4">
                <Label for="services">{lang.holiday.services}*</Label>
                <Select
                  options={services}
                  id="services"
                  placeholder=""
                  isMulti
                  value={selectedServices}
                  onChange={onServiceChange}
                />
              </div>
              <div className="mt-4">
                <Label for={`name_${data.id}`}>{lang.holiday.isAnnually}</Label>
                <div className="d-flex">
                  <div onClick={setIsAnnually}>
                    <RadioBox checked={isAnnually} />
                    <Label for={`yes_option_${data.id}`} className="mx-2">
                      {lang.yes}
                    </Label>
                  </div>
                  <div className="mx-6" onClick={setNotAnnually}>
                    <RadioBox checked={!isAnnually} />
                    <Label for={`yes_option_${data.id}`} className="mx-2">
                      {lang.no}
                    </Label>
                  </div>
                </div>
                {isAnnually && calendar === CALENDAR_TYPES.HIJRI ? (
                  <small>*{lang.holiday.conversionDisclaimer}</small>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4 my-4">
              <div>
                <Label for={`name_${data.id}`}>{lang.holiday.date}*</Label>
                <DatePicker
                  selectsRange
                  selected={startDate}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  onCalendarChange={onCalendarChange}
                  calendar={calendar}
                  showHijriFooter
                  inline
                />
              </div>
            </div>
          </section>
          <section className="mt-4 p-0 justify-content-between d-flex flex-row-reverse">
            <Button color="primary" className="mr-2" type="submit" disabled={!validate()} onClick={onSaveClick}>
              {lang.btn.save}
            </Button>
            <Button color="secondary" className="mr-2" type="button" onClick={onRemove}>
              {lang.btn.remove}
            </Button>
          </section>
          <Confirm
            open={deleteAlert}
            confirm={remove}
            cancel={() => setDeleteAlert(false)}
            title={lang.holiday.confirmationDelete}
            body={lang.holiday.confirmationDeleteMsg}
          />
          <Modal className="text-center" isOpen={isModalOpen} centered={true}>
            <ModalHeader className="d-flex justify-content-center">{lang.holiday.cancelAppointmentTitle}</ModalHeader>
            <ModalBody>{lang.holiday.cancelAppointmentMsg}</ModalBody>
            <ModalFooter className="justify-content-center">
              <Button color="primary" className="px-4 py-2 mx-2" onClick={submit}>
                {lang.yes}
              </Button>
              <Button
                className="px-4 py-2 mx-2"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                {lang.cancel}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </article>
  );
};
