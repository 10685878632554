import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardBody, Card, Button } from 'reactstrap';
import AddForm from '../../components/invitations/Form';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Invitee } from '../../components/invitations/Invitee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/common/Loader';

export default () => {
  library.add(faPlus);
  library.add(faMinus);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, control} = useForm({
    defaultValues: { invitee: [{ name: '', email: '', mobile: '' }] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitees',
  });

  const addInvitee = useCallback(() => append({ name: '', email: '', mobile: '' }), [append]);

  useEffect(() => {
    !fields.length && addInvitee();
  }, [fields, addInvitee]);

  const onSubmitData = async ({ invitees, service, time, date, ...rest }) => {
    try {
      const promises = invitees.map(({ mobile, ...inv }) =>
        request.POST(APIs.INVITATIONS, {
          service_id: service.value,
          datetime: `${date} ${time}`,
          mobile: mobile,
          ...rest,
          ...inv,
        })
      );
      setLoading(true);
      await Promise.all(promises);
      setLoading(false);
      history.push('/invitations');
      toast.success(lang.invitations.addInvitation.success);
    } catch (e) {
      // skipping catch because of postmark email provider error
      history.push('/invitations');
      setLoading(false);
    }
  };

  return (
    <div className="schedule">
      <>
        <Card>
          <CardBody>
            <div>
              <AddForm errors={errors} register={register} control={control} />
            </div>
          </CardBody>
        </Card>
        <div className="mt-4">
          <h5 className="text-primary mb-2">{lang.invitations.labels.invitees}</h5>
          {fields.map((item, index) => (
            <Invitee key={item.id} index={index} register={register} errors={errors} remove={remove} />
          ))}
          <div className="d-flex justify-content-center align-items-center mt-3 py-3">
            <Button
              className="circular-plus d-flex justify-content-center align-items-center bg-white m-auto border-0 text-primary"
              onClick={addInvitee}
            >
              <FontAwesomeIcon icon={['fas', 'plus']} />
            </Button>
          </div>
        </div>

        <div className="mt-3 py-3 d-flex flex-row-reverse">
          <Button
            onClick={handleSubmit(onSubmitData)}
            color="primary"
            className=" px-3 py-1 d-flex justify-content-center align-items-center"
            type="submit"
            transform="down-1 shrink-4"
            disabled={loading}
          >
            <span className="mx-2 d-inline-block ">{lang.btn.send}</span>
            {loading ? <Loader type="border" color={'white'} size="sm" style={{ marginBottom: 5 }} /> : null}
          </Button>
        </div>
      </>
    </div>
  );
};
