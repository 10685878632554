import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardTitle, CardSubtitle } from 'reactstrap';
import { theme } from '../../constants/theme';
import { Card } from '../common/Card';

export function StatusCard(props) {
  const [iconScale, setIconScale] = useState(1);
  useEffect(() => {
    setIconScale(0.7);
    setTimeout(() => setIconScale(1), 500);
  }, [props.value]);
  return (
    <Card
      fetching={props.loading}
      className={`statuses-card overflow-hidden p-xxl-4 p-xl-3 p-lg-2  ${props.className || ''}`}
    >
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex justify-content-between align-items-center title-container ">
            <CardTitle className="dashboard-card-title">{props.title}</CardTitle>
            <CardSubtitle className="ml-2 font-weight-bold dashboard-card-subtitle subtitle">
              {props.subTitle}
            </CardSubtitle>
          </div>
          <div>
            <div className="d-flex justify-content-start align-items-center">
              <div
                className="icon-container d-flex justify-content-center align-items-center"
                style={{ backgroundColor: props.color, transform: `scale(${iconScale})`, transition: '2s' }}
              >
                <Icon {...props} />
              </div>
              <CardTitle className="ml-3 value" style={{ color: props.color, marginBottom: 0, fontSize: '1.3rem' }}>
                {props.value}
              </CardTitle>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

const Icon = ({ icon, iconSize, iconType, style = {} }) =>
  iconType === 'svg' ? (
    <img src={icon} style={{ width: iconSize, height: iconSize * 1.2, ...style }} alt="icon" />
  ) : (
    <FontAwesomeIcon
      icon={icon}
      className="icon"
      style={{ fontSize: iconSize, color: theme.whiteColor, textAlign: 'center' }}
    />
  );
