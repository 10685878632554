import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Activation from '../../components/configuration/Activation';
import AutoAccept from '../../components/configuration/AutoAccept';
import WalkinCapacity from '../../components/configuration/WalkinCapacity';
import { lang } from '../../lang';

export default () => (
  <div className="configuration">
    <Card>
      <CardBody className="px-5 pt-4 pb-5">
        <h4 className="mb-5 font-weight-bold">{lang.configuration.title}</h4>
        <Activation />
        <AutoAccept />
        <WalkinCapacity />
      </CardBody>
    </Card>
  </div>
);
