
import { Tokens } from "../types";
import { getSession } from "../session/session";

export const fetchCurrentUser = async (tokens?: Tokens) => {
  
  const accessToken = tokens?.access_token ?? (await getSession())?.access_token;

   const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/tenant/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data = await response.json();
  return {
    data,
  };
  
};
