import { toast } from "react-toastify";
import { lang } from "../lang";

export const safeReq = async (tryHandler, catchHandller, preventDefault) => {
  try {
    await tryHandler();
  } catch (err) {
    catchHandller && catchHandller(err);
    !preventDefault && toast.error(lang.defaultError);
    console.log(err);
  }
};
