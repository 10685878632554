import React from 'react';
import { isAR, lang } from '../../lang';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/morajea-logo-green.png';

function Footer() {
  return (
    <footer>
      <div className="white-bg h-100 px-6 footer-container">
        <div
          className="container d-flex flex-row flex-md-row justify-content-between align-items-center landing-footer"
          style={{ direction: 'rtl' }}
        >
          <div className="footer-img">
            <Link to="/">
              <img src={logo} style={{ height: '120px', width: '120px', borderRadius: '5px' }} alt="Morajea logo" />
            </Link>
            {/* <h4 className="text-black">{lang.yasser.landing.header.logo}</h4> */}
          </div>
          <div className="d-flex flex-row flex-md-row justify-content-between align-items-center footer-links">
            <Link to="/auth/login" target="_blank" className="mx-3">
              {isAR ? 'تسجيل الدخول' : 'Login'}
            </Link>

            {/* <a
              href="https://www.my.gov.sa/wps/portal/snp/main"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3"
            >
              {lang.yasser.landing.footer.gov}
            </a> */}
            {/* <a
              href="https://appointments.tamkeentech.sa/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-3"
            >
              {lang.yasser.landing.footer.policy}
            </a> */}
            <Link to="/privacy-policy" className="mx-3">
              {lang.yasser.landing.footer.policy}
            </Link>
            <Link to="/faq" className="mx-3">
              {lang.yasser.landing.footer.faq}
            </Link>
            <Link to="/contact"> {lang.yasser.landing.footer.contact}</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
