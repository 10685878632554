import axios from 'axios';
import { AuthHelpers } from '../utils';
import { lang, isEN } from '../lang';
import APIs from './apis';
import { getSession } from '../lib/auth/session/session';
import { toast } from 'react-toastify';
const BASES_URLS = {
  BASE: process.env.REACT_APP_BASE_URL,
};

const methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

let session = null;
const initializeSession = async () => {
  const Session = await getSession();
  session = Session?.access_token;
};
initializeSession();

axios.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = isEN ? 'en' : 'ar';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    const token = response.headers['x-auth-token'];

    if (token) {
      AuthHelpers.setToken(token);
    }
    return response;
  },
  error => {
    if (error.config && error.config.url.includes('statistics/appointments')) {
      return null;
    }
    if (error?.response?.status === 401) {
      AuthHelpers.logout();
    } else if (error?.response?.status === 403) {
      // TODO: redirect to forbidden page
      console.log('FORBIDDEN');
    } else if (error?.response?.data) {
      toast.error(error?.response?.data?.message);
    } else if (error?.response) {
      toast.error(`BACKEND ERROR: ${error?.response?.status}`);
    } else {
      toast.error(lang.defaultError);
    }

    return Promise.reject(error);
  }
);

const templateEngine = (template, data) => {
  const re = /{([^}]*)?}/;
  let match = re.exec(template);
  let tpl = template;
  while (match) {
    if (match[0] && !data[match[1]]) {
      tpl = tpl.replace(`${match[1]}=`, '');
    }
    tpl = tpl.replace(match[0], data[match[1]] || '');
    match = re.exec(tpl);
  }
  return tpl;
};

const send = (method, url, d, customHeaders) => {
  if (!url) {
    throw new Error('URL PASSED TO THE REQUEST METHOD IS UNDEFINED. HINT: MAKE SURE THE SPELLING IS CORRECT');
  }

  let templateData = {};
  if (d instanceof FormData) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of d.keys()) {
      templateData[key] = d.get(key);
    }
  } else {
    templateData = d;
  }

  const data = d || {};
  const fullURL = templateEngine(url, { ...templateData, ...BASES_URLS });
  const token = session;
  const headers = {
    Accept: 'application/json',
    'x-auth-token': token,
    authorization: `Bearer ${token}`,
    ...customHeaders,
  };

  if (method === methods.POST || method === methods.PUT || method === methods.PATCH) {
    return axios[method](fullURL, data, { headers });
  }
  return axios[method](fullURL, { headers });
};

const sendPost = (url, data, headers) => send(methods.POST, url, data, headers);

const sendGet = (url, params) => send(methods.GET, url, params);

const sendPut = (url, data) => send(methods.PUT, url, data);

const sendDelete = (url, params) => send(methods.DELETE, url, params);

const sendPatch = (url, data) => send(methods.PATCH, url, data);

export const prepareURL = (url, d) => {
  const data = d || {};
  let fullURL = templateEngine(url, { ...data, ...BASES_URLS });
  return fullURL;
};

export const getErrors = messages => {
  const errors = [];
  if (messages) {
    Object.keys(messages).forEach(key => {
      const category = messages[key];
      if (Array.isArray(category)) {
        category.forEach(msg => {
          errors.push(msg);
        });
      } else {
        errors.push(category);
      }
    });
  }
  return errors;
};

export const request = {
  GET: sendGet,
  POST: sendPost,
  PUT: sendPut,
  DELETE: sendDelete,
  PATCH: sendPatch,
};

export { APIs };
