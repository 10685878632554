import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { range, d3 } from '../../utils';

const height = 100;
const width = 500;
const barWidth = width / 90;
const barColor = 'rgb(113,202,186)';
const translateX = barWidth / 2; // to center the bar on the tick
const axisMarginTop = 5;
const margin = { top: 0, bottom: 30, right: 20, left: 20 };
const borderRadius = 4;

export const GongestionGraph = ({ data = range(24).map(() => 0) }) => {
  const axisRef = useRef();
  const svgRef = useRef();
  const max = useMemo(() => {
    const max = d3.max(data, d => d);
    return !max || max === -1 ? 10 : max;
  }, [data]);

  /* SCALES */
  const xScale = useCallback(
    d3
      .scaleLinear()
      .domain([-1, 24])
      .range([margin.left, width - margin.right]),
    []
  );
  const yScale = useCallback(
    d3
      .scaleLinear()
      .domain([-1, max]) // starting from -1 so that we show small bar if value is zero
      .range([margin.top, height - margin.bottom]),
    [max]
  );
  const opacityScale = useCallback(
    d3
      .scaleLinear()
      .domain([-1, max]) // starting from -1 so that we show small bar if value is zero
      .range([0, 1]),
    [max]
  );

  const xAxis = useCallback(
    d3
      .axisBottom(xScale)
      .ticks(24)
      .tickFormat((_, i) => {
        let tick = i - 1;

        if (tick === -1) {
          return 'AM';
        }
        if (tick === 24) {
          return 'PM';
        }
        if (tick % 12 === 0) {
          return 12;
        }
        return tick % 12;
      }),
    [data, xScale]
  );

  /* MAIN: rendering the bars */
  useEffect(() => {
    const t = d3
      .select(svgRef.current)
      .transition()
      .duration(1000);

    d3.select(svgRef.current)
      .selectAll('rect')
      .data(data, (d, i) => i)
      .join(
        enter => {
          return enter
            .append('rect')
            .attr('transform', (d, i) => `translate(${xScale(i) - translateX}, ${height - margin.bottom})`)
            .attr('width', barWidth)
            .attr('height', d => 0)
            .attr('fill-opacity', 0);
        },
        update => update,
        exit => {
          exit
            .transition(t)
            .attr('transform', (d, i) => `translate(${xScale(i) - translateX}, ${height - margin.bottom})`)
            .attr('height', 0)
            .attr('fill-opacity', 0);
        }
      )
      .transition(t)
      .attr(
        'transform',
        (d, i) =>
          `translate(${xScale(i) - translateX}, ${height - (!d ? Math.min(4, yScale(d)) : yScale(d)) - margin.bottom})`
      )
      .attr('width', barWidth)
      .attr('height', d => (!d ? Math.min(5, yScale(d)) : yScale(d)))
      .attr('fill', barColor)
      .attr('fill-opacity', d => opacityScale(d))
      .attr('ry', borderRadius)
      .attr('rx', borderRadius);

    d3.select(axisRef.current).call(xAxis);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, yScale, opacityScale]);

  return (
    <div
      className="congestion_container d-flex align-items-center justify-content-center"
      style={{ maxWidth: width, maxHeight: height }}
    >
      <svg viewBox={`0 0 ${width} ${height}`} className="congestion" ref={svgRef}>
        <g className="x_axis" ref={axisRef} transform={`translate(0, ${height - margin.bottom + axisMarginTop})`} />
      </svg>
    </div>
  );
};
