import DASHBOARD from './dashboard';

export default {
  API_TEST: `{BASE}/users`,
  CONTACT: `{BASE}/contact`,
  // LOGIN: `{BASE}/api/tenant/login`,
  NOTIFICATIONS: `{BASE}/owners/notifications`,
  CLEAR_NOTIFICATIONS: `{BASE}/owners/notifications/clear`,
  BRAND: `{BASE}/api/tenants`,
  STORES: `{BASE}/api/tenant/branches/{id}`,
  CREATE_STORE: `{BASE}/api/tenant/branches/`,
  WORKING_PLAN: `{BASE}/owners/brand/{brandId}/stores/{storeId}/working_plan`,
  APPOINTMENTS: `{BASE}/api/tenant/appointments?branch_id={id}&status={status}&from={from}&to={to}service_id={service_id}&pageSize={pageSize}&page={page}`,
  BRAND_APPTS: `{BASE}/owners/appointments?status={status}&from={from}&to={to}&per_page={per_page}`,
  ACCEPT_APPOINTMENT: `{BASE}/owners/appointments/{appointmentId}/accept`,
  REJECT_APPOINTMENT: `{BASE}/owners/appointments/{appointmentId}/reject`,
  ACCEPT_ALL_APPOINTMENTS: `{BASE}/api/tenant/appointments/accepted-all/{branch_id}`,
  CHANGE_APPOINTMENTS: `{BASE}/api/tenant/appointments/change-status/{appointmentId}`,
  STATISTICS_APPOINTMENTS: `{BASE}/api/tenant/appointments/statistics`,
  // STAFF: `{BASE}/api/tenant/employees/{id}`,
  STAFF: `{BASE}/api/tenant/employees/assign`,
  REMOVE_STAFF: `{BASE}/api/tenant/employees/unassign`,
  STORE_STAFF: `{BASE}/api/tenant/employees?branch_id={branch_id}&role_id={role_id}&pageSize={pageSize}&page={page}`,
  UPDATE_STORE: `{BASE}/api/tenant/branches/{id}`,
  STORE_STATUS: `{BASE}/api/tenant/branches/change-status/{id}`,
  UPLOAD_LOGO: `{BASE}/owners/brand/{brandId}/upload_logo`,
  UPDATE_BRAND: `{BASE}/owners/brand/{brandId}`,
  RESET_PASSWORD: `{BASE}/api/tenant/reset`,
  FORGET_PASSWORD: `{BASE}/api/tenant/forget`,
  STORE_SHIFTS: `{BASE}/api/tenant/shifts?branch_id={branch_id}`,
  SHIFT: `{BASE}/api/tenant/shifts/{id}`,
  SERVICES: `{BASE}/api/tenant/services/{service_id}`,
  STORE_SERVICES: `{BASE}/api/tenant/services?branch_id={branch_id}&pageSize={size}&page={page}`,
  PACKAGES: `{BASE}/owners/packages/{id}`,
  INVITATIONS: `{BASE}/api/tenant/invitations?branch_id={id}&pageSize={pageSize}&page={page}`,
  SERVICE_INVITATIONS: `{BASE}/owners/invitations`,
  USER_MANUALS: `{BASE}/files/manuals/{filename}?attachment={attachment}`,
  STORE_CONFIG: `{BASE}/owners/stores/{store_id}/config`,
  SERVICE_STATUS: `{BASE}/api/tenant/services/change-status/{service_id}`,
  INVITATIONS_STATUS: `{BASE}/api/tenant/invitations/update-status/{id}`,
  STORE_HOLIDAYS: `{BASE}/api/tenant/holidays?branch_id={branch_id}`,
  HOLIDAY: `{BASE}/api/tenant/holidays/{id}`,
  DASHBOARD,
  CONFIG: `{BASE}/api/tenant/configuration/update/{branch_id}`,
};
