import { Tokens } from "../types";
import { getSession } from "../session/session";
// @ts-ignore
import Cookies from "js-cookie";
export const logout = async (tokens?: Tokens) => {
  
  const accessToken = tokens?.access_token ?? (await getSession())?.access_token;
  
  localStorage.setItem('isLoggingOut', 'true');
  await fetch(`${process.env.REACT_APP_START_BACKEND_URL}/oauth/token`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
  Cookies.remove('session');
  window.location.href = `${process.env.REACT_APP_START_BACKEND_URL}/logout`;

};
