import React, { useState, useEffect, useContext } from 'react';
import { StatusCard } from '../appointments/StatusCard';
import { faCheckCircle, faTimesCircle, faClock, faBan, faCalendar, faWalking } from '@fortawesome/free-solid-svg-icons';
import { lang } from '../../lang';
import { APPOINTMENT_STATUS } from '../../constants';
import { date, groupBy } from '../../utils';
import { request, APIs } from '../../request';
import { toast } from 'react-toastify';
import AppContext from '../../context/Context';

const statuses = [
  {
    status: '',
    subTitle: 'perDay',
    value: '',
    icon: faCalendar,
    color: '#005a96',
  },
  {
    status: APPOINTMENT_STATUS.PENDING,
    subTitle: 'appointment',
    value: '',
    icon: faClock,
    color: '#329dec',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.CANCELED,
    value: '',
    icon: faTimesCircle,
    color: '#cdccd4',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.REJECTED,
    value: '',
    icon: faBan,
    color: '#444061',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.ACCEPTED,
    value: '',
    icon: faCheckCircle,
    color: '#28AA8C',
  },
  {
    subTitle: 'appointment',
    status: APPOINTMENT_STATUS.WALK_IN,
    value: '',
    icon: faWalking,
    color: '#4fcdba',
  },
];

export const AppointmentsStatuses = ({ data, className, loading }) => {
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [walkIn, setWalkIn] = useState(0);
  const { selectedStore } = useContext(AppContext);

  const renderValue = status => {
    switch (status) {
      case APPOINTMENT_STATUS.PENDING:
        return pending;
      case APPOINTMENT_STATUS.ACCEPTED:
        return accepted;
      case APPOINTMENT_STATUS.CANCELED:
        return canceled;
      case APPOINTMENT_STATUS.REJECTED:
        return rejected;
      case APPOINTMENT_STATUS.WALK_IN:
        return walkIn;
      default:
        return total;
    }
  };

  const fetchAppomintsState = async () => {
    try {
      const { data } = await request.POST(APIs.STATISTICS_APPOINTMENTS, {
        branch_id: selectedStore.id,
      });
      if (data.total) {
        setTotal(data.total.all);
        setPending(data.total.pending);
        setAccepted(data.total.accepted);
        setCanceled(data.total.canceled);
        setRejected(data.total.rejected);
        setWalkIn(data.total.walk_in);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAppomintsState('');
  }, [data]);
  return (
    <div className={className} style={{ margin: '0 -13px' }}>
      <div className="d-flex h-100 justify-content-between w-100 flex-wrap">
        {statuses.map(status => (
          <StatusCard
            title={lang.appointments[status.status] || lang.dashboardItems.appointments}
            subTitle={lang.dashboardItems[status.subTitle]}
            value={renderValue(status.status)}
            color={status.color}
            icon={status.icon}
            iconSize="27px"
            className="space-hlf-mx h-100"
            loading={loading}
          />
        ))}
      </div>
    </div>
  );
};
