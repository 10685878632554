import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { lang, isEN } from '../../lang';
import { Table } from '../common/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const PER_PAGE = 10;

export const ActivityTable = ({ data = [], loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState(data.slice(currentPage - 1, currentPage * PER_PAGE));
  const totalPages = useMemo(() => Math.floor(data.length / PER_PAGE) || 1, [data]);
  const columns = prepareColumns();

  const paginate = useCallback(page => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    setPageData(data.slice(currentPage - 1, currentPage * PER_PAGE));
  }, [data, currentPage]);

  return (
    <Table
      columns={columns}
      rows={pageData}
      loading={loading}
      page={currentPage}
      onPaginate={paginate}
      totalPages={totalPages}
    />
  );
};

const prepareColumns = () => [
  {
    dataField: `name${isEN ? '_en' : '_ar'}`,
    text: lang.dashboardItems.branchName,
    // classes: 'border-0 align-middle',
    // headerClasses: 'border-0',
    // sort: true,
  },
  {
    dataField: 'totalRejected',
    text: lang.appointments.rejected,
    formatter: (cell, record) => formatter({ now: cell, prev: record.totalPreviousRejected, isUpGood: false, record }),
    className: 'tbl-number',
  },
  {
    dataField: 'totalAccepted',
    text: lang.appointments.accepted,
    formatter: (cell, record) => formatter({ now: cell, prev: record.totalPreviousAccepted, isUpGood: true, record }),
    className: 'tbl-number',
  },
  {
    dataField: 'totalCanceled',
    text: lang.appointments.canceled,
    formatter: printCell,
    className: 'tbl-number',
  },
  {
    dataField: 'totalNoAction',
    text: lang.dashboardItems.noAction,
    formatter: printCell,
    className: 'tbl-number',
  },
];

const formatter = ({ now: originalNow, prev: originalPrev, isUpGood, record }) => {
  // replacing 0 with 1 for prev so we dont have inifinity number
  // if prev is 0 increase now by 1 so the increase precentage is more rational
  const prev = originalPrev || 1;
  const now = originalPrev ? originalNow : originalNow + 1;
  const isIncreasing = now > prev;
  const rate = Math.round((Math.abs(now - prev) / prev) * 100);
  // hiding the rate if rate is zero
  const shouldHideRate = rate === 0 || (now === 1 && prev === 1);

  return (
    <div>
      {printCell(originalNow, record)}
      <span
        className={classNames('mx-auto', {
          'dashboard-graph-numbers-green-small': (isIncreasing && isUpGood) || (!isIncreasing && !isUpGood),
          'dashboard-graph-numbers-red-small': (!isIncreasing && isUpGood) || (isIncreasing && !isUpGood),
        })}
      >
        {shouldHideRate ? '' : now > prev ? '+' : '-'}
        {shouldHideRate ? '' : `${rate}%`}
        {shouldHideRate ? null : (
          <FontAwesomeIcon className="mt-auto mx-1" icon={isIncreasing ? faCaretUp : faCaretDown} />
        )}
      </span>
    </div>
  );
};

const printCell = (cell, record) => <span className="mx-1">{Math.round((cell / record.totalAppts) * 100) || 0}%</span>;
