import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const RadioBox = ({ checked, className, onClick, value, ...rest }) => {
  const onSelect = useCallback(() => {
    onClick && onClick(value);
  }, [onClick, value]);

  return (
    <div className={classNames({ "radiobox-outer": true, className })} onClick={onSelect} {...rest}>
      <div className={classNames({ "radiobox-inner": checked })} />
    </div>
  );
};

RadioBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default RadioBox;
