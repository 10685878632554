import pkceChallenge from 'pkce-challenge';
import Cookies from 'js-cookie';

export const RedirectToSSOLogin = async () => {
  const { code_challenge, code_verifier } = pkceChallenge();
  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_NAWART_SSO_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    response_type: 'code',
    scope: '',
    state: 'random',
    code_challenge: code_challenge,
    code_challenge_method: 'S256',
  });

  const redirectUrl = new URL(`${process.env.REACT_APP_START_BACKEND_URL}/oauth/authorize?${params.toString()}`);
  Cookies.set('session', '', { expires: new Date(0) });
  Cookies.set('code_verifier', code_verifier, {
    secure: true,
    sameSite: 'Strict',
  });
  Cookies.set('redirect', '/dashboard', {
    secure: true,
    sameSite: 'Strict',
  });
  return redirectUrl.toString();
};
// import React, { useEffect } from 'react';
// import pkceChallenge from 'pkce-challenge';
// import Cookies from 'js-cookie';

// export const RedirectToSSOLogin = () => {
//   useEffect(() => {
//     performRedirect();
//     console.log('1');
//   }, []);
//   const performRedirect = async () => {
//     const { code_challenge, code_verifier } = pkceChallenge();
//     const params = new URLSearchParams({
//       client_id: process.env.REACT_APP_NAWART_SSO_CLIENT_ID,
//       redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
//       response_type: 'code',
//       scope: '',
//       state: 'random',
//       code_challenge: code_challenge,
//       code_challenge_method: 'S256',
//     });

//     const redirectUrl = `${process.env.REACT_APP_START_BACKEND_URL}/oauth/authorize?${params.toString()}`;
//     Cookies.set('session', '', { expires: new Date(0) });
//     Cookies.set('code_verifier', code_verifier, {
//       secure: true,
//       sameSite: 'Strict',
//     });

//     Cookies.set('redirect', window.location.pathname, {
//       secure: true,
//       sameSite: 'Strict',
//     });
//     // Redirect the user to the SSO login page
//     window.location.href = redirectUrl;
//   };

//   return null; // This component does not render anything
// };
