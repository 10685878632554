import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import classNames from 'classnames';

const GoogleMap = ({ className, children, markerLatLng, onCursorDragend, draggable, ...rest }) => {
  return (
    <div className={classNames('position-relative', className)}>
      <Map {...rest} draggable={draggable} streetViewControl={false} fullscreenControl={false}>
        <Marker draggable={draggable} position={markerLatLng} onDragend={onCursorDragend} />
      </Map>
    </div>
  );
};

GoogleMap.propTypes = {
  mapStyle: PropTypes.oneOf([
    'Default',
    'Gray',
    'Midnight',
    'Hopper',
    'Beard',
    'AssassianCreed',
    'SubtleGray',
    'Tripitty',
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  ...Map.propTypes,
};

GoogleMap.defaultProps = { zoom: 17, mapStyle: 'Default' };

// TODO: Do you provide the apiKey in production, instruct user to use his own apiKey
export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY })(GoogleMap);
