import { useEffect, useRef } from 'react';

export const usePrevious = val => {
  const prevRef = useRef();
  useEffect(() => {
    prevRef.current = val;
  });

  return prevRef.current;
};
