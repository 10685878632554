import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import { Alert, Card, CardBody, Button } from 'reactstrap';
import Loader from '../../components/common/Loader';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useHistory } from 'react-router-dom';
import StaffsTable from '../../components/staffs/StaffsTable';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import { ROLES } from '../../constants';
import { safeReq } from '../../utils/request';
import Confirm from '../../components/alert/confirm';
import { toast } from 'react-toastify';

const Stafss = () => {
  const history = useHistory();
  const [staffs, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const { selectedStore } = useContext(AppContext);
  const [staffsMeta, setStaffsMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { data },
    } = await request.GET(APIs.STORE_STAFF, {
      branch_id: selectedStore.id,
      role_id: 4,
      page: page,
      pageSize: pageSize,
    });
    setStaff(data?.data);
    setStaffsMeta(data);
    setLoading(false);
  }, [selectedStore.id, page, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onResetClicked = useCallback(email => {
    setShowResetAlert(true);
    setSelectedStaff(email);
  }, []);

  const resetModerator = useCallback(() => {
    safeReq(async () => {
      setShowResetAlert(false);
      setLoading(true);
      await request.POST(APIs.FORGET_PASSWORD, { email: selectedStaff });
      toast.success(lang.staffs.resetStaffSuccess);
      setLoading(false);
    });
  }, [selectedStaff]);

  const onDeleteClicked = useCallback(id => {
    setShowDeleteAlert(true);
    setSelectedStaff(id);
  }, []);

  const deleteModerator = useCallback(() => {
    safeReq(async () => {
      setShowDeleteAlert(false);
      setLoading(true);
      await request.POST(APIs.REMOVE_STAFF, { id: selectedStaff, branch_id: selectedStore.id });
      setStaff(staffs?.filter(el => el.id !== selectedStaff));
      toast.success(lang.staffs.deleteStaffSuccess);
      setLoading(false);
    });
  }, [staffs, selectedStaff]);

  return (
    <Fragment>
      <Card>
        <FalconCardHeader title={lang.staffs.staffs}>
          <Button color="primary" className="mr-2" onClick={() => history.push('/staffs/add')}>
            {lang.staffs.addStaff.title}
          </Button>
        </FalconCardHeader>
        <CardBody className="fs--1">
          {loading ? (
            <Loader />
          ) : staffs[0] ? (
            <StaffsTable
              staffs={staffs}
              onDeleteClicked={onDeleteClicked}
              onResetClicked={onResetClicked}
              staffsMeta={staffsMeta}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          ) : (
            <Alert color="info" className="mb-0">
              {lang.staffs.noStaffsFound}
            </Alert>
          )}
        </CardBody>
      </Card>
      <Confirm
        open={showDeleteAlert}
        confirm={deleteModerator}
        cancel={() => setShowDeleteAlert(false)}
        title={lang.staffs.deleteStaffConfirmationTitle}
        body={lang.staffs.deleteStaffConfirmation}
      />
      <Confirm
        open={showResetAlert}
        confirm={resetModerator}
        cancel={() => setShowResetAlert(false)}
        title={lang.staffs.resetStaffConfirmationTitle}
        body={lang.staffs.resetStaffConfirmation}
      />
    </Fragment>
  );
};

export default Stafss;
