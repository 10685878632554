import ar from "./ar.json";
import en from "./en.json";
import { DEFAULT_LANGUAGE } from "../constants";

const langs = { ar, en };
/* eslint-disable import/no-mutable-exports */

const initial_language = localStorage.getItem("lang");

let parsed_initial_language;

try {
  parsed_initial_language = JSON.parse(initial_language);
} catch (err) {
  parsed_initial_language = initial_language;
}

let lang = langs[DEFAULT_LANGUAGE];
let isAR = parsed_initial_language ? parsed_initial_language === "ar" : DEFAULT_LANGUAGE === "ar";
let isEN = parsed_initial_language ? parsed_initial_language === "en" : DEFAULT_LANGUAGE === "en";

const setLanguage = lg => {
  lang = langs[lg];
  isAR = lg === "ar";
  isEN = lg === "en";
  if (isAR) {
    document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    document.getElementById("main").style.fontFamily = "FrutigerLight";
    document.getElementById("main").style.textAlign = "right";
    document.getElementById("main").dir = "rtl";
  } else {
    document.getElementsByTagName("html")[0].setAttribute("lang", "en");
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    document.getElementById("main").style.fontFamily = '"Open Sans", sans-serif';
    document.getElementById("main").style.textAlign = "left";
    document.getElementById("main").dir = "ltr";
  }
};

// TODO: CHANGE IT TO REGEX
const interpolate = (path, obj) => {
  if (!path) {
    /* eslint-disable */
    console.error("Path paramter inside interpolate function is not a string, Path: ", path);
  }
  let branch = lang;
  let subPath = path;
  let hasNext = subPath.indexOf(".") > -1;
  while (hasNext) {
    const key = subPath.slice(0, subPath.indexOf("."));
    branch = branch[key];
    if (!branch) {
      return "الترجمة غير متاحة";
    }
    subPath = subPath.slice(subPath.indexOf(".") + 1);
    hasNext = subPath.indexOf(".") > -1;
  }
  branch = branch[subPath] || subPath;

  let interpolatedString = branch;
  if (obj) {
    Object.keys(obj).forEach(key => {
      interpolatedString = interpolatedString.replace(`{{${key}}}`, obj[key]);
    });
  }
  return interpolatedString;
};

export { lang, setLanguage, isAR, isEN, interpolate };
