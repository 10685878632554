import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { fetchAccessToken } from './api/fetchAccessToken';
import { fetchCurrentEmployee } from './api/fetchCurrentEmployee';
import { fetchCurrentUser } from './api/fetchCurrentUser';
import { encrypt } from './session/encryption';
import { getSession } from './session/session';
export const handleAuthCallback = async code => {
  try {

    const tokens = await fetchAccessToken({
      type: 'fetch',
      code: code,
      code_verifier: Cookies.get('code_verifier'),
    });

    if (!tokens) {
      throw new Error('fetching_tokens_failed');
    }


    const { data: employee } = await fetchCurrentEmployee(tokens);
    const { data: user } = await fetchCurrentUser(tokens);
    employee.role = user?.roles?.name?.en;
    localStorage.setItem('user', JSON.stringify(employee));
    if (!employee) {
      throw new Error('employee_not_found');
    }

    Cookies.set(
      'session',
      await encrypt({
        access_token: tokens.access_token,
        refresh_token: tokens.refresh_token,
        token_expires_at: dayjs()
          .add(tokens.expires_in, 'second')
          .toDate(),
      }),
      {
        expires: dayjs()
          .add(1, 'year')
          .toDate(),
        secure: true,
      }
    );
    Cookies.remove('code_verifier');

    const redirectUrl = Cookies.get('redirect') || '/';
    Cookies.remove('redirect');

    window.location.href = redirectUrl;
  } catch (e) {
    console.error(e);
    // window.location.href = `/auth/error?error=${e.message || 'unknown_error'}`;
  }
  return null;
};
