import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import addHours from "date-fns/addHours";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import isToday from "date-fns/isToday";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import formatRelative from "date-fns/formatRelative";
import getDaysInMonth from "date-fns/getDaysInMonth";
import setMonth from "date-fns/setMonth";
import ar from "date-fns/locale/ar-SA";
import { enUS, arSA } from "date-fns/locale";
import { isAR } from "../lang";
import "hijri-date";

class CustomDate {
  getSafariFriendlyDateSting = (dateString) =>
    /^....-..-.. ..:..:..$/g.test(dateString) ? dateString.replace(/-/g, "/") : dateString;

  format = (date, str, no_trans) => format(date, str, isAR && !no_trans ? { locale: ar } : {});

  addDays = (date, num, str) => (str ? this.format(addDays(date, num), str) : addDays(date, num));

  subDays = (date, num, str) => (str ? this.format(subDays(date, num), str) : subDays(date, num));

  subMonths = (date, num, str) => (str ? this.format(subMonths(date, num), str) : subMonths(date, num));

  addMonths = (date, num, str) => (str ? this.format(addMonths(date, num), str) : addMonths(date, num));

  isBefore = (date1, date2) => isBefore(date1, date2);

  isAfter = isAfter;

  differenceInDays = differenceInDays;

  differenceInHours = differenceInHours;

  differenceInMinutes = differenceInMinutes;

  isToday = isToday;

  addHours = addHours;

  getDaysInMonth = getDaysInMonth;

  setMonth = setMonth;

  setStartOfDay = (date) => new Date(date.setHours(0, 0, 0, 0));

  setEndOfDay = (date) => new Date(date.setHours(23, 59, 59, 999));

  locale = {
    ar: arSA,
    en: enUS,
  };

  diff = (date1, date2) => differenceInMilliseconds(date1, date2);

  formatRelative = (date1, date2) => formatRelative(date1, date2, isAR ? { locale: ar } : {});

  createDate = (str) => (str ? new Date(str.replace(/-/g, "/")) : new Date());

  toHijriDate = (date) => {
    const hijriDate = new Date(date).toHijri();
    const day = String(hijriDate.date).padStart(2, "0");
    const month = String(hijriDate.month).padStart(2, "0");
    const year = String(hijriDate.year);
    // eslint-disable-next-line no-undef
    return `${year}-${month}-${day}`;
  };

  toGregorianDate = (date) => {
    // eslint-disable-next-line no-undef
    return new HijriDate(date).toGregorian();
  };
}

export const date = new CustomDate();

export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
