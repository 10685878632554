import React from "react";

const Tabs = ({ tabs = [], onTabChange, selectedTabIndex, className }) => (
  <div className={`tabs d-flex ${className || ""}`}>
    {tabs.map((tab, index) => (
      <>
        <span className="mx-4" />
        <div className="text-center" onClick={() => onTabChange(index)}>
          <span className={`text-center ${selectedTabIndex === index ? "selected" : ""}`}>{tab}</span>
        </div>
      </>
    ))}
  </div>
);

export default Tabs;
