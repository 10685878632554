import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, Label } from 'reactstrap';
import WizardInput from '../../components/auth/wizard/WizardInput';
import { lang } from '../../lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Invitee = ({ register, errors: formErrors, remove, index }) => {
  const [errors, setErrors] = useState({});
  const nameLabel = useMemo(() => `invitees[${index}].name`, [index]);
  const emailLabel = useMemo(() => `invitees[${index}].email`, [index]);
  const mobileLabel = useMemo(() => `invitees[${index}].mobile`, [index]);

  useEffect(() => {
    if (formErrors.invitees && formErrors.invitees.length) {
      const errs = formErrors.invitees[index];
      errs
        ? setErrors({ [nameLabel]: errs.name, [emailLabel]: errs.email, [mobileLabel]: errs.mobile })
        : setErrors({});
    } else {
      setErrors({});
    }
  }, [formErrors, index, nameLabel, emailLabel, mobileLabel]);

  const removeInvitee = useCallback(() => remove(index), [index, remove]);
  return (
    <Card className="mt-3">
      <CardBody>
        <div className="position-absolute px-4 w-100 d-flex flex-row-reverse h-0" style={{ top: 10 }}>
          <Button className="bg-transparent px-1 text-light border-0" title={lang.btn.remove} onClick={removeInvitee}>
            <FontAwesomeIcon transform="shrink-2" icon="minus" />
          </Button>
        </div>
        <article className="p-3 row">
          <div className="d-inline-block col-12 col-sm-4">
            <Label for={nameLabel}>{lang.invitations.labels.name}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id={nameLabel}
              name={nameLabel}
              errors={errors}
              innerRef={register({
                required: lang.requiredField,
              })}
            />
          </div>
          <div className="d-inline-block col-12 col-sm-4">
            <Label for={emailLabel}>{lang.invitations.labels.email}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id={emailLabel}
              name={emailLabel}
              errors={errors}
              innerRef={register({
                required: lang.requiredField,
                pattern: {
                  value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
                  message: lang.validationMessage.entervalidEmail,
                },
              })}
            />
          </div>
          <div className="d-inline-block col-12 col-sm-4">
            <Label for={mobileLabel}>{lang.invitations.labels.mobile_phone}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id={mobileLabel}
              name={mobileLabel}
              errors={errors}
              innerRef={register({
                required: lang.requiredField,
                pattern: {
                  value: /^(05)[0-9]{8}$/,
                  message: lang.validationMessage.mobileStart05,
                },
              })}
            />
          </div>
        </article>
      </CardBody>
    </Card>
  );
};
