import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input } from 'reactstrap';
import { IconDown, IconClose } from '../../utils';

const FilterDropdown = ({ selected, onChange, options }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal d-flex position-relative p-0">
        <Input disabled value={selected || ''} />
        <IconDown className="dropdown-arrow" />
        {selected ? <IconClose className="dropdown-close" onClick={() => onChange({ label: undefined })} /> : null}
      </DropdownToggle>
      <DropdownMenu right className="border py-1">
        {options.map((el, index) => (
          <React.Fragment key={el.id}>
            <DropdownItem onClick={() => onChange(el)}>{el.label}</DropdownItem>
            {index !== options.length - 1 ? <DropdownItem divider /> : null}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FilterDropdown;
