import React, { useContext, useEffect, useRef } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../common/Flex';
import prepareRoutes from '../../routes';
import { navbarBreakPoint } from '../../config';
import { ROLES } from '../../constants';
import Avatar from '../common/Avatar';
// import YesserLogoImage from '../../assets/img/yesser-logo-full-w.svg';
import morajeaLogo from '../../assets/img/nawart_logo.png';
import brandPlaceHolderImage from '../../assets/img/store-placeholder.jpg';
import { isAR, lang } from '../../lang';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const NavbarVertical = () => {
  const navBarRef = useRef(null);
  const {
    isRTL,
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    selectedStore,
    user,
    brand,
  } = useContext(AppContext);

  const viewMenuAs =
    user?.roles?.some(role => role?.name === ROLES.ADMIN) && !selectedStore.id
      ? ROLES.ADMIN
      : user?.roles?.some(role => role?.name === ROLES.ADMIN)
      ? ROLES.MANAGER
      : user.role;
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);
  return (
    <Navbar expand={navbarBreakPoint} className="navbar-vertical h-auto h-xl-100 ">
      <Flex align="center" className="ml-3 d-flex d-xl-none">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
      </Flex>

      <Collapse navbar isOpen={showBurgerMenu} innerRef={navBarRef}>
        <Scrollbar
          style={{ height: 'calc(100vh - 90px)', display: 'block' }}
          rtl={isRTL}
          trackYProps={{
            renderer(props) {
              const { elementRef, ...restProps } = props;
              return <span {...restProps} ref={elementRef} className="TrackY" />;
            },
          }}
        >
          <Nav navbar vertical>
            <div className="navbar-info mb-5 mt-6 mt-xl-4 d-flex flex-column gap-2">
              <div>
                {selectedStore.id ? (
                  <Link to="/manager-information">
                    <Avatar size="sm" src={brand?.logo?.file_url || brandPlaceHolderImage} className="bg-white p-1" />
                  </Link>
                ) : (
                  <Link to="/brand-information">
                    <Avatar size="sm" src={brand?.logo?.file_url || brandPlaceHolderImage} className="bg-white p-2" />
                  </Link>
                )}
              </div>
              <div className="mx-2 d-flex justify-content-center align-items-center flex-column">
                <p className="my-1 font-weight-bold">{user?.name}</p>
                <p className="mb-0">{selectedStore.id ? selectedStore.name[isAR ? 'ar' : 'en'] : lang.admin}</p>

                <div
                  className="d-flex justify-content-start align-items-center mt-2"
                  style={{ visibility: selectedStore.id ? 'visible' : 'hidden' }}
                >
                  <FontAwesomeIcon
                    color={selectedStore?.configuration?.branch_status ? '#28AA8C' : '#D63737'}
                    icon={faCircle}
                    style={{ fontSize: 9 }}
                  />
                  <div className="align-self-center mx-1" style={{ fontSize: 9 }}>
                    {selectedStore?.configuration?.branch_status
                      ? lang.dashboardItems.active
                      : lang.dashboardItems.inactive}
                  </div>
                </div>
              </div>
            </div>
            <NavbarVerticalMenu routes={prepareRoutes(viewMenuAs)} />
          </Nav>
        </Scrollbar>
      </Collapse>
      <div id="yesser-logo">
        <img src={morajeaLogo} alt="logo" />
      </div>
    </Navbar>
  );
};

export default NavbarVertical;
