import React, { useCallback, useEffect, useState } from 'react';
import { lang } from '../../lang';
import { SectionTitle } from '../common/sectionTitle';
import { InformationItem } from './information_item';
import StoreLocation from '../stores/StoreLocation';

export const BranchInformationSection = ({ branch, editMode, setInfo }) => {
  const [enName, setEnName] = useState(branch.name.en);
  const [name, setName] = useState(branch.name.ar);
  const [city, setCity] = useState(branch.city);
  const [district, setDistrict] = useState(branch.district);
  const [latLng, setLatLng] = useState({ lat: branch.lat, lng: branch.lng });
  const region = branch.region;
  useEffect(() => {
    setInfo({ enName, name, region, city, district, latLng });
  }, [enName, name, city, district, latLng, setInfo]);

  const onNameChange = useCallback(e => setName(e.target.value), []);
  const onNameEnChange = useCallback(e => setEnName(e.target.value), []);
  const onCityChange = useCallback(e => setCity(e.target.value), []);
  const onDistrictChange = useCallback(e => setDistrict(e.target.value), []);

  return (
    <div>
      <SectionTitle className="mb-4">{lang.stores.informations.title}</SectionTitle>
      <InformationItem
        label={lang.brandInformation.enName}
        value={enName}
        className="mb-2"
        editMode={editMode}
        onChange={onNameEnChange}
      />
      <InformationItem
        label={lang.brandInformation.arName}
        value={name}
        className="mb-2"
        editMode={editMode}
        onChange={onNameChange}
      />
      <InformationItem
        label={lang.stores.informations.city}
        value={city}
        className="mb-2"
        editMode={editMode}
        onChange={onCityChange}
      />
      <InformationItem
        label={lang.stores.informations.district}
        value={district}
        className="mb-2"
        editMode={editMode}
        onChange={onDistrictChange}
      />
      <p className="mb-0">{lang.stores.informations.loc}</p>
      <StoreLocation setLatLng={setLatLng} locateMeControl={editMode} onChange={setLatLng} latLng={latLng} />
    </div>
  );
};
