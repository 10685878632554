import React, { memo, useState, useCallback, useEffect } from 'react';
import CardBody from 'reactstrap/lib/CardBody';
import CustomInput from 'reactstrap/lib/CustomInput';
import { lang } from '../../lang';
import { date } from '../../utils';
import { Card } from '../common/Card';
import { GongestionGraph } from '../dashboard/CongestionGraph';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import { APPOINTMENT_STATUS } from '../../constants';

const FILTERS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const CongestionHours = memo(({ loading, className, data: rawDate }) => {
  const [filter, setFilter] = useState(FILTERS.DAY);
  const [data, setData] = useState();
  const onChange = useCallback(e => setFilter(e.target.value), []);

  const prepareFilters = useCallback(filter => {
    switch (filter) {
      case FILTERS.DAY:
        return {
          start_date: date.setStartOfDay(new Date()),
          end_date: date.setEndOfDay(new Date()),
          count: 1,
        };
      case FILTERS.WEEK:
        return {
          start_date: date.subDays(date.setStartOfDay(new Date()), 7),
          end_date: date.setEndOfDay(new Date()),
          count: 7,
        };
      case FILTERS.MONTH:
        return {
          start_date: date.subMonths(date.setStartOfDay(new Date()), 1),
          end_date: date.setEndOfDay(new Date()),
          count: 30,
        };
      default:
        break;
    }
  }, []);

  const prepareData = useCallback(() => {
    const filters = prepareFilters(filter);
    const appts = rawDate
      .map(a => ({ ...a, start_datetime: new Date(a.start_datetime) }))
      .filter(
        appt =>
          appt.status !== APPOINTMENT_STATUS.NOT_ARRIVED &&
          appt.status !== APPOINTMENT_STATUS.REJECTED &&
          appt.status !== APPOINTMENT_STATUS.CANCELED &&
          !date.isBefore(appt.start_datetime, filters.start_date) &&
          !date.isAfter(appt.start_datetime, filters.end_date)
      );

    const data = [];
    for (let i = 0; i < 24; i++) {
      const count = appts.filter(appt => appt.start_datetime.getHours() === i).length;
      data[i] = count;
    }

    // trim start
    if (data.find(a => a > 0)) {
      for (let i = 0; i < 24; i++) {
        if (data[i] !== 0) {
          break;
        }
        data[i] = -1;
      }

      // trim end
      for (let i = 23; i >= 0; i--) {
        if (data[i] !== 0) {
          break;
        }
        data[i] = -1;
      }
      setData(data);
    } else {
      setData(data);
    }
  }, [rawDate, filter, prepareFilters]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  return (
    <Card loading={loading} className={className} mid>
      <FalconCardHeader
        titleClass="dashboard-card-title"
        title={lang.dashboardItems.congestionTime}
        light={false}
        titleTag="h6"
        className="pb-0"
      >
        <Flex>
          <CustomInput
            type="select"
            bsSize="sm"
            className="select-month mr-2 lightgrey-yesser"
            value={filter}
            onChange={onChange}
          >
            <option value={FILTERS.DAY}>Day</option>
            <option value={FILTERS.WEEK}>Week</option>
            <option value={FILTERS.MONTH}>Month</option>
          </CustomInput>
        </Flex>
      </FalconCardHeader>

      <CardBody className="h-100  d-flex justify-content-center align-items-center">
        <GongestionGraph data={data} />
      </CardBody>
    </Card>
  );
});
