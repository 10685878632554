import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import WizardError from './WizardError';
import { AuthWizardContext } from '../../../context/Context';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { Select } from '../../common/FormSelect';
import { DatePicker } from '../../common/DatePicker';
import { TimePicker } from '../../common/TimePicker';

const WizardInput = ({
  label,
  id,
  name,
  errors,
  tag: Tag = Input,
  type = 'text',
  options = [],
  placeholder,
  className,
  customType,
  control,
  defaultValue,
  ...rest
}) => {
  const { user } = useContext(AuthWizardContext);
  if (customType === 'react-select') {
    return (
      <FormGroup>
        {!!label && <Label>{label}</Label>}
        <Controller
          control={control}
          name={name}
          id={id}
          as={Select}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
          options={options}
          label={label}
          className={classNames(className, { 'border-danger': errors[name]?.message })}
          {...rest}
        />
        <WizardError error={errors[name]?.message} className="mt-1" />
      </FormGroup>
    );
  }
  if (customType === 'datetime') {
    return (
      <FormGroup className="position-relative">
        {!!label && <Label>{label}</Label>}
        <DatePicker
          id={id}
          name={name}
          defaultValue={defaultValue}
          control={control}
          // showHijriFooter
          className={classNames(className, { 'border-danger': errors[name]?.message })}
          {...rest}
        />
        <WizardError error={errors[name]?.message} className="mt-1" />
      </FormGroup>
    );
  }

  if (customType === 'time-picker') {
    return (
      <FormGroup className="position-relative">
        {!!label && <Label>{label}</Label>}
        <TimePicker
          id={id}
          name={name}
          timeFormat={false}
          defaultValue={defaultValue}
          control={control}
          className={classNames(className, { 'border-danger': errors[name]?.message })}
          {...rest}
        />
        <WizardError error={errors[name]?.message} className="mt-1" />
      </FormGroup>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <FormGroup>
        <Tag
          name={name}
          id={id}
          type={type}
          className={className}
          label={
            <Fragment>
              {label}
              <WizardError error={errors[name]?.message} className="fs--1 font-weight-normal d-block" />
            </Fragment>
          }
          {...rest}
        />
      </FormGroup>
    );
  }
  if (type === 'select') {
    return (
      <FormGroup>
        {!!label && <Label for={id}>{label}</Label>}
        <Tag
          name={name}
          id={id}
          defaultValue={user[name]}
          type={type}
          label={label}
          className={classNames(className, { 'border-danger': errors[name]?.message })}
          {...rest}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </Tag>
        <WizardError error={errors[name]?.message} className="mt-1" />
      </FormGroup>
    );
  }
  // console.log(name, errors[name]?.message);

  return (
    <FormGroup>
      {!!label && <Label for={id}>{label}</Label>}
      <Tag
        name={name}
        id={id}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        className={classNames(className, { 'border-danger': errors[name]?.message })}
        {...rest}
      />
      <WizardError error={errors[name]?.message} className="mt-1" />
    </FormGroup>
  );
};

WizardInput.propTypes = { label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) };

WizardInput.defaultProps = { required: false };

export default WizardInput;
