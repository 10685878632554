import React from 'react';
import { Input } from 'reactstrap';

export const InformationItem = ({ label, value, className, editMode, onChange, disabled }) => (
  <div className={`information-item ${className || ''}`}>
    <p className="fs--1">{label}</p>
    {!editMode ? (
      <p className="text-grey">{value}</p>
    ) : (
      <Input value={value} className="sweet-input" onChange={onChange} disabled={disabled} />
    )}
  </div>
);
