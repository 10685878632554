import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { lang } from '../../lang';
import { Link } from 'react-router-dom';
import { IconEllipses } from '../../utils';

const TableActionButton = ({ id, active, toggleActive, onDeleteClicked }) => (
  <UncontrolledDropdown className="position-static">
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
      <IconEllipses />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <Link to={`/services/edit/${id}`}>
        <DropdownItem>{lang.tableActions.edit}</DropdownItem>
      </Link>
      <DropdownItem divider />
      <DropdownItem
        onClick={toggleActive}
        className={`${active ? 'text-warning' : 'text-success'} border-top border-light`}
      >
        {active ? lang.tableActions.deactivate : lang.tableActions.activate}
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem className="text-danger" onClick={() => onDeleteClicked(id)}>
        {lang.tableActions.delete}
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default TableActionButton;
