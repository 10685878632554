import React, { useEffect } from 'react';
import { isAR } from '../../lang';
import Header from './header';
import Footer from './footer';

const Faq = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const ar = () => {
    return (
      <>
        <Header />
        <div className="box mx-auto px-4 py-5 mb-5 mt-5">
          <h2 className="text-primary pb-5 ">الاسئلة الشائعة</h2>
          <h5 className="text-primary">ما هو مراجع؟</h5>
          <p>
            هو نظام الكتروني متكامل لادارة و حجز المواعيد بشكل سهل وبسيط يهدف إلى رفع رضا العملاء وتحسين تجربة المستخدم.
          </p>
          <hr />
          <h5 className="text-primary">ماهي مميزات مراجع؟</h5>
          <p>يوفر مراجع العديد من المميزات:</p>
          <ul>
            <li>يجمع مراجع العديد من مزودي الخدمة في مكان واحد.</li>
            <li>دعم النظام اللغتين العربية و الإنجليزية.</li>
            <li>تطبيقات المستخدم على الهاتف المحمول تعمل على النظامين iOS و Android .</li>
            <li>لوحة التحكم سهلة وبسيطة توفر كافة المعلومات المطلوبة لمدير النظام ومدير الفرع.</li>
            <li>
              كما يساهم النظام في تطبيق مبدأ التباعد الأجتماعي بحساب عدد الأشخاص المسموح لهم بدخول المنشئة أو الفرع
              بناءَ على المساحة.
            </li>
          </ul>
          <hr />
          <h5 className="text-primary">ماهي قيمة الاشتراك في مراجع؟</h5>
          <p>يتم تقديم مراجع في باقات تختلف الأسعار حسب الباقة.</p>
          <hr />
          <h5 className="text-primary">هل المنتج يخدم فقط المنشأة او الأفراد؟</h5>
          <p>يخدم المنتج المنشأة و الفرد على حد سواء.</p>
          <hr />
          <h5 className="text-primary">هل من الممكن حجز اكثر من مكان في نفس الوقت؟</h5>
          <p>نعم يمكن ذلك.</p>
          <hr />
          <h5 className="text-primary">هل بالإمكان إلغاء الحجز؟</h5>
          <p>نعم يمكن ذلك.</p>
          <hr />
          <h5 className="text-primary">هل هناك رسوم للحجز؟</h5>
          <p>لا توجد رسوم.</p>
          <hr />
          <h5 className="text-primary">هل هناك خدمة تقييم المنشأة بعد الانتهاء من الحجز؟</h5>
          <p>بناءً علي الطلب</p>
          <hr />
          <h5 className="text-primary">ماهو عدد الأفراد المحدد للحجز في ساعة؟</h5>
          <p>تختلف من مقدم خدمة لأخر.</p>
          <hr />
          <h5 className="text-primary">هل يتوفر تطبيق للنظام؟</h5>
          <p>نعم للمستفيد النهائي الذي يرغب بإجراء الحجوزات.</p>
          <hr />
          <h5 className="text-primary">هل هناك وقت محدد للحجز؟</h5>
          <p>حسب المدة المحددة من قبل مقدم الخدمة المطلوبة قبل حجز الموعد.</p>
        </div>
        <Footer />
      </>
    );
  };

  const en = () => {
    return (
      <>
        <Header />
        <div className="box mx-auto px-4 py-5 mb-5 mt-5">
          <h2 className="text-primary pb-5">Frequently Asked Questions</h2>
          <h5 className="text-primary">What is Appointments system?</h5>
          <p>
            Aa integrated system designed to present a solution to manage and book appointments in an easy and simple
            way that helps in improving customer experience.
          </p>
          <hr />
          <h5 className="text-primary">What are the features of Appointments system?</h5>
          <p>Appointments system offers different features:</p>
          <ul>
            <li>Different service provider in one app.</li>
            <li>It supports both Arabic and English.</li>
            <li>Provides an easy-to-use mobile phone application that works on both iOS and Android.</li>
            <li>
              It’s also provide an easy and simple admin dashborad that provide all the information required for both
              woners and managers.
            </li>
            <li>It’s help in applying social destansing by calculatiog the number of cliens based on the space.</li>
          </ul>
          <hr />
          <h5 className="text-primary">What is the subscription fee of the system?</h5>
          <p>The system is offered in packages and the fee differs from one package to another.</p>
          <hr />
          <h5 className="text-primary">Does the Appointments system support only entities or individuals as well?</h5>
          <p>The system serves both enterprices and the individuals.</p>
          <hr />
          <h5 className="text-primary">Is it possible to book more than one appointment at the same time?</h5>
          <p>Yes.</p>
          <hr />
          <h5 className="text-primary">Can the appointment be canceled?</h5>
          <p>Yes.</p>
          <hr />
          <h5 className="text-primary">Is there a fee for booking an appointment?</h5>
          <p>No.</p>
          <hr />
          <h5 className="text-primary">Is there an evaluation form for the service after booking?</h5>
          <p>Comming soon feature.</p>
          <hr />
          <h5 className="text-primary">How many people can book within one hour?</h5>
          <p>Differ from one service provider to the other.</p>
          <hr />
          <h5 className="text-primary">Is there an application for iOS and Android?</h5>
          <p>Yes for the end-user to book appointments.</p>
          <hr />
          <h5 className="text-primary">Is there a specific time to book?</h5>
          <p>Depends on the minimum time required prior to booking defined by the service provider.</p>
        </div>
        <Footer />
      </>
    );
  };

  return (
    <section className="privacy-policy pb-0">
      <div>{isAR ? ar() : en()}</div>
    </section>
  );
};

export default Faq;
