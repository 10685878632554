import React, { Fragment, useEffect, useContext, useState, useCallback } from 'react';
import { Card, CardBody, Col, Row, Form, FormGroup, Label, Container, Media, Button } from 'reactstrap';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import AppContext from '../../context/Context';
import Loader from '../../components/common/Loader';
import Avatar from '../../components/common/Avatar';
import { isIterableArray } from '../../utils';
import { useHistory } from 'react-router-dom';
import Alert from '../../components/alert/success';
import FalconDropzone from '../../components/common/FalconDropzone';

const BrandInformation = () => {
  const { setCurrentPage } = useContext(AppContext);
  const history = useHistory();
  const { user } = useContext(AppContext);
  let [brandInfo, setInfo] = useState(user?.tenant);
  let [loading, setLoading] = useState(false);
  let [uploading, setUploading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [avatar, setAvatar] = useState([{ src: user?.tenant?.logo ? user?.tenant?.logo?.file_url : '' }]);

  useEffect(() => {
    setCurrentPage('brandSettings');
  }, [setCurrentPage]);

  // const onDrop = files => {
  //   setAvatar(files);
  //   // console.log(files, brandInfo)

  //   let updateLogo = document.querySelector('#update-logo');
  //   updateLogo.removeAttribute('disabled');
  //   updateLogo.classList.remove('disabled');
  //   updateLogo.addEventListener('click', function() {
  //     uploadBrandLogo(files);
  //   });
  // };

  // const uploadBrandLogo = async files => {
  //   try {
  //     const form = new FormData();
  //     form.append('logo', files[0].file);
  //     form.append('brandId', brandInfo.id);
  //     setUploading(true);
  //     await request.POST(APIs.UPLOAD_LOGO, form);
  //     setUploading(false);
  //     // updateBrandInfo();
  //     setShowAlert(true);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const updateBrandInfo = async (logoID) => {
  //   // setShowAlert(true);
  //   // let storeId = storeInfo.id;
  //   // console.log(brandInfo)
  //   try {
  //     const body = {
  //       name: brandInfo.name,
  //       name_en: brandInfo.name_en,
  //       description: brandInfo.description,
  //       description_en: brandInfo.description_en,
  //       brandId: brandInfo.id,
  //     };
  //     await request.POST(APIs.UPDATE_BRAND, body);
  //     setShowAlert(true);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <Fragment>
      <Container>
        <Card className="mb-3">
          <FalconCardHeader title={lang.brandInformation.navTitle} />
          <CardBody>
            <Form>
              <Row>
                {/* <Col>
                  <FormGroup>
                    <Label for="en-name">{lang.brandInformation.enName}</Label>
                    <h5 className="text-primary">{brandInfo.name_en}</h5>
                  </FormGroup>
                </Col> */}
                <Col>
                  <FormGroup>
                    <Label for="ar-name">{lang.brandInformation.name}</Label>
                    <h5 className="text-primary">{brandInfo.name}</h5>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                  <FormGroup>
                    <Label for="en-description">{lang.brandInformation.enDesc}</Label>
                    <h5 className="text-primary">{brandInfo.description_en}</h5>
                  </FormGroup>
                </Col> */}
                {/* <Col>
                  <FormGroup>
                    <Label for="ar-description">{lang.brandInformation.arDesc}</Label>
                    <h5 className="text-primary">{brandInfo.description}</h5>
                  </FormGroup>
                </Col> */}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default BrandInformation;
