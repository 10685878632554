import { useState } from 'react';
import { SORT_TYPES } from '../constants';
import { IconSort } from '../utils';

const DEFAULT = { type: SORT_TYPES.NONE, Icon: IconSort };

const useTableSort = (source, customHandler) => {
  const [sortInfo, setSortInfo] = useState({ type: DEFAULT.type, column: '', sorted: [] });

  const sortLocally = column => {
    const rows = source.slice();
    switch (sortInfo.type) {
      case SORT_TYPES.ASC:
        setSortInfo({ column: '', type: SORT_TYPES.NONE, sorted: rows });
        break;
      case SORT_TYPES.DESC:
        const ascSorted = rows.sort((a, b) => {
          return a[column.dataField] < b[column.dataField] ? -1 : 1;
        });
        setSortInfo({ column: column.dataField, type: SORT_TYPES.ASC, sorted: ascSorted });
        break;
      default:
        const descSorted = rows.sort((a, b) => {
          return a[column.dataField] < b[column.dataField] ? 1 : -1;
        });
        setSortInfo({ column: column.dataField, type: SORT_TYPES.DESC, sorted: descSorted });
    }
  };

  // slice() to give new array
  const onSortClick = column => {
    if (!customHandler) {
      sortLocally(column);
    } else {
      switch (sortInfo.type) {
        case SORT_TYPES.ASC:
          setSortInfo({ column: '', type: SORT_TYPES.NONE });
          customHandler();
          break;
        case SORT_TYPES.DESC:
          setSortInfo({ column: column.dataField, type: SORT_TYPES.ASC });
          customHandler(column.dataField, SORT_TYPES.ASC);
          break;
        default:
          setSortInfo({ column: column.dataField, type: SORT_TYPES.DESC });
          customHandler(column.dataField, SORT_TYPES.DESC);
      }
    }
  };

  return { onSortClick, sortedRows: sortInfo.sorted, activeColumn: sortInfo.column, sortType: sortInfo.type };
};

export default useTableSort;
