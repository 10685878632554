import React, { useCallback, useEffect, useMemo, useState } from 'react';

export const Select = ({ options = [], onChange, value }) => {
  const [text, setText] = useState('');
  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(() => setFocused(true), []);

  const onBlur = useCallback(() => setTimeout(() => setFocused(false), 200), []);

  const onItemClick = useCallback(
    (value, label) => {
      onChange && onChange(value);
      setText(label);
    },
    [onChange]
  );

  const filteredOpts = useMemo(() => {
    return options.filter(({ label }) => true || label.includes(text));
  }, [options, text]);

  useEffect(() => {
    if (!value) {
      setText(null);
    } else {
      value.label && setText(value.label);
    }
  }, [value]);

  return (
    <div className="position-relative filter-select">
      <button onFocus={onFocus} onBlur={onBlur} className="form-control overflow-hidden px-1">
        {text}
      </button>
      <div className={`position-absolute dropdown ${focused ? 'visible' : 'invisible'}`}>
        <div className="dropdown-content py-2 px-2">
          {filteredOpts.length ? (
            filteredOpts.map(({ value, label }) => {
              return (
                <button onClick={() => onItemClick(value, label)} className="item">
                  {label}
                </button>
              );
            })
          ) : (
            <span className="item">No option</span>
          )}
        </div>
      </div>
    </div>
  );
};
