import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
// import { faTwitter, faYoutube, faLinkedinIn, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { lang, isAR } from '../../lang';
// import { request, APIs } from '../../request';
// import { Button } from 'reactstrap';
import Header from './header';
import Footer from './footer';

export default () => {
  // const [first, setFirst] = useState('');
  // const [last, setLast] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [company, setCompany] = useState('');
  // const [size, setSize] = useState('');
  // const [message, setMessage] = useState('');

  // const submit = async () => {
  //   let data = new FormData();
  //   data.append('firstname', first);
  //   data.append('lastname', last);
  //   data.append('email', email);
  //   data.append('phone', phone);
  //   data.append('company', company);
  //   data.append('company_size', size);
  //   data.append('message', message);

  //   await request.POST(APIs.CONTACT, data);
  // };

  return (
    <>
      <Header />
      <section id="contact" className="landing-section contact " style={{ minHeight: '90vh' }}>
        <div className="section-content d-flex flex-wrap">
          <div className="d-flex main mb-4 mb-md-0" style={{ flex: 1 }}>
            <div style={{ flex: 0.8 }}>
              <div>
                <h2 className="font-weight-bold mb-4">{lang.landing.contactTitle}</h2>
                <h5>{lang.landing.contactDescription}</h5>
              </div>
              <div className="mt-4 mt-md-5 mt-xl-7">
                <a className="my-4 d-block " href="tel:+966920023600">
                  <h5>
                    <FontAwesomeIcon className="mr-3 mr-md-3" icon={faPhoneAlt} />
                    {!isAR ? '+' : ''}
                    <span>966-920023600  </span>
                    {isAR ? '+' : ''}
                  </h5>
                </a>
                <a className="my-4 d-block " href="mailTo:appointment@tamkeentech.sa">
                  <h5>
                    <FontAwesomeIcon className="mr-3 mr-md-3" icon={faEnvelope} />
                    <span>appointment@tamkeentech.sa</span>
                  </h5>
                </a>
                <a
                  className="my-4 d-block "
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/Tamkeen+Technologies/@24.7078982,46.6802959,18.53z/data=!4m5!3m4!1s0x0:0xb4a9b05dbe6ee664!8m2!3d24.7080861!4d46.6785827"
                >
                  <h5>
                    <FontAwesomeIcon className="mr-3 mr-md-3" icon={faMapMarkerAlt} />
                    <span>العليا العام, Al Olaya, Riyadh 12244</span>
                  </h5>
                </a>
              </div>
            </div>
          </div>

          {/* <div style={{ flex: 1 }}>
            <div className="card h-auto  w-100 w-md-100 w-xl-75 white-bg p-xl-5 p-md-4 p-4 m-auto ">
              <span className="card-title">{lang.landing.contactUS}</span>
              <div className="row">
                <div className="field mx-2">
                  <label for="firstName">
                    {lang.landing.firstName}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={first}
                    onChange={e => setFirst(e.target.value)}
                  />
                </div>
                <br />
                <div className="field mx-2">
                  <label for="lastName">
                    {lang.landing.lastName}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={last}
                    onChange={e => setLast(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field mx-2">
                  <label for="email">
                    {lang.landing.email}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="field mx-2">
                  <label for="phone">
                    {lang.landing.phone}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="phone"
                    type="phone"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field mx-2">
                  <label for="company">
                    {lang.landing.company_name}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="company"
                    type="text"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                  />
                </div>
                <div className="field mx-2">
                  <label for="company_size">
                    {lang.landing.company_size}
                    <small className="text-muted">*</small>
                  </label>
                  <input
                    id="company_size"
                    type="text"
                    className="px-2 py-1 px-md-2 py-md-1"
                    value={size}
                    onChange={e => setSize(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="field  mx-2 d-flex flex-column">
                  <label for="message">
                    {lang.landing.message}
                    <small className="text-muted">*</small>
                  </label>
                  <textarea id="message" onChange={e => setMessage(e.target.value)} />
                </div>
              </div>
              <div className="row">
                <Button
                  disabled={!(first && last && email && phone && message)}
                  className="w-100 py-2 font-weight-bold"
                  style={{ borderRadius: 18 }}
                  color="danger"
                  onClick={submit}
                >
                  {lang.landing.send}
                </Button>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="social-media">
          <div className="mx-auto d-flex flex-column justify-content-center h-100 row">
            <h4 className="text-white font-weight-bold pb-2">{lang.landing.stayConnected}</h4>
            <ul className="d-flex p-0">
              <li className="d-flex justify-content-center align-items-center mx-2">
                <a rel="noopener noreferrer" href="https://twitter.com/TamkeenTech">
                  <FontAwesomeIcon className="text-white" icon={faTwitter} />
                </a>
              </li>
              <li className="d-flex justify-content-center align-items-center mx-2">
                <a rel="noopener noreferrer" href="https://www.instagram.com/tamkeentech/">
                  <FontAwesomeIcon className="text-white" icon={faInstagram} />
                </a>
              </li>
              <li className="d-flex justify-content-center align-items-center mx-2">
                <a rel="noopener noreferrer" href="https://www.facebook.com/Tamkeen-Technologies-341094266689994">
                  <FontAwesomeIcon className="text-white" icon={faFacebookF} />
                </a>
              </li>
              <li className="d-flex justify-content-center align-items-center mx-2">
                <a href="https://www.linkedin.com/company/filesharingplatform">
                  <FontAwesomeIcon className="text-white" icon={faLinkedinIn} />
                </a>
              </li>
              <li className="d-flex justify-content-center align-items-center mx-2">
                <a href="https://www.youtube.com/channel/UCM53esqJKAPdV1vnOb_uwMg/featured">
                  <FontAwesomeIcon className="text-white" icon={faYoutube} />
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};
