import { SignJWT, jwtVerify } from 'jose';
import { Session } from '../types';

const key = new TextEncoder().encode(process.env.REACT_APP_AUTH_SECRET);

export async function encrypt(payload: Session) {
  return await new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).sign(key);
}

export async function decrypt(input: string) {
  const result = await jwtVerify(input, key, {
    algorithms: ['HS256'],
  }).catch(e => {
    console.log('Failed to decrypt session');
    console.error(e);
    return null;
  });
  return result?.payload as Session | null;
}
