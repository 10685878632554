import React from 'react';
import { ReactComponent as CheckedIcon } from '../../assets/img/icons/checked.svg';
import PropTypes from 'prop-types';

const Checkbox = ({ checked = false, toggle }) => {

  const icon = () => {
    return !!checked ? <CheckedIcon /> : <IconUnchecked />;
  };

  return (
    <button
      style={Styles.button}
      onClick={toggle}>
      <div style={Styles.check}>
        {icon()}
      </div>
    </button>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

const Styles = {
  button: {
    background: 'transparent',
    border: '0',
    margin: '0.1rem',
    marginBottom: '0.5rem',
    fontSize: '1rem',
    display: 'flex',
    outline: '0',
    color: '#9B9B9B',
    cursor: 'hand',

  },

  check: {
    margin: '0.1rem',
  },

  content: {
    fontSize: '0.9rem',
    fontWeight: '100',
    lineHeight: '1.25rem',
  },
};

class IconUnchecked extends React.Component {

  render() {
    return (
      <svg style={unCheckedStyles.svg} viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g stroke="#BFC5D2" strokeWidth="1">
            <rect id="Rectangle" x="1.5" y="1.5" width="21" height="21" rx="5" />
          </g>
        </g>
      </svg>
    );
  }
}

const unCheckedStyles = {
  svg: {
    width: '20px',
    height: '20px',
  },
};

export default Checkbox;