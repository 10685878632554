import React, { useContext, useCallback, useState } from 'react';
import { lang } from '../../lang';
import { Toggler } from '../common/toggler';
import ConfigCard from './_ConfigCard';
import AppContext from '../../context/Context';
import { request, APIs } from '../../request';
import { toast } from 'react-toastify';

export default () => {
  const { selectedStore, stores, setStores, setSelectedStore } = useContext(AppContext);
  const [toggleValue, setToggleValue] = useState(!!selectedStore.configuration?.walkin_status);

  const _updateLocalStoreState = useCallback(
    is_active => {
      let storeId = selectedStore.id;
      setSelectedStore(prevState => ({
        ...prevState,
        configuration: {
          ...prevState.configuration,
          walkin_status: is_active,
        },
      }));
      const updatedStoresList = stores.map(st => {
        st.id === storeId && (st.configuration.walkin_status = is_active);
        return st;
      });
      setStores(updatedStoresList);
    },
    [selectedStore, setSelectedStore, stores, setStores]
  );

  const onToggleChange = useCallback(
    async toggleState => {
      let storeId = selectedStore.id;
      setToggleValue(toggleState);
      _updateLocalStoreState(toggleState);
      const {
        data: { data },
      } = await request.PATCH(APIs.CONFIG, {
        branch_id: storeId,
        walkin_status: toggleValue,
      });
      toggleState
        ? toast.success(lang.configuration.walkinControlActivated)
        : toast.success(lang.configuration.walkinControlDeactivated);
    },
    [_updateLocalStoreState, selectedStore.id]
  );

  return (
    <ConfigCard>
      <h5 className="font-weight-bold">{lang.configuration.walkinControl}</h5>
      <small className="m-0 px-3" style={{ flex: 1 }}>
        {lang.configuration.walkinControlExplaination}
      </small>
      <Toggler checked={toggleValue} onChange={onToggleChange} />
    </ConfigCard>
  );
};
