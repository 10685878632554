import React, { Fragment, useState, useContext, useCallback, useMemo } from 'react';
import { Alert, CardBody, Button } from 'reactstrap';
import { Card } from '../../components/common/Card';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useHistory } from 'react-router-dom';
import ServicesTable from '../../components/services/ServicesTable';
import { lang } from '../../lang';
import AppContext from '../../context/Context';
import { request, APIs, prepareURL } from '../../request';
import { safeReq } from '../../utils/request';
import { toast } from 'react-toastify';
import Confirm from '../../components/alert/confirm';
import useSWR from 'swr';
import { REVALIDATE_TIME } from '../../constants';
import { number } from 'prop-types';

const Services = () => {
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [selecteServiceId, setSelecteServiceId] = useState([]);
  const [servicesMeta, setServicesMeta] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [page, setPage] = useState(1);
  const { selectedStore, setSelectedStore, setStores, stores } = useContext(AppContext);
  const url = useMemo(() => prepareURL(APIs.STORE_SERVICES, { branch_id: selectedStore.id }), [selectedStore]);
  let loading = false;
  const fetchData = useCallback(async () => {
    try {
      const { data } = await request.GET(APIs.STORE_SERVICES, { branch_id: selectedStore.id, size: 6, page: page });
      setServicesMeta(data?.data);
      return data.data.data;
    } catch (e) {
      // toast.error(lang.defaultError);
    }
  }, [selectedStore.id, servicesMeta, page]);

  const { data: services, mutate } = useSWR([url, selectedStore.id, page], fetchData, {
    refreshInterval: REVALIDATE_TIME,
  });
  !services && (loading = true);

  const handleStoreStatus = useCallback(
    services => {
      const should_deactivate_store = !services.find(service => service.active);
      if (should_deactivate_store) {
        const updated_store = { ...selectedStore, active: false };
        setSelectedStore(updated_store);
        setStores(stores.map(st => (st.id === selectedStore.id ? updated_store : st)));
      }
    },
    [stores, setStores, selectedStore, setSelectedStore]
  );

  const toggleActive = useCallback(
    async (service_id, active) => {
      try {
        setFetching(true);
        const { data } = await request.POST(APIs.SERVICE_STATUS, { service_id, status: !active });
        const new_service = data.data;
        const updated_services = services.map(service => (service.id === service_id ? { ...new_service } : service));
        mutate(updated_services);
        handleStoreStatus(updated_services);
        setFetching(false);
        toast.success(active ? lang.services.deactivateService : lang.services.activateService);
      } catch (err) {
        setFetching(false);
      }
    },
    [services, handleStoreStatus, mutate]
  );

  const onDeleteClicked = useCallback(id => {
    setShowDeleteAlert(true);
    setSelecteServiceId(id);
  }, []);

  const deleteService = useCallback(() => {
    safeReq(async () => {
      setShowDeleteAlert(false);
      setFetching(true);
      await request.DELETE(APIs.SERVICES, { service_id: selecteServiceId });
      const updated_services = services.filter(el => el.id !== selecteServiceId);
      mutate(updated_services);
      handleStoreStatus(updated_services);
      toast.success(lang.services.deleteServiceSuccess);
      setFetching(false);
    });
  }, [services, selecteServiceId, handleStoreStatus, mutate]);

  return (
    <Fragment>
      <Card>
        <FalconCardHeader title={lang.services.title}>
          <Button color="primary" className="mr-2" onClick={() => history.push('/services/add')}>
            {lang.services.addService.title}
          </Button>
        </FalconCardHeader>
        <CardBody className="fs--1">
          {loading || fetching || (services && services?.length) ? (
            <ServicesTable
              loading={loading || fetching}
              services={services || []}
              toggleActive={toggleActive}
              onDeleteClicked={onDeleteClicked}
              servicesMeta={servicesMeta}
              setPage={setPage}
            />
          ) : (
            <Alert color="secondary" className="mb-0">
              {lang.services.noServicesFound}
            </Alert>
          )}
        </CardBody>
      </Card>
      <Confirm
        open={showDeleteAlert}
        confirm={deleteService}
        cancel={() => setShowDeleteAlert(false)}
        title={lang.services.deleteServiceConfirmationTitle}
        body={lang.services.deleteServiceConfirmation}
      />
    </Fragment>
  );
};

export default Services;
