import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleAuthCallback } from '../../lib/auth/callback';

const AuthCallback = () => {
  const location = useLocation();
  // useEffect(() => {
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  if (code) {
    handleAuthCallback(code);
  } else {
    console.log('error');
    //     window.location.href = '/auth/error?error=missing_code';
  }
  // }, [location]);

  return <div>Redirecting...</div>;
};

export default AuthCallback;
