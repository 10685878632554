import React, { useState, Fragment } from "react";
import { Card, CardBody, CardFooter, CardHeader, Form, Button } from "reactstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Alert from "../../components/alert/success";
import GuardCredentials from "../../components/guards/GuardCredentials";
import { useHistory, useRouteMatch } from "react-router-dom";
import { lang } from "../../lang";
import data from "../../data/moderators/moderators";

const EditModerator = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { handleSubmit, errors, watch } = useForm();

  const information = data.find(moderator => `${moderator.id}` === `${params.id}`);

  const onSubmitData = data => {
    setShowAlert(true);
  };

  const [showAlert, setShowAlert] = useState(false);

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <span className="d-none d-md-block mt-1 fs--1">{lang.moderators.editModerator.title}</span>
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          <GuardCredentials errors={errors} watch={watch} data={information} />
        </CardBody>
        <CardFooter className={classNames("px-md-6 bg-light d-flex")}>
          <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
            {lang.btn.update}
          </Button>
        </CardFooter>
      </Card>
      <Alert
        open={showAlert}
        onClick={() => history.push("/moderators")}
        title={lang.moderators.editModerator.editSuccess}
      />
    </Fragment>
  );
};

export default EditModerator;
