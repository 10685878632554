import React, { Fragment, createRef } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';
import { lang, isAR } from '../../lang';
import TableActionsMenu from './TableActionButton';
import { Card } from '../common/Card';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
library.add(faChevronLeft);
library.add(faChevronRight);

const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
  <span>
    {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize}{' '}
  </span>
);

const nameFormatter = (name, data) => (
  <Link to={`/services/edit/${data.id}`} className="font-weight-semi-bold">
    {name}
  </Link>
);

let table = createRef();

const activeFormatter = (cell, row) => {
  if (row.is_active) {
    return (
      <span className="text-success">
        <strong>{lang.stores.yes}</strong>
      </span>
    );
  }

  return (
    <span className="text-danger">
      <strong>{lang.stores.no}</strong>
    </span>
  );
};

const ServicesTable = ({ services, toggleActive, onDeleteClicked, loading, servicesMeta, changingPage, setPage }) => {
  const prepareData = () =>
    services?.map(e => ({
      ...e,
      pre_msg: e.pre_msg || '-',
      action: (
        <TableActionsMenu
          toggleActive={() => toggleActive(e.id, e.is_active)}
          id={e.id}
          active={e.is_active}
          onDeleteClicked={onDeleteClicked}
        />
      ),
    }));
  const columns = [
    {
      dataField: `${isAR? 'name.ar': 'name.en'}`,
      text: lang.services.labels.name,
      formatter: nameFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
    },
  ]
    .sort(() => (isAR ? 1 : -1))
    .concat([
      {
        dataField: 'is_active',
        text: lang.stores.activated,
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
        sort: true,
        formatter: activeFormatter,
      },
      {
        dataField: 'duration',
        text: lang.services.labels.duration,
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
      },
      {
        dataField: 'capacity',
        text: lang.services.labels.customers_per_appointment,
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
      },
      {
        dataField: 'action',
        classes: 'border-0 align-middle',
        headerClasses: 'border-0',
      },
    ]);

  const options = {
    custom: true,
    sizePerPage: 6,
    totalSize: servicesMeta?.total,
  };

  const handleNextPage = () => {
    setPage(servicesMeta?.current_page + 1);
  };

  const handlePrevPage = () => {
    setPage(servicesMeta?.current_page - 1);
  };

  const handleViewAll = ({ onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, 1);
  };
  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = servicesMeta?.current_page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <Card className="table-responsive" fetching={loading}>
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={prepareData() || []}
                columns={columns}
                bordered={false}
                classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </Card>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                {/* <ButtonIcon
                  color="link"
                  size="sm"
                  icon={['fas', `${isAR ? 'chevron-left' : 'chevron-right'}`]}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={() => handleViewAll(paginationProps, servicesMeta?.total)}
                >
                  {lang.btn.viewAll}
                </ButtonIcon> */}
              </Col>
              <Col xs="auto" className="pr-3">
                <Button
                  color={servicesMeta?.current_page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage}
                  disabled={servicesMeta?.current_page === 1}
                  className="px-4"
                >
                  {lang.btn.previous}
                </Button>
                <Button
                  color={lastIndex >= servicesMeta?.total ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage}
                  disabled={lastIndex >= servicesMeta?.total}
                  className="px-4 ml-2"
                >
                  {lang.btn.next}
                </Button>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default ServicesTable;
