import React from 'react';
import { useCallback } from 'react';
import { lang } from '../../lang';

const VALUES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

const FiltersComp = ({ value, onChange }) => {
  const onClick = useCallback(
    val => {
      onChange && onChange(val);
    },
    [onChange]
  );

  return (
    <div className="filter-btns d-flex">
      <button onClick={() => onClick(VALUES.DAY)} className={value === VALUES.DAY ? 'active' : ''}>
        {lang.dashboardItems.day}
      </button>
      <button onClick={() => onClick(VALUES.WEEK)} className={value === VALUES.WEEK ? 'active' : ''}>
        {lang.dashboardItems.week}
      </button>
      <button onClick={() => onClick(VALUES.MONTH)} className={value === VALUES.MONTH ? 'active' : ''}>
        {lang.dashboardItems.month}
      </button>
    </div>
  );
};

FiltersComp.VALUES = VALUES;

export const Filters = FiltersComp;
