import React, { Fragment, useState, useCallback } from 'react';
import { lang, isEN } from '../../lang';
import TableActionButton from '../../components/stores/TableActionButton';
import { Table } from '../common/Table';
import { Badge } from '../common/Badge';

const StoresTable = ({ stores, loading, paginate, totalPages }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const prepareData = useCallback(
    () =>
      stores.map(store => ({
        ...store,
        name_ar: store.name?.['ar'],
        name_en: store.name?.['en'],
        autoAccept: store.configuration?.['auto_accept_status'],
        action: <TableActionButton store={store} />,
      })),
    [stores]
  );

  const onPaginationClick = useCallback(
    page => {
      setCurrentPage(page);
      paginate(page);
    },
    [paginate]
  );
  const columns = prepareColumns();
  const rows = prepareData();

  return (
    <Fragment>
      <Table
        columns={columns}
        rows={rows}
        loading={loading}
        page={currentPage}
        onPaginate={onPaginationClick}
        totalPages={totalPages}
      />
    </Fragment>
  );
};

export default StoresTable;

const autoAcceptFormatter = (cell, row) => {
  const value = cell ? lang.on : lang.off;
  return <span>{value.toUpperCase()}</span>;
};

const prepareColumns = () => {
  let columns_part_1 = [
    {
      dataField: 'name_ar',
      text: lang.stores.informations.name,
      sort: true,
      colspan: 1.5,
    },
    {
      dataField: 'name_en',
      text: lang.stores.informations.name_en,
      sort: true,
      colspan: 1.5,
    },
  ];

  isEN && columns_part_1.reverse();

  const columns_part_2 = [
    {
      dataField: 'autoAccept',
      text: lang.stores.autoAccept,
      sort: true,
      formatter: autoAcceptFormatter,
    },
    {
      dataField: 'is_active',
      text: lang.stores.status,
      sort: true,
      formatter: badgeFormatter,
    },
    {
      dataField: 'action',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      text: '',
      colspan: 0.1,
    },
  ];

  return [...columns_part_1, ...columns_part_2];
};

const badgeFormatter = status => {
  let color = '';
  let text = '';
  if (status) {
    color = 'success';
    text = lang.stores.activated;
  } else {
    color = 'yellow';
    text = lang.stores.deactivated;
  }

  return (
    <Badge color={color} className="rounded-capsule">
      {text}
    </Badge>
  );
};
