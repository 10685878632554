import React, { useState, Fragment, useContext, useEffect, useCallback } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Button } from 'reactstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import StaffInformation from '../../components/staffs/StaffInformation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import { safeReq } from '../../utils/request';
import AppContext from '../../context/Context';
import Loader from '../../components/common/Loader';
import { toast } from 'react-toastify';

const EditStaff = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const { handleSubmit, errors, register } = useForm();
  const { selectedStore } = useContext(AppContext);
  const [staff, setStaff] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(
    async staff_id => {
      const {
        data: { data },
      } = await request.GET(APIs.STORE_STAFF, { branch_id: selectedStore.id });
      const staff = data.find(staff => `${staff.id}` === `${staff_id}`) || {};
      setStaff(staff);
      setLoading(false);
    },
    [selectedStore.id]
  );
  useEffect(() => {
    fetchData(params.id);
  }, [fetchData, params.id]);

  const onSubmitData = data =>
    safeReq(async () => {
      try {
        const body = { id: params.id, branch_ids: [selectedStore.id], role_id: staff.roles.id, is_active: 1, ...data };
        await request.PATCH(APIs.STAFF, body);
        toast.success(lang.staffs.editStaff.editSuccess);
        history.push('/staffs');
      } catch (err) {
        console.log(err);
      }
    });

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <span className="d-none d-md-block mt-1 fs--1">{lang.staffs.editStaff.title}</span>
        </CardHeader>
        {loading ? (
          <Loader />
        ) : (
          <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
            <StaffInformation errors={errors} data={staff} register={register} />
          </CardBody>
        )}
        <CardFooter className={classNames('px-md-6 bg-light d-flex')}>
          <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4" disabled={loading}>
            {lang.btn.save}
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default EditStaff;
