import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { lang } from "../../lang";

const Confirm = ({ open, title, body, confirm, cancel }) => (
  <Modal
    className="text-center"
    isOpen={open}
    centered={true}
    // toggle={() => collapseOneOpen(!collapseOne)}
  >
    <ModalHeader className="d-flex justify-content-center">{title}</ModalHeader>
    <ModalBody>{body}</ModalBody>
    <ModalFooter className="justify-content-center">
      {/* <Button onClick={() => collapseOneOpen(!collapseOne)}>
  Close
  </Button> */}
      <Button color="primary" className="px-4 py-2 mx-2" onClick={confirm}>
        {lang.yes}
      </Button>
      <Button className="px-4 py-2 mx-2" onClick={cancel}>
        {lang.no}
      </Button>
    </ModalFooter>
  </Modal>
);

export default Confirm;
