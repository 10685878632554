import React, { useCallback, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { lang } from '../../lang';
import { IconEllipses } from '../../utils';

export const APPOINTMENTS_ACTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  CANCEL: 'cancel',
};

const TableActionButton = ({ id, onActionClick }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = e => {
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  const onAcceptClick = useCallback(() => {
    onActionClick(id, APPOINTMENTS_ACTIONS.ACCEPT);
  }, [id, onActionClick]);
  const onRejectClick = useCallback(() => {
    onActionClick(id, APPOINTMENTS_ACTIONS.REJECT);
  }, [id, onActionClick]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal">
        <IconEllipses className="fs--1" />
      </DropdownToggle>
      <DropdownMenu right className="border py-2">
        <DropdownItem onClick={onAcceptClick}>{lang.tableActions.accept}</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={onRejectClick}>{lang.tableActions.reject}</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default TableActionButton;
