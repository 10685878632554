import React, { Fragment } from 'react';
import WizardInput from '../auth/wizard/WizardInput';
import { lang } from '../../lang';

const StaffInformation = ({ register, errors, data = {} }) => {
  return (
    <Fragment>
      {/* <WizardInput
        label={`${lang.label.name}*`}
        name="name"
        id="name"
        errors={errors}
        defaultValue={data.name}
        innerRef={register({
          required: lang.requiredField,
        })}
      /> */}

      {/* <WizardInput
        label={`${lang.label.mobile}*`}
        name="mobile"
        id="mobile"
        errors={errors}
        defaultValue={data.mobile}
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^(05)[0-9]{8}$/,
            message: lang.validationMessage.mobileStart05,
          },
          // maxLength: {
          //   value: 10,
          //   message: lang.validationMessage.mobile10Digits
          // },
          // minLength: {
          //   value: 10,
          //   message: lang.validationMessage.mobile10Digits
          // }
        })}
      /> */}
      <WizardInput
        label={`${lang.label.email}*`}
        type="email"
        name="email"
        id="email"
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
            message: lang.validationMessage.entervalidEmail,
          },
        })}
        defaultValue={data.email}
        errors={errors}
      />
      {/* only show note on add mode */}
      {!data.name ? <span>{lang.staffs.addStaff.note}</span> : null}
    </Fragment>
  );
};

export default StaffInformation;
