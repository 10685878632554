import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import PortalRouter from './PortalRouter';
import ErrorsRouter from './ErrorRouter';
import loadable from '@loadable/component';
// import { LandingPage } from '../screens/landing/LandingPage';
import Invitation from '../screens/invitations/Invitation';
import IAM from '../screens/iam/IAM';
import PrivacyPolicy from '../components/landing_page/PrivacyPolicy';
import Faq from '../components/landing_page/Faq';
import contact from '../components/landing_page/contact';
import AuthCallback from '../lib/auth/AuthCallback';
import { useAuthMiddleware } from '../hooks/useAuthMiddleware';
const AuthRouter = loadable(() => import('./AuthRouter'));
const Landing = loadable(() => import('../screens/landing/LandingPage'));

const MainRouter = () => {
  useEffect(() => {
    AuthRouter.preload();
    Landing.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path={'/'} exact component={useAuthMiddleware} />
        <Route path={'/api/auth/callback'} exact component={AuthCallback} />
        <Route path={'/applinking/invitation/:id'} exact component={Invitation} />
        <Route path={'/applinking/iam'} exact component={IAM} />
        <Route path={'/applinking/iam/:data'} exact component={IAM} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/contact" exact component={contact} />
        <Route path="/auth" component={AuthRouter} />
        <Route path="/errors" component={ErrorsRouter} />
        <Route component={PortalRouter} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default MainRouter;
