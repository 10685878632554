import React from 'react';
import { lang } from '../../lang';
import { Link } from 'react-router-dom';

const Footer = ({ style }) => {
  return (
    <footer className={`d-flex justify-content-center space-px fs--1 custom-footer`}>
      <div>
        <ul className="d-flex m-0 align-items-center justify-content-between justify-content-md-start px-5 px-md-0">
          <li>
            <Link to="/privacy-policy">{lang.portalFooter.privacyPolicy}</Link>
          </li>
          <li className="pl-4 pl-0">
            <Link to="/faq">{lang.portalFooter.faq}</Link>
          </li>
          <li className="pl-4 pl-0">
            <Link to="/contact">{lang.portalFooter.contactUs}</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
