import { range } from 'd3-array';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconJumpNext, IconJumpPrev, IconNext, IconPrev } from '../../utils/icons';
import { isAR } from '../../lang';
import { usePrevious } from '../../hooks/usePrevious';

const ITEM_WIDTH = 30;
const JUMP_LIMIT = 5;

export const Pagination = ({ totalPages = 7, page = 1, onChange, className }) => {
  const isLarge = useMemo(() => totalPages > JUMP_LIMIT, [totalPages]);
  const [currentPage, setPage] = useState(1);
  const offsetX = useMemo(() => ITEM_WIDTH * (isLarge ? 4 : 2), [isLarge]);

  useEffect(() => {
    setPage(page);
  }, [page]);

  const prevPage = usePrevious(currentPage);

  const onClick = useCallback(
    page => {
      onChange && onChange(page);
    },
    [onChange]
  );

  const next = useCallback(() => {
    onClick(Math.min(page + 1, totalPages));
  }, [onClick, page, totalPages]);

  const jumpNext = useCallback(() => {
    onClick(Math.min(page + JUMP_LIMIT, totalPages));
  }, [onClick, page, totalPages]);

  const jumpFirst = useCallback(() => {
    onClick(1);
  }, [onClick]);

  const prev = useCallback(() => {
    onClick(Math.max(page - 1, 1));
  }, [onClick, page]);

  const jumpPrev = useCallback(() => {
    onClick(Math.max(page - JUMP_LIMIT, 1));
  }, [onClick, page]);

  const jumpLast = useCallback(() => {
    onClick(totalPages);
  }, [onClick, totalPages]);

  return (
    <div className={`pagination position-relative m-auto ${className}`}>
      {isLarge ? (
        <button disabled={page === 1} className="item prev offset-right" onClick={jumpPrev}>
          <IconJumpPrev />
        </button>
      ) : null}
      <button disabled={page === 1} className={`item prev ${isLarge ? 'offset-right' : ''} `} onClick={prev}>
        <IconPrev />
      </button>
      <div className="position-relative">
        <span className="border-rounded position-absolute indicator" style={{ bottom: isAR ? 3 : 0 }} />
        <div className="overflow-hidden position-relative">
          {isLarge ? (
            <button
              disabled={totalPages === page}
              className="item next position-absolute bg-white jump-first edge-item"
              style={{
                visibility: page < 4 ? 'hidden' : 'visible',
                transition: `visibility 0s ${prevPage > page ? '0s' : '0.3s'}`,
              }}
              onClick={jumpFirst}
            >
              <span style={{ transform: 'scale(0.8)' }}>1</span>
            </button>
          ) : null}
          <div
            style={{
              transform: `translateX(${isAR ? page * ITEM_WIDTH - offsetX : offsetX - page * ITEM_WIDTH}px)`,
              width: ITEM_WIDTH * (isLarge ? 7 : 3),
            }}
            className="d-flex position-relative pages-container"
          >
            {range(totalPages).map(index => {
              const isEdge = (index === 0 && page >= 4) || (index === totalPages - 1 && page <= totalPages - 3);
              return (
                <button
                  onClick={() => onClick(index + 1)}
                  className={`item ${index + 1 === page ? 'focused' : ''} ${isEdge ? 'edge-item' : ''}`}
                >
                  {index + 1}
                </button>
              );
            })}
          </div>
          {isLarge ? (
            <button
              disabled={totalPages === page}
              className="item position-absolute next jump-last bg-white edge-item"
              style={{
                visibility: page > totalPages - 3 ? 'hidden' : 'visible',
                transition: `visibility 0s ${prevPage > page ? '0.3s' : '0s'}`,
              }}
              onClick={jumpLast}
            >
              <span style={{ transform: 'scale(0.8)' }}>{totalPages}</span>
            </button>
          ) : null}
        </div>
      </div>

      <button disabled={totalPages === page} className={`item next ${isLarge ? 'offset-left' : ''}`} onClick={next}>
        <IconNext />
      </button>
      {isLarge ? (
        <button disabled={totalPages === page} className="item next offset-left" onClick={jumpNext}>
          <IconJumpNext />
        </button>
      ) : null}
    </div>
  );
};
