import React, { Fragment, useMemo } from 'react';
import { lang, isAR } from '../../lang';
import TableActionButton, { APPOINTMENTS_ACTIONS } from './TableActionButton';
import { apptHelpers, prepareDate, prepareTime, range } from '../../utils';
import { APPOINTMENT_STATUS } from '../../constants';
import { useCallback } from 'react';
import { Table } from '../common/Table';
import { Badge } from '../common/Badge';

const AppointmentsTable = ({ bookings = { data: [] }, onActionClick, onPaginationClick, onSortChange, loading }) => {
  const lastPage = bookings?.last_page;
  const currentPage = bookings?.current_page;

  const onCancelClick = useCallback(
    id => {
      onActionClick(id, APPOINTMENTS_ACTIONS.CANCEL);
    },
    [onActionClick]
  );

  const prepareData = useCallback(() => {
    return bookings.data.map(e => {
      let status;
      if (apptHelpers.isNotArrived(e)) {
        status = APPOINTMENT_STATUS.NOT_ARRIVED;
      } else if (apptHelpers.isExpired(e)) {
        status = APPOINTMENT_STATUS.EXPIRED;
      } else {
        status = e.status;
      }

      const showActionMenu = status === APPOINTMENT_STATUS.PENDING;

      const showCancelBtn = status === APPOINTMENT_STATUS.ACCEPTED || status === APPOINTMENT_STATUS.CONFIRMED;
      let ActionButton;
      if (showActionMenu) {
        ActionButton = () => <TableActionButton id={e.id} onActionClick={onActionClick} status={status} />;
      } else if (showCancelBtn) {
        ActionButton = () => (
          <button className="btn btn-link font-weight-bold fs--1" onClick={() => onCancelClick(e.id)}>
            {lang.cancel}
          </button>
        );
      }

      return {
        ...e,
        id: e.id,
        name: e.user.full_name || lang.appointments.guest,
        mobile: e.user.mobile,
        date: prepareDate(e.start_datetime),
        time: prepareTime(e.start_datetime),
        service_name: e.service_name ? e.service_name[isAR ? 'ar' : 'en'] : '',
        comment: e.comment,
        status,
        action: showActionMenu || showCancelBtn ? <ActionButton /> : null,
        warning:
          status === APPOINTMENT_STATUS.REJECTED ? (
            <div className="info mx-2">
              <span>i</span>
            </div>
          ) : null,
      };
    });
  }, [bookings.data, onActionClick, onCancelClick]);

  const renderResponseTime = useCallback(response_time => {
    return response_time ? (
      <div className="f-1">
        <h6>{lang.appointments.response_time}</h6>
        <small>{response_time}</small>
      </div>
    ) : null;
  }, []);

  const expandRow = {
    renderer: row => {
      const crated_at_log = row.activities && row.activities.find(l => l.log_name === 'created');
      const created_at = crated_at_log?.created_at
        ? apptHelpers.formatActionDate(crated_at_log.created_at)
        : lang.appointments.not_assigned;

      const action_title = ![
        APPOINTMENT_STATUS.ACCEPTED,
        APPOINTMENT_STATUS.CANCELED,
        APPOINTMENT_STATUS.ARRIVED,
        APPOINTMENT_STATUS.CONFIRMED,
        APPOINTMENT_STATUS.NOT_ARRIVED,
      ].includes(row.status)
        ? lang.appointments[`${row.status}_at`]
        : lang.appointments.accepted_at;

      const action_value = apptHelpers.printFirstActionValue(row);
      const last_action_value = apptHelpers.printLastActionValue(row);
      const last_action_title =
        last_action_value && [APPOINTMENT_STATUS.CANCELED, APPOINTMENT_STATUS.ARRIVED].includes(row.status)
          ? lang.appointments[`${row.status}_at`]
          : null;
      const response_time = apptHelpers.printResponseTime(row);

      return (
        <div className="expand-area py-4">
          {row.comment ? (
            <>
              <h6 className={`mb-3 font-weight-bold`}>{lang.appointments.reasone_of_rejection}</h6>
              <p className="mb-3">{row.comment || '-'}</p>
            </>
          ) : null}
          <div className="d-flex">
            <div className="f-1">
              <h6>{lang.appointments.created_at}</h6>
              <small>{created_at}</small>
            </div>
            {action_value ? (
              <>
                <div className="f-1">
                  <h6 style={{ textDecoration: row.status === APPOINTMENT_STATUS.EXPIRED ? 'line-through' : 'normal' }}>
                    {action_title}
                  </h6>
                  <small>{action_value}</small>
                </div>
                {renderResponseTime(response_time)}
                {last_action_value ? (
                  <div className="f-1">
                    <h6
                      style={{ textDecoration: row.status === APPOINTMENT_STATUS.EXPIRED ? 'line-through' : 'normal' }}
                    >
                      {last_action_title}
                    </h6>
                    <small>{last_action_value}</small>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {renderResponseTime(response_time)}
                <div className="f-1" />
              </>
            )}
          </div>
        </div>
      );
    },
  };

  const columns = useMemo(() => prepareColumns(), []);
  const rows = useMemo(() => prepareData(), [prepareData]);
  return (
    <Fragment>
      <Table
        columns={columns}
        rows={rows}
        loading={loading}
        page={currentPage}
        onPaginate={onPaginationClick}
        totalPages={lastPage}
        // expandRow={expandRow}
        onSortChange={onSortChange}
      />
    </Fragment>
  );
};

export default AppointmentsTable;

const badgeFormatter = status => {
  let color = '';
  let text = '';
  switch (status) {
    case APPOINTMENT_STATUS.ARRIVED:
      color = 'success';
      text = lang.appointments.arrived;
      break;
    case APPOINTMENT_STATUS.WALK_IN:
      color = 'success';
      text = lang.appointments.walk_in;
      break;
    case APPOINTMENT_STATUS.ACCEPTED:
      color = 'info';
      text = lang.appointments.accepted;
      break;
    case APPOINTMENT_STATUS.REJECTED:
      color = 'alert';
      text = lang.appointments.rejected;
      break;
    case APPOINTMENT_STATUS.CONFIRMED:
      color = 'info';
      text = lang.appointments.confirmed;
      break;
    case APPOINTMENT_STATUS.CANCELED:
      color = 'warning';
      text = lang.appointments.canceled;
      break;
    case APPOINTMENT_STATUS.NOT_ARRIVED:
      color = 'grey';
      text = lang.appointments.not_arrived;
      break;
    case APPOINTMENT_STATUS.EXPIRED:
      color = 'grey';
      text = lang.appointments.expired;
      break;
    case APPOINTMENT_STATUS.PENDING:
      color = 'yellow';
      text = lang.appointments.pending;
      break;
    default:
      text = 'TBA';
  }
  return (
    <Badge color={color} className="rounded-capsule">
      {text}
    </Badge>
  );
};

const prepareColumns = () => {
  const timeLookup = {};
  range(0, 24).forEach(el => {
    const start = `${el}`.padStart(2, '0');
    const end = `${el + 1 !== 24 ? el + 1 : 0}`.padStart(2, '0');
    const str = `${start}-${end}`;
    timeLookup[el] = str;
  });

  return [
    {
      dataField: 'name',
      text: lang.appointments.attendeeName,
      colspan: 2,
      sort: true,
    },
    {
      dataField: 'service_name',
      text: lang.appointments.service_name,
      colspan: 1.5,
    },
    {
      dataField: 'mobile',
      text: lang.appointments.mobile,
    },
    {
      dataField: 'date',
      text: lang.appointments.date,
      sort: true,
    },
    {
      dataField: 'time',
      text: lang.appointments.time,
      sort: true,
    },
    {
      dataField: 'status',
      text: lang.appointments.status,
      formatter: badgeFormatter,
      colspan: 0.5,
    },
    {
      dataField: 'action',
      text: '',
      colspan: 0.5,
    },
  ];
};
