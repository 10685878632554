import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isAR, lang } from '../../lang';
import Flex from '../common/Flex';
import { CardBody, CustomInput, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { apptHelpers, capitalize } from '../../utils';
import PropTypes from 'prop-types';
import { date, groupBy } from '../../utils';
import { Card } from '../common/Card';
import { ResponseTimeGraph } from '../dashboard/ResponseTimeGraph';

const ResponseTimeChart = ({ stores: raw_stores, appts: raw_appts, className, loading }) => {
  const [selectedFilter, setSelectedFilter] = useState('0');
  const [data, setData] = useState([]);
  const [hrs, setHrs] = useState(8);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = useMemo(() => [lang.dashboardItems.day, lang.dashboardItems.week, lang.dashboardItems.month], [isAR]);

  const calculateResponseTime = useCallback((store, groupedApptsByStore) => {
    const storeWithAppts = groupedApptsByStore[store.id] || [];
    const responseTime = storeWithAppts.reduce((carrier, appt) => {
      return appt.waitingTime + carrier;
    }, 0);
    return !!storeWithAppts.length ? responseTime / storeWithAppts.length : null;
  }, []);

  const getDayAppts = useCallback(appts => {
    return appts.filter(appt => {
      return new Date(appt.start_datetime).getDate() === new Date().getDate();
    });
  }, []);

  const getWeekAppts = useCallback(appts => {
    return appts.filter(appt => {
      return date.differenceInDays(new Date(), new Date(appt.start_datetime)) <= 7;
    });
  }, []);

  const getMonthAppts = useCallback(appts => {
    return appts.filter(appt => {
      return date.differenceInDays(new Date(), new Date(appt.start_datetime)) <= 31;
    });
  }, []);

  const prepareData = useCallback(() => {
    // calculate each appt waiting time
    const updated_appts = raw_appts.map(appt => ({
      ...appt,
      waitingTime: apptHelpers.calculateReposneTime(appt),
    }));

    // filter appts based on selected time period.
    let res = [];
    if (selectedFilter === '0') {
      res = getDayAppts(updated_appts);
      setHrs(8);
    } else if (selectedFilter === '1') {
      // week selected
      res = getWeekAppts(updated_appts);
      setHrs(40);
    } else {
      res = getMonthAppts(updated_appts);
      setHrs(160);
    }

    // group updated_appts by store name
    const index = 'store.id';
    const groupedApptsByStore = groupBy(res, index);

    // calculate each store response time
    const updated_stores = raw_stores
      .map(s => ({
        ...s,
        responseTime: calculateResponseTime(s, groupedApptsByStore),
      }))
      .sort((a, b) => {
        return b.responseTime - a.responseTime;
      });

    const data = updated_stores
      .map(s => ({
        value: s.responseTime || 0,
        id: s.id,
        name: s.name.ar,
        name_en: s.name.en,
        auto_accept: s.configuration?.['auto-accept'] || s.configuration?.['auto-accept'] === undefined,
      }))
      .slice(0, 7);
    setData(data);
  }, [selectedFilter, raw_stores, raw_appts, getDayAppts, getWeekAppts, getMonthAppts, calculateResponseTime]);

  useEffect(() => {
    prepareData();
  }, [selectedFilter, raw_stores, raw_appts, prepareData]);

  const onChange = e => setSelectedFilter(e.target.value);

  return (
    <Card loading={loading} className={className + ' dashboard-card h-100 w-100'}>
      <CardHeader className="row m-1 justify-content-between mb-0">
        <Flex className="align-items-center">
          <div className="dashboard-card-title">{lang.dashboardItems.branchResponseTime}</div>
          <div className="mx-3">
            <div className="info-border" id="responseTimeGraphInfo">
              i
            </div>
            <UncontrolledTooltip
              placement={isAR ? 'left' : 'right'}
              hideArrow={true}
              target="responseTimeGraphInfo"
              innerClassName="graph-info-text bg-white text-black border-500 shadow-lg border rounded-0"
            >
              {lang.dashboardItems.branchResponseTimeDescription}
            </UncontrolledTooltip>
          </div>
        </Flex>
        <Flex>
          <CustomInput
            type="select"
            id="exampleCustomSelect"
            bsSize="sm"
            className="select-month mr-2 lightgrey-yesser"
            value={selectedFilter}
            onChange={onChange}
          >
            {filters.map((filter, index) => (
              <option key={index} value={index}>
                {capitalize(filter)}
              </option>
            ))}
          </CustomInput>
        </Flex>
      </CardHeader>
      <CardBody className="h-100 d-flex justify-content-center pt-0">
        <ResponseTimeGraph min={hrs} data={data} />
      </CardBody>
    </Card>
  );
};

export default ResponseTimeChart;

ResponseTimeChart.propTypes = {
  appts: PropTypes.array.isRequired,
  stores: PropTypes.array.isRequired,
};
