import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardBody, CardHeader, CustomInput, UncontrolledTooltip } from 'reactstrap';
import Flex from '../common/Flex';
import { isAR, lang } from '../../lang';
import Col from 'reactstrap/es/Col';
import {
  getDayAppts,
  getPreviousDayAppts,
  getWeekAppts,
  getPreviousWeekAppts,
  getMonthAppts,
  getPreviousMonthAppts,
  prepareBranchActivity,
} from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../common/Card';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Filters } from '../branches-activity/Filters';

const BranchesActivity = ({ stores: raw_stores, appts: raw_appts, className, loading }) => {
  const [selectedFilter, setSelectedFilter] = useState(Filters.VALUES.DAY);
  const [stores, setStores] = useState([]);

  const prepareData = useCallback(() => {
    // filter appts based on selected time period.
    let currentAppts = [];
    let previousAppts = [];
    if (selectedFilter === Filters.VALUES.DAY) {
      currentAppts = getDayAppts(raw_appts);
      previousAppts = getPreviousDayAppts(raw_appts);
    } else if (selectedFilter === Filters.VALUES.WEEK) {
      // week selected
      currentAppts = getWeekAppts(raw_appts);
      previousAppts = getPreviousWeekAppts(raw_appts);
    } else {
      currentAppts = getMonthAppts(raw_appts);
      previousAppts = getPreviousMonthAppts(raw_appts);
    }

    const updated_stores = prepareBranchActivity(currentAppts, previousAppts, raw_stores);
    setStores(updated_stores);
  }, [selectedFilter, raw_stores, raw_appts]);

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  const onChange = e => setSelectedFilter(e.target.value);

  const printRate = (originalNow, originalPrev, isUpGood) => {
    // replacing 0 with 1 for prev so we dont have inifinity number
    // if prev is 0 increase now by 1 so the increase precentage is more rational
    const prev = originalPrev || 1;
    const now = originalPrev ? originalNow : originalNow + 1;
    const isIncreasing = now > prev;
    const rate = Math.round((Math.abs(now - prev) / prev) * 100);
    // hiding the rate if rate is zero
    const shouldHideRate = rate === 0 || (now === 1 && prev === 1);
    return (
      <span
        className={classNames('mx-auto', {
          'dashboard-graph-numbers-green-small': (isIncreasing && isUpGood) || (!isIncreasing && !isUpGood),
          'dashboard-graph-numbers-red-small': (!isIncreasing && isUpGood) || (isIncreasing && !isUpGood),
        })}
        style={{ direction: 'ltr' }}
      >
        {shouldHideRate ? '' : now > prev ? '+' : '-'}
        {shouldHideRate ? '' : `${rate}%`}
        {shouldHideRate ? null : (
          <FontAwesomeIcon className="mt-auto mx-1" icon={isIncreasing ? faCaretUp : faCaretDown} />
        )}
      </span>
    );
  };

  const targetedStores = stores
    ?.sort((a, b) => {
      return b.totalAppts - a.totalAppts;
    })
    .slice(0, 4);

  return (
    <Card loading={loading} className={className + 'dashboard-card branches-activity w-100 h-100'}>
      <CardHeader className="row m-1 justify-content-between mb-0">
        <Flex className="align-items-center">
          <div className="dashboard-card-title">{lang.dashboardItems.branchesActivity}</div>
        </Flex>
        <Flex>
          <CustomInput
            type="select"
            id="exampleCustomSelect"
            bsSize="sm"
            className="select-month mr-2 lightgrey-yesser"
            value={selectedFilter}
            onChange={onChange}
          >
            <option value={Filters.VALUES.DAY}>{lang.dashboardItems.day}</option>
            <option value={Filters.VALUES.WEEK}>{lang.dashboardItems.week}</option>
            <option value={Filters.VALUES.MONTH}>{lang.dashboardItems.month}</option>
          </CustomInput>
        </Flex>
      </CardHeader>
      <CardBody className="justify-content-center pt-2">
        <div className="container">
          <div className="row justify-content-end mb-3">
            <Col className="col-9">
              <div className="row justify-content-around text-center dashboard-graph-label-column">
                <div className="col-2 dashboard-card-title card-title">{lang.appointments.accepted}</div>
                <div className="col-2 dashboard-card-title card-title">{lang.appointments.rejected}</div>
                <div className="col-2 dashboard-card-title card-title">{lang.appointments.canceled}</div>
                <div className="col-2 text-grey dashboard-card-title card-title">{lang.dashboardItems.noAction}</div>
              </div>
            </Col>
          </div>
          {targetedStores.map((s, index) => {
            const label_len = window.innerWidth / 50
            const storeName = s[isAR ? 'name_ar' : 'name_en'];
            return (
              <div key={index}>
                <div className="row justify-content-between align-items-center">
                  <Col className="col-3 justify-content-start">
                    <div className="dashboard-graph-label-row" id={'branchFullName' + index}>
                      {storeName.length > label_len ? storeName.slice(0, label_len) + '..' : storeName}
                    </div>
                    <UncontrolledTooltip target={'branchFullName' + index}>
                      {storeName}
                    </UncontrolledTooltip>
                  </Col>
                  <Col className="col-9">
                    <div className="row justify-content-around text-center dashboard-graph-numbers">
                      <div className="col-2">
                        <div className="row justify-content-center">
                          <div>
                            <span>{Math.round((s.totalAccepted / s.totalAppts) * 100) || 0}%</span>
                          </div>
                          <div className="ml-2">
                            {printRate(s.totalAccepted, s.totalPreviousAccepted, true)}
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row justify-content-center">
                          <div>
                            <div>{Math.round((s.totalRejected / s.totalAppts) * 100) || 0}%</div>
                          </div>
                          <div className="ml-2">
                            {printRate(s.totalRejected, s.totalPreviousRejected)}
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <span>{Math.round((s.totalCanceled / s.totalAppts) * 100) || 0}%</span>
                      </div>
                      <div className="col-2 dashboard-graph-numbers-grey">
                        <span>
                        {Math.round((s.totalNoAction / s.totalAppts) * 100) || 0}%
                      </span>
                      </div>
                    </div>
                  </Col>
                </div>
                {index !== targetedStores.length - 1 ? <hr className="dashboard-data-devider" /> : null}
              </div>
            );
          })}
        </div>
      </CardBody>
      <small className="m-auto pb-2">
        <Link
          to={{ pathname: '/dashboard/branches-activity', search: `?duration=${selectedFilter}` }}
          className="font-weight font-weight-bold text-capitalize text-decoration-underlined"
        >
          <small>
            <u>{lang.dashboardItems.showMore}</u>
          </small>
        </Link>
      </small>
    </Card>
  );
};

export default BranchesActivity;

BranchesActivity.propTypes = {
  appts: PropTypes.array.isRequired,
  stores: PropTypes.array.isRequired,
};
