import React, { useState, memo, useCallback } from 'react';
import { Card } from '../common/Card';
import { CardBody, CustomInput } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import { VerticalBarGraph } from '../../components/dashboard/VerticalBarGraph';
import { isAR, lang } from '../../lang';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { date, capitalize, apptHelpers } from '../../utils';
import { APPOINTMENT_STATUS } from '../../constants';

export const ApptStatuses = memo(({ data: rawData, className, loading }) => {
  const xAxisGuides = useMemo(() => ['Pending', 'Canceled', 'Arrived', 'Not Arrived', 'Accepted', 'Rejected'], []);
  const xAxisLabels = useMemo(
    () => [
      lang.appointments.pending,
      lang.appointments.canceled,
      lang.appointments.arrived,
      lang.appointments.not_arrived,
      lang.appointments.accepted,
      lang.appointments.rejected,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAR]
  );
  // xAxisLabels.sort(() => (isAR ? -1 : 1));
  const [selectedFilter, setSelectedFilter] = useState('0');
  const [data, setData] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = useMemo(() => [lang.dashboardItems.day, lang.dashboardItems.week, lang.dashboardItems.month], [isAR]);

  const prepareData = useCallback(
    rawData => {
      let res = [];
      if (selectedFilter === '0') {
        // day selected
        res = rawData.filter(appt => date.isToday(new Date(appt.start_datetime)));
      } else if (selectedFilter === '1') {
        // week selected
        res = rawData.filter(appt => {
          return date.differenceInDays(new Date(), new Date(appt.start_datetime)) <= 7;
        });
      } else {
        res = rawData.filter(appt => {
          // month selected
          return date.differenceInDays(new Date(), new Date(appt.start_datetime)) <= 31;
        });
      }

      let resTemplate = [0, 0, 0, 0, 0, 0];

      res.forEach(appt => {
        let status = appt.status;
        if (apptHelpers.isNotArrived(appt)) {
          status = 'NOT ARRIVED';
        } else if (status === APPOINTMENT_STATUS.CONFIRMED) {
          status = APPOINTMENT_STATUS.ACCEPTED;
        } else if (apptHelpers.isExpired(appt)) {
          status = APPOINTMENT_STATUS.EXPIRED;
        }

        const index = xAxisGuides.findIndex(label => label.toUpperCase() === status.toUpperCase());
        index > -1 && resTemplate[index]++;
      });

      setData(resTemplate.map((el, i) => ({ label: xAxisLabels[i], value: el })));
    },
    [xAxisGuides, selectedFilter, xAxisLabels]
  );

  useEffect(() => {
    prepareData(rawData);
  }, [rawData, prepareData]);

  const onChange = e => setSelectedFilter(e.target.value);
  return (
    <Card loading={loading} className={className} mid>
      <FalconCardHeader
        titleClass="dashboard-card-title"
        title={lang.dashboardItems.AppointmentsStatuses}
        light={false}
        titleTag="h6"
        className="pb-0"
      >
        <Flex>
          <CustomInput
            type="select"
            bsSize="sm"
            className="select-month mr-2 lightgrey-yesser"
            value={selectedFilter}
            onChange={onChange}
          >
            {filters.map((filter, index) => (
              <option key={index} value={index}>
                {capitalize(filter)}
              </option>
            ))}
          </CustomInput>
        </Flex>
      </FalconCardHeader>

      <CardBody className="h-100 d-flex justify-content-center align-items-center">
        <VerticalBarGraph data={data} />
      </CardBody>
    </Card>
  );
});
