import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ title, subtitle, src, placeholder_src, size }) => (
  <div className="d-flex pt-3 pb-1 justify-content-center flex-column align-items-center " style={{ flex: 1.3 }}>
    <img alt="logo" className={`brand-avatar ${size || ''}`} src={src || placeholder_src} />
    <div className="d-flex flex-column justify-content-center align-items-center">
      <p className="mb-2 font-weight-bold">{title}</p>
      <p className="mb-0 text-grey">{subtitle}</p>
    </div>
  </div>
);

Avatar.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
  placeholder_src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'l']),
};

Avatar.defaultProps = {
  size: 'l',
};

export default Avatar;
