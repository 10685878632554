import React, { useState, Fragment, useEffect } from 'react';
import { Card, CardBody, Form, CardFooter, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Confirm from '../../components/alert/confirm';
import Loader from '../../components/common/Loader';
import EditForm from '../../components/services/Form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { toast } from 'react-toastify';

const EditModerator = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const { register, handleSubmit, errors, watch, setValue, control } = useForm();
  const service_id = params.id;

  const fetchData = async () => {
    setLoading(true);
    const { data } = await request.GET(APIs.SERVICES, { service_id: service_id });
    setService({
      ...data.data,
      service_name_en: data.data.name_en,
      service_name_ar: data.data.name_ar,
    });

    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service_id]);

  const update = async form => {
    try {
      const body = {
        ...form,
        ar_name: form.service_name_ar,
        en_name: form.service_name_en,
        duration: form.duration.value,
        branch_id: service.branch_id,
        service_id,
        shift_id: form.shifts ? form.shifts.map(shift => shift.value).filter(id => id) : [],
        staff: form.staffs ? form.staffs.map(staff => staff.value).filter(id => id) : [],
      };
      !body.pre_msg && delete body.pre_msg;
      !body.ar_post_msg && delete body.ar_post_msg;
      !body.en_post_msg && delete body.en_post_msg;

      const { data } = await request.PUT(APIs.SERVICES, body);
      setService(data.data);
      toast.success(lang.services.editService.success);
      history.push('/services');
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitData = async form => {
    if (form.capacity < service.capacity || form.duration.value < service.duration) {
      setShowAlert(true);
    } else {
      update(form);
    }
  };

  return (
    <Fragment>
      <FalconCardHeader title={lang.services.editService.title} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        {loading ? (
          <Loader />
        ) : (
          <>
            <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
              <EditForm
                errors={errors}
                data={service}
                register={register}
                watch={watch}
                setValue={setValue}
                control={control}
              />
            </CardBody>
            <CardFooter className={'px-md-6 bg-light d-flex'}>
              <Button color="primary" className="ml-auto px-5" type="submit" transform="down-1 shrink-4">
                {lang.btn.save}
              </Button>
            </CardFooter>
          </>
        )}
      </Card>
      <Confirm
        open={showAlert}
        confirm={handleSubmit(update)}
        cancel={() => setShowAlert(false)}
        confrim_label={lang.services.editService.confirm}
        cancel_label={lang.services.editService.cancel}
        title={lang.services.editService.title}
        body={lang.services.editService.warning}
      />
    </Fragment>
  );
};

export default EditModerator;
