import React from "react";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prepareURL, APIs } from "../../request";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import { date } from "../../utils";
import { IS_PRODUCTION } from "../../constants";

export default function UserManual(props) {
  const sizeInMB = (props.size / (1024 * 1024)).toFixed(1);

  let uploadingDate = date.format(props.date, "dd LLLL yyyy");

  return (
    <Card className="mt-2"> 
      <CardBody>
        <div className="d-flex justify-content-between align-items-center flex-wrap flex-xl-nowrap flex-lg-wrap">
          <div className="d-flex justify-content-start align-items-center flex-row flex-wrap flex-xl-nowrap flex-lg-wrap">
            <FontAwesomeIcon icon="file-pdf" style={{ fontSize: "40px", color: "#e33900" }} />
            <div className="d-flex justify-content-start align-items-between flex-column px-3 py-2">
              <div className="font-weight-bold" style={{ fontSize: "1rem" }}>
                {props.title}
              </div>
              <div className="d-flex justify-content-between  align-items-center  flex-row  mt-2">
                {IS_PRODUCTION ? null : <div style={{ fontSize: "0.7rem" }}>{uploadingDate}</div>}                
                <div style={{ fontSize: "0.7rem" }}>{sizeInMB} MB</div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between  w-20">
            <div className="m-1 px-1 pt-1 text-primary">
              <a
                href={prepareURL(APIs.USER_MANUALS, { filename: props.title })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEye} style={{ fontSize: "20px", cursor: "pointer" }} />
              </a>
            </div>
            <div className="m-1 px-1 text-primary">
              <a
                href={prepareURL(APIs.USER_MANUALS, {
                  filename: props.title,
                  attachment: true
                })}
                download
              >
                <FontAwesomeIcon icon={faDownload} style={{ fontSize: "20px", cursor: "pointer" }} />
              </a>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
