import React, { useState, useEffect } from 'react';
import { StatusCard } from '../appointments/StatusCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import clipboard from '../../assets/img/clipboard.svg';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { lang } from '../../lang';
import { Card } from '../common/Card';
import { theme } from '../../constants/theme';
import { APPOINTMENT_STATUS } from '../../constants';
import { date } from '.../../../src/utils/date';

export function TotalAps({ appts = [], className, loading }) {
  const [totalAppts, setTotalAppts] = useState(0);
  const [arrivedPercentage, setArrivedPercentage] = useState(0);
  const [notArrivedPercentage, setNotArrivedPercentage] = useState(0);

  useEffect(() => {
    const todayAppts = appts.filter(ap => date.isToday(new Date(ap.start_datetime)));
    const total = todayAppts.length;

    if (total) {
      setTotalAppts(todayAppts.length);
      const notArrivedAppts = todayAppts.filter(el => {
        return (
          (el.status === APPOINTMENT_STATUS.CONFIRMED || el.status === APPOINTMENT_STATUS.ACCEPTED) &&
          date.isAfter(new Date(), date.createDate(el.end_datetime))
        );
      }).length;

      const arrivedAppts = todayAppts.filter(ap => ap.status === APPOINTMENT_STATUS.ARRIVED).length;

      let arrivedResult = (arrivedAppts / total) * 100;
      arrivedResult = Number.isInteger(arrivedResult)
        ? arrivedResult
        : Number.parseFloat(arrivedResult).toPrecision(arrivedResult > 10 ? 3 : 2);
      setArrivedPercentage(arrivedResult);

      let notArrivedResult = (notArrivedAppts / total) * 100;
      notArrivedResult = Number.isInteger(notArrivedResult)
        ? notArrivedResult
        : Number.parseFloat(notArrivedResult).toPrecision(notArrivedResult > 10 ? 3 : 2);

      setNotArrivedPercentage(notArrivedResult);
    }
  }, [appts]);

  return (
    <div className={`d-flex f-1 h-100 flex-column justify-content-between ${className || ''}`}>
      <div className="space-hlf-pb h-50">
        <Card className="d-flex status-card-wide h-100 f-1" fetching={loading}>
          <div className="d-flex flex-column align-items-center p-xxl-4 p-xl-3 p-lg-2">
            <div>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="icon"
                style={{ fontSize: '40px', color: theme.sapphireBlueColor, textAlign: 'center', padding: 3 }}
              />
            </div>
            <div className="dashboard-card-title" style={{ padding: 4, fontWeight: 'bold', letterSpacing: 1 }}>
              {lang.dashboardItems.totalAppointments}
            </div>
            <div style={{ fontSize: '20px', color: theme.sapphireBlueColor, fontWeight: 'bold', padding: 3 }}>
              {totalAppts}
            </div>
          </div>
        </Card>
      </div>
      <div className="d-flex h-50 f-1 space-hlf-pt justify-content-between align-items-end">
        <div className="f-1 h-100 space-hlf-pr">
          <StatusCard
            loading={loading}
            className="h-100 space-hlf-pr"
            title={lang.appointments.arrived}
            color={theme.mintGreenColor}
            icon={faClipboardCheck}
            value={`${arrivedPercentage}%`}
            iconSize={25}
          />
        </div>
        <div className="f-1 h-100 space-hlf-pl">
          <StatusCard
            loading={loading}
            className="h-100 "
            title={lang.appointments.not_arrived}
            color={theme.lightLavnderColor}
            iconType="svg"
            icon={clipboard}
            iconSize={20}
            value={`${notArrivedPercentage}%`}
          />
        </div>
      </div>
    </div>
  );
}
