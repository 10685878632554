/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Label } from 'reactstrap';
import { lang } from '../../lang';
import WizardInput from '../../components/auth/wizard/WizardInput';
import TagsSorter from '../common/TagsSorter';
import { useContext } from 'react';
import AppContext from '../../context/Context';
import { APIs, request } from '../../request';

export const DURATION_OPTIONS = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 60, label: 60 },
];

export default ({ className, errors, register, control, watch, data = {}, setValue }) => {
  const { selectedStore } = useContext(AppContext);
  const [loadingShifts, setLoadingShifts] = useState(false);
  const [loadingStaff, setLoadingStaff] = useState(false);
  const [shiftOptions, setShiftOptions] = useState([]);
  const allShiftOption = useMemo(() => ({ value: 0, label: lang.services.all_shifts }), []);
  const allStaffOption = useMemo(() => ({ value: 0, label: lang.services.all_staffs }), []);

  const [staffs, setStaff] = useState([]);

  const shiftsAnswers = watch(
    'shifts',
    data.shifts && data.shifts.length
      ? data.shifts.map(({ id, name }) => ({ value: id, label: name }))
      : [allShiftOption]
  );

  const staffsAnswers = watch(
    'staffs',
    data.staffs && data.staffs.length
      ? data.staffs.map(({ id, name }) => ({ value: id, label: name }))
      : [allStaffOption]
  );

  useEffect(() => {
    const length = shiftsAnswers && shiftsAnswers.length;
    if (length > 1) {
      if (!shiftsAnswers[length - 1].value) {
        setValue('shifts', [allShiftOption]);
      } else if (!shiftsAnswers[length - 2].value) {
        shiftsAnswers.splice(length - 2, 1);
        setValue('shifts', [...shiftsAnswers]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsAnswers]);

  useEffect(() => {
    const length = staffsAnswers && staffsAnswers.length;
    if (length > 1) {
      if (!staffsAnswers[length - 1].value) {
        setValue('staffs', [allStaffOption]);
      } else if (!staffsAnswers[length - 2].value) {
        staffsAnswers.splice(length - 2, 1);
        setValue('staffs', [...staffsAnswers]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffsAnswers]);

  const fetchShifts = useCallback(async () => {
    setLoadingShifts(true);
    const { data } = await request.GET(APIs.STORE_SHIFTS, { branch_id: selectedStore.id });
    const shiftsData = data.data.map(shift => ({ value: shift.id, label: shift.name }));
    shiftsData.unshift(allShiftOption);
    setShiftOptions(shiftsData);
    setLoadingShifts(false);
  }, [selectedStore, allShiftOption]);

  useEffect(() => {
    selectedStore.id && fetchShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStaff = useCallback(async () => {
    setLoadingStaff(true);
    const {
      data: { data },
    } = await request.GET(APIs.STORE_STAFF, { branch_id: selectedStore.id, role_id: 4 });
    const staffData = data.map(staff => ({ value: staff.id, label: staff.first_name }));
    staffData.unshift(allStaffOption);
    setStaff(staffData);
    setLoadingStaff(false);
  }, [selectedStore.id]);

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  const preventNegativeValues = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
    }
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <article className={`p-3 ${className} `}>
      <section className="p-0">
        <TagsSorter className="d-flex">
          <div className="d-inline-block col-6">
            <Label for="service_name_en">{lang.services.labels.name_en}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="service_name_en"
              name="service_name_en"
              errors={errors}
              onBlur={e =>
                (e.target.value = e.target.value
                  .replace(/^\s+/, '')
                  .replace(/\s\s+/g, ' ')
                  .replace(/\s+$/, ''))
              }
              defaultValue={data.name ? data.name.en : 'New Service'}
              innerRef={register({
                required: lang.requiredField,
                pattern: {
                  value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]*$/,
                  message: lang.validationMessage.enterEnglishLetter,
                },
              })}
            />
          </div>
          <div className="d-inline-block col-6">
            <Label for="service_name_ar">{lang.services.labels.name_ar}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="service_name_ar"
              name="service_name_ar"
              errors={errors}
              onBlur={e =>
                (e.target.value = e.target.value
                  .replace(/^\s+/, '')
                  .replace(/\s\s+/g, ' ')
                  .replace(/\s+$/, ''))
              }
              defaultValue={data.name ? data.name.ar : 'خدمة جديدة'}
              innerRef={register({
                required: lang.requiredField,
                pattern: {
                  value: /^([\u0600-\u06FF0-9]+\s)*[\u0600-\u06FF0-9]*$/,
                  message: lang.validationMessage.enterArabicLetter,
                },
              })}
            />
          </div>
        </TagsSorter>
        <div className="mt-4 d-flex">
          <div className="d-inline-block col-6">
            <Label for="duration">{lang.services.labels.duration}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              id="duration"
              name="duration"
              customType="react-select"
              options={DURATION_OPTIONS}
              control={control}
              errors={errors}
              placeholder=""
              rules={{ required: lang.requiredField }}
              defaultValue={data.duration && DURATION_OPTIONS.find(({ value }) => value === data.duration)}
            />
          </div>
          <div className="d-inline-block col-6">
            <Label for="capacity">{lang.services.labels.customers_per_appointment}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <WizardInput
              type="number"
              id="capacity"
              name="capacity"
              onKeyDown={preventNegativeValues}
              errors={errors}
              defaultValue={data.capacity}
              min={0}
              innerRef={register({
                valueAsNumber: true,
                required: lang.requiredField,
                pattern: {
                  value: /^[0-9]+$/,
                  message: lang.validationMessage.positiveNumbers,
                },
                min: { value: 1, message: lang.validationMessage.positiveNumbers },
              })}
            />
          </div>
        </div>
        <div className="mt-4 d-flex">
          <div className="d-inline-block col-6">
            <Label for="shifts">{lang.services.labels.shifts}</Label>
            <small className="d-inline-block text-danger mx-1">*</small>
            <small className="mx-2">{lang.services.labels.note_shifts}</small>
            <WizardInput
              id="shifts"
              name="shifts"
              customType="react-select"
              options={shiftOptions}
              control={control}
              errors={errors}
              isMulti
              rules={{ required: lang.requiredField }}
              placeholder={loadingShifts ? lang.services.labels.loading_shifts : ''}
              isDisabled={!selectedStore.id}
              defaultValue={
                data.shifts && data.shifts.length
                  ? data.shifts.map(({ id, name }) => ({ value: id, label: name }))
                  : [allShiftOption]
              }
            />
          </div>

          <div className="d-inline-block col-6">
            <Label for="staffs">{lang.services.labels.staffs}</Label>
            <WizardInput
              id="staffs"
              name="staffs"
              customType="react-select"
              options={staffs}
              control={control}
              errors={errors}
              isMulti
              rules={{ required: lang.requiredField }}
              placeholder={loadingStaff ? lang.services.labels.loading_shifts : ''}
              isDisabled={!selectedStore.id}
              defaultValue={
                staffs && staffs.length ? staffs.map(({ id, name }) => ({ value: id, label: name })) : [allStaffOption]
              }
            />
          </div>
        </div>
        <TagsSorter className="d-flex mt-4">
          <div className="d-inline-block col-6">
            <Label for="post_msg_en" dangerouslySetInnerHTML={{ __html: lang.services.labels.post_msg_en }} />
            <WizardInput
              id="en_post_msg"
              type="textarea"
              name="en_post_msg"
              errors={errors}
              defaultValue={data?.post_msg?.en}
              innerRef={register({
                pattern: {
                  value: /^[a-zA-Z0-9\W ]*$/,
                  message: lang.validationMessage.enterEnglishLetter,
                },
              })}
            />
          </div>
          <div className="d-inline-block col-6">
            <Label for="post_msg" dangerouslySetInnerHTML={{ __html: lang.services.labels.post_msg }} />
            <WizardInput
              id="ar_post_msg"
              type="textarea"
              name="ar_post_msg"
              errors={errors}
              defaultValue={data?.post_msg?.ar}
              innerRef={register({
                pattern: {
                  value: /^([\u0600-\u06FF0-9]+\W\s)*[\u0600-\u06FF0-9\W]*$/,
                  message: lang.validationMessage.enterArabicLetter,
                },
              })}
            />
          </div>
        </TagsSorter>
      </section>
    </article>
  );
};
