import React, { useEffect, useCallback, useRef, memo, useMemo } from 'react';
import { d3 } from '../../utils';
import PropTypes from 'prop-types';

const status_graph_width = 600;
const status_graph_height = 350;
const status_bar_width = 20;
const text_height = 20;
const margin = { top: 10, right: 20, left: 30, bottom: 25 };
const xBarIndent = status_bar_width / 2;
const tooltip_width = 160;
const tooltip_height = 40;

export const VerticalBarGraph = memo(({ data = [], barColor = `rgb(0,92,153)`, barUnit = '' }) => {
  const svgRef = useRef();
  const yAxisRef = useRef();
  const max = useMemo(() => {
    let max = d3.max(data, d => d?.value);
    return Math.max(10, max) * 1.1;
  }, [data]);

  const xAxisLabels = useMemo(() => ['', ...data?.map(el => el?.label)], [data]);

  const xScale = useCallback(
    d3
      .scaleLinear()
      .domain([-1, 6])
      .range([margin.left, status_graph_width - margin.right]),
    []
  );

  const yScale = useCallback(
    d3
      .scaleLinear()
      .domain([0, max])
      .range([status_graph_height - margin.bottom, margin.top]),
    [data]
  );

  const xAxis = useCallback(
    d3
      .axisBottom()
      .scale(xScale)
      .tickFormat((d, i) => {
        return xAxisLabels[i];
      }),
    [xAxisLabels]
  );

  xAxis.ticks(5);

  const yAxis = useCallback(
    d3
      .axisLeft()
      .scale(yScale)
      .tickSize(-status_graph_width)
      .ticks(data?.length + 1)
      .tickFormat(d => {
        return Number.isInteger(d) ? d : '';
      }),
    [data]
  );

  const showTip = useCallback((e, d) => {
    d?.tooltip &&
      d3
        .select('#vertical-bar-tooltip')
        .html(
          `<div class="small m-0">
          <small class="w-100 text-center">${d?.tooltip}</small>
        </div>
        `
        )
        .style('left', `${e.pageX - tooltip_width / 2}px`)
        .style('top', `${e.pageY - tooltip_height - 25}px`)
        .style('display', 'block');
  }, []);

  const hideTip = useCallback(() => d3.select('#vertical-bar-tooltip').style('display', 'none'), []);

  useEffect(() => {
    // init tootip
    d3.select('body')
      .append('div')
      .attr('id', 'vertical-bar-tooltip')
      .style('display', 'none')
      .style('width', `${tooltip_width}px`)
      .style('min-height', `${tooltip_height}px`)
      .style('height', 'auto');
  }, []);

  useEffect(() => {
    d3.select(svgRef.current)
      .select('.x-axis')
      .remove();
    d3.select(svgRef.current)
      .append('g')
      .classed('x-axis', true)
      .attr('transform', () => `translate(0, ${status_graph_height - margin.bottom})`)
      .call(xAxis)
      .selectAll('.tick text')
      .call(d3.helpers.wrapXLabel, xScale(0) - 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxis]);

  useEffect(() => {
    const trans = d3
      .select(svgRef.current)
      .transition()
      .duration(1000);

    d3.select(svgRef.current)
      .selectAll('rect')
      .data(data, (_, i) => i)
      .join(
        enter => {
          return enter
            .append('rect')
            .attr('transform', (d, i) => `translate(${xScale(i) - xBarIndent}, ${status_graph_height - margin.bottom})`)
            .attr('width', status_bar_width)
            .attr('height', d => 0)
            .attr('fill-opacity', 1);
        },
        update => update,
        exit => {
          exit
            .transition(trans)
            .attr('transform', (d, i) => `translate(${xScale(i) - xBarIndent}, ${status_graph_height - margin.bottom})`)
            .attr('height', 0)
            .attr('fill-opacity', 0);
        }
      )
      .on('mouseover', showTip)
      .on('mousemove', showTip)
      .on('mouseout', hideTip)
      .transition(trans)
      .attr(
        'transform',
        (d, i) =>
          `translate(${xScale(i) - xBarIndent}, ${status_graph_height - (status_graph_height - yScale(d?.value))})`
      )
      .attr('width', status_bar_width)
      .attr('height', d => status_graph_height - (yScale(d?.value) + margin.bottom))
      .attr('fill', barColor)
      .attr('ry', 2)
      .attr('rx', 2);

    d3.select(yAxisRef.current).call(yAxis);

    d3.select(svgRef.current)
      .selectAll('text.label')
      .data(data, (_, i) => i)
      .join(
        enter => {
          return enter
            .append('text')
            .classed('label', true)
            .attr(
              'transform',
              (d, i) =>
                `translate(${xScale(i) - xBarIndent + 10}, ${status_graph_height - margin.bottom - text_height})`
            )
            .attr('fill-opacity', 1);
        },
        update => update,
        exit => {
          exit
            .transition(trans)
            .attr(
              'transform',
              (d, i) =>
                `translate(${xScale(i) - xBarIndent + 10}, ${status_graph_height - margin.bottom - text_height})`
            )
            .attr('fill-opacity', 0);
        }
      )
      .transition(trans)
      .attr(
        'transform',
        (d, i) =>
          `translate(${xScale(i) - xBarIndent + 10}, ${status_graph_height -
            (status_graph_height - yScale(d?.value)) -
            text_height})`
      )
      .attr('dy', '.75em')
      .attr('text-anchor', 'middle')
      .text(function(d) {
        return `${d?.value}${barUnit}`;
      });
  }, [xAxis, yAxis, data, xScale, yScale, barColor, barUnit, hideTip, showTip]);

  return (
    <svg
      viewBox={`0 0 ${status_graph_width} ${status_graph_height}`}
      style={{ overflow: 'visible' }}
      ref={svgRef}
      className="appt_statuses_container"
    >
      <g className="y-axis" ref={yAxisRef} transform={`translate(${margin.left}, 0)`} />
    </svg>
  );
});

VerticalBarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  barColor: PropTypes.string,
  barUnit: PropTypes.string,
};
