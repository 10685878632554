import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import { Card } from '../../components/common/Card';
import { Pagination } from '../../components/common/Pagination';
import { SORT_TYPES } from '../../constants';
import useTableSort from '../../hooks/useSort';
import { isAR } from '../../lang';
import { IconSort, IconSortUp, IconSortDown } from '../../utils';

export const Table = memo(
  ({ columns = [], rows: source = [], loading, onPaginate, totalPages, page, onSortChange, expandRow }) => {
    const [rows, setRows] = useState(source);

    useEffect(() => {
      setRows(source);
    }, [source]);

    return (
      <div className="appointments-table">
        <TableHead columns={columns} setRows={setRows} rows={rows} onSortChange={onSortChange} />
        <TableBody
          data={rows}
          columns={columns}
          loading={loading}
          onPaginate={onPaginate}
          totalPages={totalPages}
          expandRow={expandRow}
          page={page}
        />
      </div>
    );
  }
);

const TableHead = ({ columns, setRows, rows, onSortChange }) => {
  const { onSortClick, activeColumn, sortType, sortedRows } = useTableSort(rows, onSortChange);

  useEffect(() => {
    sortedRows && setRows(sortedRows);
  }, [setRows, sortedRows]);

  return (
    <div className="appt-tbl-header d-flex my-2 px-5">
      {columns.map((el, index) => (
        <TableHeadItem
          item={el}
          setRows={setRows}
          rows={rows}
          onSortClick={onSortClick}
          sortType={activeColumn === el.dataField ? sortType : SORT_TYPES.NONE}
          key={el.text + index}
        />
      ))}
    </div>
  );
};

const TableHeadItem = ({ item, onSortClick, sortType }) => {
  let Icon = IconSort;
  Icon = sortType === SORT_TYPES.ASC ? IconSortUp : Icon;
  Icon = sortType === SORT_TYPES.DESC ? IconSortDown : Icon;

  const style = {};
  item.colspan && (style.flex = item.colspan);
  item.sort && (style.cursor = 'pointer');
  item.sort && (style.userSelect = 'none');
  return (
    <span
      className="fs--1 font-weight-bold px-2"
      style={{ minWidth: 60, ...style }}
      onClick={() => item.sort && onSortClick(item)}
    >
      {item.text}
      {item.sort ? <Icon className="my-auto ml-1 text-grey" /> : null}
    </span>
  );
};

const TableBody = ({ data, columns, loading, onPaginate, totalPages, expandRow, page }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState();

  useEffect(() => {
    setExpandedRowIndex(undefined);
  }, [page]);

  const toggleExapndRow = i => (i === expandedRowIndex ? setExpandedRowIndex(undefined) : setExpandedRowIndex(i));

  return (
    <Card className="appt-tbl-body py-3" fetching={loading}>
      <div>
        {data.map((el, i) => (
          <TableRow
            isExpanded={expandedRowIndex === i}
            onExpand={() => toggleExapndRow(i)}
            columns={columns}
            record={el}
            expandRow={expandRow}
            key={el.text + '' + i}
          />
        ))}
      </div>
      <Pagination className="pt-4" onChange={onPaginate} page={page} totalPages={totalPages} />
    </Card>
  );
};

const TableRow = ({ columns, record, expandRow, isExpanded, onExpand }) => {
  const extendRef = useRef();
  const Child = useCallback(() => expandRow?.renderer(record), [record, expandRow]);

  return (
    <div className="tbl-row">
      <div className="d-flex plain-row px-5 position-relative">
        {columns.map((col, index) => {
          const text = record[col.dataField];
          const style = {};
          col.colspan && (style.flex = col.colspan);
          return (
            <TableCell text={text} className={col.className} style={style} key={col.dataField + index}>
              {col.formatter && col.formatter(text, record)}
            </TableCell>
          );
        })}
        {Child && expandRow ? (
          <button className="extend-btn position-absolute" onClick={onExpand}>
            <IconSortDown style={{ transform: `rotateZ(${isExpanded ? 0 : isAR ? 90 : -90}deg)` }} />
          </button>
        ) : null}
      </div>
      {Child && expandRow ? (
        <div
          className="extend-row"
          style={{ height: isExpanded ? extendRef?.current?.offsetHeight + 15 || 'auto' : 0 }}
        >
          <div className="extend-padding" />
          <div ref={extendRef} className="extend-row-container mx-5">
            <Child />
          </div>
        </div>
      ) : null}
      <div className="border-bottom mx-5" />
    </div>
  );
};

const TableCell = ({ text, children, className, style }) => (
  <span className={`tbl-cell px-2 fs--1 ${className || ''}`} style={style}>
    {children || text}
  </span>
);
