import React from 'react';
import { Card as NativeCard } from 'reactstrap';
import classNames from 'classnames';
import { lang } from '../../lang';

export const Card = ({ loading, children, className, fetching, style, mid, low }) => (
  <NativeCard
    className={classNames(className, {
      'overflow-hidden': loading || fetching,
    })}
    style={style}
  >
    <div className={`position-absolute w-100 line-loading ${fetching ? 'show' : ''}`} />
    {children}
    {loading ? (
      <div className="position-absolute card-loading d-flex justify-content-center align-items-center">
        <small>{lang.pleaseWait} ...</small>
      </div>
    ) : null}
  </NativeCard>
);
