import React from 'react';
import { Col, Row } from 'reactstrap';
import Checkbox from '../common/Checkbox';
import Dot from '../common/Dot';
import PropTypes from 'prop-types';
import { isAR, lang } from '../../lang';
import {
  AutoAcceptOffIcon,
  AutoAcceptOnIcon,
  NotificationIcon,
  ServiceActivatedIcon,
  ServiceDeactivatedIcon,
  StoreActivatedIcon,
  StoreDeactivatedIcon,
} from '../../utils';

const NotificationRow = ({ selected = false, handleRowSelection, notification = {}, range = 'date' }) => {
  let ar_time = Intl.DateTimeFormat('ar-AR', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    numberingSystem: 'arab',
  });
  let ar_day = Intl.DateTimeFormat('ar-AR', { weekday: 'long' });
  let ar_date = Intl.DateTimeFormat('ar-AR', { calendar: 'islamic', numberingSystem: 'arab' });

  let en_time = Intl.DateTimeFormat('en-En', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  let en_day = Intl.DateTimeFormat('en-En', { weekday: 'long' });
  let en_date = Intl.DateTimeFormat('en-EN');

  const dateFormatter = () => {
    switch (range) {
      case 'time':
        return isAR ? ar_time : en_time;
      case 'day':
        return isAR ? ar_day : en_day;
      default:
        return isAR ? ar_date : en_date;
    }
  };

  const icon = () => {
    switch (notification.data.type) {
      case 'SERVICE_ACTIVATED':
        return <ServiceActivatedIcon />;
      case 'SERVICE_DEACTIVATED':
        return <ServiceDeactivatedIcon />;
      case 'STORE_ACTIVATED':
        return <StoreActivatedIcon />;
      case 'STORE_DEACTIVATED':
        return <StoreDeactivatedIcon />;
      case 'AUTO_ACCEPT_ON':
        return <AutoAcceptOnIcon />;
      case 'AUTO_ACCEPT_OFF':
        return <AutoAcceptOffIcon />;
      default:
        return <NotificationIcon />;
    }
  };

  const toggle = () => {
    handleRowSelection(notification.id);
  };

  return (
    <Row className="justify-content-between align-items-center px-2">
      <Col md={3} lg={2} xl={1}>
        <div className="d-flex justify-content-around align-items-center px-2">
          <div className="mr-1">
            <Checkbox checked={selected} toggle={toggle} />
          </div>
          <div>
            <span
              className="dot bg-soft-secondary"
              style={{ height: 40, width: 40, backgroundColor: 25, display: 'inline-block' }}
            >
              <div className="align-self-center text-center">{icon()}</div>
            </span>
          </div>
        </div>
      </Col>

      <Col md={6} lg={8}>
        {notification.data.payload.causer_name} {lang.notifications[notification.data.type]}{' '}
        {notification.data.payload[isAR ? 'name_ar' : 'name_en']}
      </Col>
      <Col md={3} lg={2}>
        <Row className="justify-content-around">
          <div>{dateFormatter().format(new Date(notification.created_at))} </div>
          <div>{!notification.read_at ? <Dot color="danger" /> : null}</div>
        </Row>
      </Col>
    </Row>
  );
};

NotificationRow.propTypes = {
  selected: PropTypes.bool.isRequired,
  handleRowSelection: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};
export default NotificationRow;
