import React, { forwardRef } from "react";
import ReactSelect from "react-select";
import { theme as appTheme } from "../../constants";

export const Select = forwardRef(({ options, ...props }) => {
  const styles = {
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? appTheme.primaryColorAlpha(0.1) : null,
      color: isSelected ? appTheme.primaryColor : styles.color,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: appTheme.primaryColorAlpha(0.1),
    }),
  };

  const theme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: appTheme.primaryColorAlpha(0.8),
    },
  });

  return <ReactSelect styles={styles} theme={theme} options={options} {...props} />;
});
