import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import FalconCardHeader from "../../components/common/FalconCardHeader";
import { lang } from "../../lang";
import { request, APIs } from "../../request";
import { safeReq } from "../../utils/request";
import UserManual from '../../components/userManual/UserManual';




export default function UserManuals(){
    const [userManuals, setUserManuals] = useState([]);
  
    const fetchData = useCallback(
        () => 
            safeReq(async () => {
                const { data } = await request.GET(APIs.USER_MANUALS);
                const userManual = data;
                setUserManuals(userManual);
            }),[]
         );

        useEffect(() => {
            fetchData();
        }, [fetchData]);

    return(
   
      <Card>
          <FalconCardHeader title={ lang.userManual.title }/>
            <CardBody className="w-100" >
                <Container>
                    <Row xs={1} sm={1} md={1} >
                        { userManuals.map(um => {
                                return <Col xl={6}  lg={6}>
                                          <UserManual key={Math.random()} title= {um.basename} size= {um.size} date= {um.timestamp} fileName= {um.filename}/>
                                       </Col>
                            })
                        }
                    </Row>    
                </Container>
            </CardBody>
        </Card>
    )
};