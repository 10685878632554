import React, { useCallback, useContext } from 'react';
import useSWR from 'swr';
import { TotalAps } from '../../components/owner_dashboard/TotalAps';
import ResponseTimeGraph from '../../components/owner_dashboard/ResponseTimeGraph';
import { APIs, request } from '../../request';
// import { toast } from 'react-toastify';
// import { lang } from '../../lang';
import AppContext from '../../context/Context';
import MostBookedServiceGraph from '../../components/owner_dashboard/MostBookedServiceGraph';
import Flex from '../../components/common/Flex';
import BranchesActivity from '../../components/owner_dashboard/BranchesActivity';
import { REVALIDATE_TIME } from '../../constants';

const DashboardAlt = () => {
   const { stores } = useContext(AppContext);
  let loadingAppts = false;
  let loadingServices = false;

  const fetchAppts = useCallback(async URL => {
    try {
      const {
        data: { data: appts },
      } = await request.GET(URL, { per_page: 500 });
      return appts.data;
    } catch (e) {
      // toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const fetchServices = useCallback(async URL => {
    try {
      const {
        data: { data },
      } = await request.GET(URL);
      return data.data;
    } catch (e) {
      // toast.error(lang.defaultError);
      console.log({ e });
    }
  }, []);

  const { data: appts } = useSWR(APIs.APPOINTMENTS, fetchAppts, {
    refreshInterval: REVALIDATE_TIME,
  });
  const { data: services } = useSWR(APIs.SERVICES, fetchServices, {
    refreshInterval: REVALIDATE_TIME,
  });

   !appts ? (loadingAppts = true) : (loadingAppts = false);
   !services ? (loadingServices = true) : (loadingServices = false);

  return (
    <Flex className="h-100 flex-column owner-dashboard">
      <div style={{ maxHeight: '50%', overflow: 'hidden' }} className="h-50 d-flex space-hlf-pb">
        <Flex style={{ maxHeight: '100%' }} className="col-4 p-0 h-auto space-hlf-pr">
          <TotalAps loading={loadingAppts} appts={appts || []} />
        </Flex>
        <Flex style={{ maxHeight: '100%' }} className="col-8 p-0 space-hlf-pl">
          <BranchesActivity loading={loadingAppts} stores={stores} appts={appts || []} />
        </Flex>
      </div>
      <div style={{ maxHeight: '50%', overflow: 'hidden' }} className="d-flex h-50 space-hlf-pt">
        <div style={{ maxHeight: '100%' }} className="col-6 p-0 space-hlf-pr h-auto">
          <ResponseTimeGraph loading={loadingAppts} stores={stores} appts={appts || []} />
        </div>
        <div style={{ maxHeight: '100%' }} className="col-6 p-0 space-hlf-pl h-100">
          <MostBookedServiceGraph loading={loadingServices || loadingAppts} services={services || []} appts={appts || []} />
        </div>
      </div>
    </Flex>
  );
};

export default DashboardAlt;
