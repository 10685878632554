import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import TopNavLeftSideNavItem from './TopNavLeftSideNavItem';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu, isTopNav } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  return (
    <div className="navbar-top fs--1 font-weight-semi-bold mx-0 sticky-kit sticky-top space-px">
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav,
          'd-xl-none': !isTopNav,
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            !isTopNav ? setShowBurgerMenu(!showBurgerMenu) : setNavbarCollapsed(!navbarCollapsed);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <TopNavLeftSideNavItem />
      <TopNavRightSideNavItem />
    </div>
  );
};

export default NavbarTop;
