import React from "react";
import { Card, CardBody } from "reactstrap";

export default ({ children }) => {
  return (
    <Card className="mt-4 border">
      <CardBody className="py-4 px-4 justify-content-between align-items-center d-flex">{children}</CardBody>
    </Card>
  );
};
