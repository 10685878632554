import React, { Fragment, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Alert, Card, CardBody, Button } from 'reactstrap';
import Loader from '../../components/common/Loader';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useHistory } from 'react-router-dom';
import { isAR, lang } from '../../lang';
import AppContext from '../../context/Context';
import Confirm from '../../components/alert/confirm';
import Error from '../../components/alert/error';
import { request, APIs } from '../../request';
import { toast } from 'react-toastify';
import InvitationsTable from '../../components/invitations/InvitationsTable';
import { INVITATION_STATUS } from '../../constants';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { date } from '../../utils/date';

const Invitations = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [confrimPopup, setConfrimPopup] = useState(false);
  const [invAcceptedErrorPopup, setInvAcceptedErrorPopup] = useState(false);
  const [invRejectedErrorPopup, setInvRejectedErrorPopup] = useState(false);
  const [selectedInv, setSelectedInv] = useState();
  const [invitations, setInvitations] = useState([]);
  const { selectedStore } = useContext(AppContext);
  const [invitationsMeta, setInvitationsMeta] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Invitations',
    sheet: 'Invitations',
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { data },
    } = await request.GET(APIs.INVITATIONS, {
      id: selectedStore.id,
      page: page,
      pageSize: pageSize,
    });
    setInvitations(data?.data);
    setInvitationsMeta(data);
    setLoading(false);
  }, [page, pageSize]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const cancelInv = id => {
    setConfrimPopup(true);
    setSelectedInv(id);
  };

  const fetchCancelInv = async () => {
    try {
      const {
        data: { data },
      } = await request.POST(APIs.INVITATIONS_STATUS, {
        id: selectedInv,
        status: INVITATION_STATUS.CANCELED,
      });

      const updated = invitations.map(el => ({
        ...el,
        status: selectedInv === el.id ? data.status : el.status,
      }));
      setInvitations(updated);
      setConfrimPopup(false);
      data.status === INVITATION_STATUS.CANCELED && toast.success(lang.invitations.delete_success);
      data.status === INVITATION_STATUS.ACCEPTED && setInvAcceptedErrorPopup(true);
      data.status === INVITATION_STATUS.REJECTED && setInvRejectedErrorPopup(true);
    } catch (e) {
      setConfrimPopup(false);
    }
  };

  const cancelConfirmPopup = () => {
    setConfrimPopup(false);
  };

  const closeErrorPopup = useCallback(() => {
    setInvAcceptedErrorPopup(false);
    setInvRejectedErrorPopup(false);
  }, []);

  const addInvitee = useCallback(() => history.push('/invitations/add'), [history]);

  const checkStatus = status => {
    switch (status) {
      case INVITATION_STATUS.PENDING:
        return lang.invitations.status.pending;
      case INVITATION_STATUS.REJECTED:
        return lang.invitations.status.rejected;
      case INVITATION_STATUS.ACCEPTED:
        return lang.invitations.status.accepted;
      case INVITATION_STATUS.CANCELED:
        return lang.invitations.status.canceled;
    }
  };

  return (
    <Fragment>
      <table ref={tableRef} style={{ display: 'none' }}>
        <thead>
          <tr>
            <th>{lang.invitations.labels.subject}</th>
            <th>{lang.invitations.labels.name}</th>
            <th>{lang.invitations.labels.email}</th>
            <th>{lang.invitations.labels.mobile_phone}</th>
            <th>{lang.invitations.labels.service}</th>
            <th>{lang.invitations.labels.service}</th>
            <th>{lang.invitations.labels.date}</th>
            <th>{lang.invitations.labels.time}</th>
            <th>{lang.invitations.labels.status}</th>
          </tr>
        </thead>
        <tbody>
          {invitations?.map(invitation => {
            return (
              <tr>
                <td>{invitation?.subject}</td>
                <td>{invitation?.name}</td>
                <td>{invitation?.email}</td>
                <td>{invitation?.mobile}</td>
                <td>{isAR ? invitation.service.name.ar : invitation.service.name.en}</td>
                <td>{date.format(new Date(invitation?.datetime), 'dd/MM/yyyy')}</td>
                <td>{date.format(new Date(invitation?.datetime), 'hh:mm aa')}</td>
                <td>{checkStatus(invitation?.status)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Card>
        <FalconCardHeader title={lang.invitations.title}>
          <Button color="primary" className="mr-2" onClick={addInvitee}>
            {lang.invitations.addInvitation.title}
          </Button>
          <Button color="primary" className="" onClick={onDownload}>
            {lang.label.download}
          </Button>
        </FalconCardHeader>
        <CardBody className="fs--1">
          {loading ? (
            <Loader />
          ) : invitations.length ? (
            <InvitationsTable
              invitations={invitations}
              cancelInv={cancelInv}
              invitationsMeta={invitationsMeta}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          ) : (
            <Alert color="secondary" className="mb-0">
              {lang.invitations.noInvitationFound}
            </Alert>
          )}
          <Confirm
            open={confrimPopup}
            title={lang.invitations.confirmation_pop_up_title}
            body={lang.invitations.confirmation_pop_up}
            confirm={() => fetchCancelInv()}
            cancel={() => cancelConfirmPopup()}
          />
          <Error
            open={invAcceptedErrorPopup}
            title={lang.invitations.invitation_cancel_error_popup_title}
            body={lang.invitations.cancel_accepted_invitation_popup}
            onClick={closeErrorPopup}
          />
          <Error
            open={invRejectedErrorPopup}
            title={lang.invitations.invitation_cancel_error_popup_title}
            body={lang.invitations.cancel_rejected_invitation_popup}
            onClick={closeErrorPopup}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Invitations;
