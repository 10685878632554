import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRouter from './router/MainRouter';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.min.css';
import { lang } from './lang';

export const App = () => {
  return (
    <>
      <Helmet>
        <title>{lang.tabTitle}</title>
      </Helmet>
      <Router basename={process.env.PUBLIC_URL}>
        <MainRouter />
      </Router>
    </>
  );
};

export default App;
