export default [
  {
    id: 1,
    name: "Mohammed Al Dossari",
    mobile: "05577230926",
    email: "m.dossari@gmail.com",
    stores: [{ title: "Al Olaya Branch", id: 1 }, { title: "Exit 5 Branch", id: 2 }]
  },
  {
    id: 2,
    name: "Sami Al Molhem",
    mobile: "05585530976",
    email: "s.molhem@gmail.com",
    stores: [{ title: "Al Sahafa Branch", id: 3 }]
  },
  {
    id: 3,
    name: "Rayan Al Shasi",
    mobile: "0558230976",
    email: "r_shasi@outlook.com",
    stores: [{ title: "Exit 16 Branch", id: 4 }]
  }
];
