import Cookies from 'js-cookie';
export class AuthHelpers {
  static setToken(token) {
    localStorage.setItem('announcements-token', token);
  }

  static isAuthenticated() {
    if (Cookies.get('session') == null) {
      return false;
    } else {
      return true;
    }
  }

  static getToken() {
    return Cookies.get('session');
  }

  static removeToken() {
    Cookies.remove('session');
  }
  static logout() {
    AuthHelpers.removeToken();
    localStorage.removeItem('user');
    localStorage.removeItem('selectedStore');
    localStorage.removeItem('brand');
  }
}
