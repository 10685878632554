import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { lang } from "../../lang";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Alert = ({ open, title, onClick, body }) => (
  <Modal className="text-center" isOpen={open} centered={true}>
    <ModalHeader
      className={`d-flex justify-content-center align-items-center mb-0 mt-2 ${!body ? "border-0 pb-0" : "pb-2"}`}
    >
      <FontAwesomeIcon icon={faCheckCircle} color="green" size="sm" className="mx-2" />
      {title}
    </ModalHeader>
    {body ? <ModalBody>{body}</ModalBody> : null}
    <ModalFooter className={`justify-content-center  ${!body ? "border-0" : ""}`}>
      <Button color="primary" className={`px-3 py-1 ${!body ? "mt-3" : ""}`} onClick={onClick}>
        {lang.btn.ok}
      </Button>
    </ModalFooter>
  </Modal>
);

export default Alert;
