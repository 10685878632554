import { rgbaColor, themeColors } from '../../utils';
import { Circle } from 'react-es6-progressbar.js';
import React from 'react';
import PropTypes from 'prop-types';

export const VacanciesCircle = ({ used, total }) => {
  const res = used / total;
  const options = {
    color: res < 0.5 ? themeColors.success : res < 0.8 ? themeColors.warning : themeColors.danger,
    progress: res,
    strokeWidth: 10,
    trailWidth: 10,
    trailColor: rgbaColor('#000', 0.1),
    easing: 'easeInOut',
    duration: 500,
    svgStyle: {
      'stroke-linecap': 'round',
      display: 'block',
      width: '100%',
    },
    text: { autoStyleContainer: false },
  };

  return (
    <Circle
      progress={res}
      options={options}
      container_class="progress-circle progress-circle-dashboard"
      container_style={{ width: '30px', height: '30px' }}
    />
  );
};

VacanciesCircle.propTypes = {
  used: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
