import { FetchAccessTokenParams, Tokens } from '../types';

export const fetchAccessToken = async (params: FetchAccessTokenParams): Promise<Tokens> => {
  const body =
    params.type === 'fetch'
      ? {
          grant_type: 'authorization_code',
          client_id: process.env.REACT_APP_NAWART_SSO_CLIENT_ID as string,
          redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL as string,
          code: params.code as string,
          code_verifier: params.code_verifier as string,
          scope: '',
        }
      : {
          grant_type: 'refresh_token',
          refresh_token: params.refresh_token as string,
          client_id: process.env.REACT_APP_NAWART_SSO_CLIENT_ID as string,
          scope: '',
        };

  const response = await fetch(`${process.env.REACT_APP_START_BACKEND_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // @ts-ignore
    body: new URLSearchParams(body),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(`Failed to ${params.type} code for token: ${response.statusText}`);
  }

  return data;
};
