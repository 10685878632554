
import { Tokens } from "../types";
import { getSession } from "../session/session";

export const fetchCurrentEmployee = async (tokens?: Tokens) => {
  
  const accessToken = tokens?.access_token ?? (await getSession())?.access_token;

   const response = await fetch(`${process.env.REACT_APP_START_BACKEND_URL}/api/v1/tenant/current-employee`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return {
    ...(await response.json()),
  };
  
};
