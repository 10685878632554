import React, { useCallback } from "react";
import classNames from "classnames";

export const Toggler = ({ checked, onChange }) => {
  const onToggle = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);
  return (
    <button
      dir="ltr"
      className={classNames("toggle-container", {
        active: checked
      })}
      onClick={onToggle}
    >
      <div className="toggle" />
    </button>
  );
};
