import React, { useEffect } from "react";
import { IS_DEVELOP, ENDUSER_DEVELOP_LINK, ENDUSER_STAGING_LINK, IS_PRODUCTION } from "../../constants";

const IAM = ({ match }) => {
  let applink = IS_DEVELOP ? ENDUSER_DEVELOP_LINK : ENDUSER_STAGING_LINK;
  applink = applink.replace("PATH", `iam/${match.params.data}`);
  useEffect(() => {
    if (!IS_PRODUCTION) {
      try {
        window.location.replace(applink);
      } catch (err) {}
    }
  }, [applink]);

  return <div />;
};

export default IAM;
