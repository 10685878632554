import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { lang } from "../../lang";

export default function ConfirmationInput({ open, title, body, confirm, cancel }) {
  const [reason, setReason] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);

  let rejection = "";
  const rejectionInputHandler = (input) => {
    rejection = input.target.value;
    setDisabledButton(!rejection.trim());
    setReason(rejection);
  };

  return (
    <Modal className="text-center" isOpen={open} centered={true}>
      <div className="d-flex justify-content-center">
        <ModalBody>
          <div className="font-weight-bold m-2">{body}</div>
          <textarea
            onChange={rejectionInputHandler}
            rows="4"
            cols="40"
            Style="outline:none; border-color:#e7e7e7; border-radius:5px; font-size:0.8rem; line-height: 1.5;"
          />
        </ModalBody>
      </div>

      <div className="justify-content-center p-2 m-2">
        <Button disabled={disabledButton} color="primary" className="px-4 py-2 mx-2" onClick={() => confirm(reason)}>
          {lang.confirm}
        </Button>
        <Button className="px-4 py-2 mx-2" onClick={cancel}>
          {lang.cancel}
        </Button>
      </div>
    </Modal>
  );
}
