import React, { useCallback, useEffect, useState } from 'react';
import Picker from 'hijri-react-datepicker';
import { Input } from 'reactstrap';
import { isAR, lang } from '../../lang';
import { date as datehelper, IconCalendar } from '../../utils';
import PropTypes from 'prop-types';

const MultiInput = ({ startDate, endDate, ...props }) => (
  <button
    {...props}
    className="form-control position-relative text-left d-flex align-items-center overflow-hidden px-2"
  >
    <small className="text-muted">{lang.from}</small>
    <small className="f-1 text-center">{startDate ? datehelper.format(startDate, 'yyyy-M-dd') : ''}</small>
    <small className="text-muted">{lang.to}</small>
    <small className="f-1 text-center">{endDate ? datehelper.format(endDate, 'yyyy-M-dd') : ''}</small>
    <span className="date-icon text-muted">
      <IconCalendar />
    </span>
  </button>
);

export const DatePicker = ({
  type = 'text',
  defaultValue,
  control,
  showHijriFooter,
  defaultCalendar,
  onChange = () => {},
  onCalendarChange = () => {},
  selectsRange,
  calendar = CALENDAR_TYPES.GREGORIAN,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');

  const InputComp = useCallback(
    props => {
      return selectsRange ? <MultiInput {...props} /> : <Input {...props} />;
    },
    [selectsRange]
  );

  const onDateChange = useCallback(
    val => {
      setValue(val);
      onChange && onChange(val);
    },
    [onChange]
  );
  const onCalendarTypeChange = useCallback(
    val => {
      onCalendarChange(val);
    },
    [onCalendarChange]
  );

  const printGregorian = useCallback(value => {
    const date = value;
    return `${datehelper.format(date, 'yyyy-MM-dd')}`;
  }, []);

  useEffect(() => {
    let input_value;
    if (value && value[0]) {
      // Handling Ranged Date Picker
      const start = value[0] ? printGregorian(value[0]) : '';
      const end = value[1] ? printGregorian(value[1]) : '';
      input_value = `${start} - ${end}`;
    } else {
      input_value = value && printGregorian(value);
    }
    input_value && setInputValue(input_value);
  }, [value, printGregorian]);

  return (
    <Picker
      hijriButtonLabel={lang.showGregorian}
      gregorianButtonLabel={lang.showHijri}
      showHijriFooter={showHijriFooter}
      selected={value}
      onChange={onDateChange}
      onCalendarTypeChange={onCalendarTypeChange}
      calendar={calendar}
      customInput={<InputComp {...rest} style={{ paddingTop: 8 }} value={inputValue} type={type} />}
      readOnly={true}
      locale={isAR ? 'ar' : 'en'}
      selectsRange={selectsRange}
      {...rest}
    />
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  onCalendarChange: PropTypes.func,
};

export const CALENDAR_TYPES = { HIJRI: 'hijri', GREGORIAN: 'gregorian' };
