import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import StoreInformation from '../../components/stores/StoreInformation';
import ServiceForm from '../../components/services/Form';
import StoreLocation from '../../components/stores/StoreLocation';
import { useHistory } from 'react-router-dom';
import { lang } from '../../lang';
import { request, APIs } from '../../request';
import Alert from '../../components/alert/success';
import ErrorAlert from '../../components/alert/error';
import classNames from 'classnames';
import AppContext from '../../context/Context';

const DAFAULT_DAYS = [
  { label: lang.days.sun, value: '7' },
  { label: lang.days.mon, value: '1' },
  { label: lang.days.tue, value: '2' },
  { label: lang.days.wed, value: '3' },
  { label: lang.days.thu, value: '4' },
];

const AddBranch = () => {
  const history = useHistory();
  const { setStores, stores } = useContext(AppContext);
  const { handleSubmit, errors, watch, register, control, setValue } = useForm();
  const [showAlert, setShowAlert] = useState(false);
  const [showPackageError, setShowPackageError] = useState(false);
  const [step, setStep] = useState(1);
  const [fields, setField] = useState({ days: DAFAULT_DAYS });
  const [defaultServiceValues, setDefaultServiceValues] = useState({ duration: 15, capacity: 5 });
  const [withoutService, setWithoutService] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmitData = async form => {
    if (step === 1) {
      setField(form);

      setDefaultServiceValues({
        ...defaultServiceValues,
        service_name_en: form.name_en,
        service_name_ar: form.name,
      });
      setStep(step + 1);
    } else if (step === 2) {
      setStep(step + 1);
    } else if (step === 3) {
      setLoading(true);
      try {
        const add_store_body = {
          ar_name: fields.ar_name,
          en_name: fields.en_name,
          lat: fields.lat,
          lng: fields.lng,
          email: fields.email,
          mobile: fields.mobile,
          region: fields.region,
          city: fields.city,
          district: fields.district,
          is_active: 1,
        };
        const {
          data: { data },
        } = await request.POST(APIs.CREATE_STORE, add_store_body);

        const new_days = fields.days.map(day => day.value);

        const add_shift_body = {
          branch_id: data.id,
          name: 'Shift',
          start_datetime: fields.start.value,
          end_datetime: fields.end.value,
          days: new_days,
        };
        await request.POST(APIs.SHIFT, add_shift_body);
        setStores([...stores, data]);

        if (!withoutService) {
          const add_service_body = {
            branch_id: data.id,
            en_name: form.service_name_en,
            ar_name: form.service_name_ar,
            capacity: form.capacity,
            duration: form.duration.value,
            pre_msg: form.pre_msg,
            post_msg_ar: form.post_msg_ar,
            post_msg_en: form.post_msg_en,
          };
          !add_service_body.pre_msg && delete add_service_body.pre_msg;
          !add_service_body.post_msg_ar && delete add_service_body.post_msg_ar;
          !add_service_body.post_msg_en && delete add_service_body.post_msg_en;
          await request.POST(APIs.SERVICES, add_service_body);
        }
        setShowAlert(true);
        setLoading(false);
      } catch (err) {
        const errorData = err.response.data;
        if (errorData && errorData.message === 'REACHED_MAX_STORE') {
          setShowPackageError(true);
        }
      }
    }
  };

  const shiftDays = watch('days', DAFAULT_DAYS);
  useEffect(() => {
    if (Array.isArray(shiftDays) && !shiftDays.length) {
      setValue('days', null);
    }
  }, [shiftDays, setValue]);

  return (
    <Fragment>
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1,
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">1</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.stores.informations.title}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2,
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">2</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.stores.location.title}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 3,
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">3</span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">{lang.stores.addService}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          {step === 1 && (
            <StoreInformation errors={errors} watch={watch} data={fields} register={register} control={control} />
          )}
          {step === 2 && (
            <StoreLocation
              errors={errors}
              watch={watch}
              data={fields}
              setLatLng={latlng => setField({ ...fields, ...latlng })}
            />
          )}
          {step === 3 && (
            <ServiceForm
              register={register}
              errors={errors}
              watch={watch}
              data={defaultServiceValues}
              control={control}
            />
          )}
        </CardBody>
        <CardFooter className={'bg-light d-flex justify-content-end'}>
          {step === 3 ? (
            <Button
              color="gray"
              className="px-3 mr-5"
              type="submit"
              transform="down-1 shrink-4"
              onClick={() => setWithoutService(true)}
            >
              {lang.btn.skip}
            </Button>
          ) : null}
          <Button color="primary" className="px-5" type="submit" disabled={loading} transform="down-1 shrink-4">
            {step !== 3 ? lang.btn.next : lang.btn.submit}
          </Button>
        </CardFooter>
      </Card>
      <Alert
        open={showAlert}
        onClick={() => history.push('/stores')}
        title={lang.stores.addSuccess}
        body={withoutService ? `*${lang.stores.addedWithoutService}` : undefined}
      />
      <ErrorAlert
        open={showPackageError}
        onClick={() => history.push('/stores')}
        title={lang.sorry}
        body={`${lang.stores.packageLimitExceeded}`}
      />
    </Fragment>
  );
};

export default AddBranch;
