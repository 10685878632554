import { lang } from './lang';
import { ROLES } from './constants';
import {
  IconCalendar,
  IconCalendarCheck,
  IconCity,
  IconCog,
  IconDocument,
  IconEnvelopeOpen,
  IconHome,
  IconShapes,
  IconUserShield,
  IconUserTie,
  IconUsers,
  IconEmployees,
  IconStart,
} from './utils';

export const PAGE_TITLE_KEY = {
  DASHBOARD: 'dashboard',
  BOOKINGS: 'appointments',
  SCHEDULE: 'schedule',
  MODERATOR: 'moderatorManagments',
  STAFFS: 'staffManagment',
  GUARD: 'guardianManagment',
  STORES: 'storesManagment',
  SERVICES: 'services',
  CONFIGURATION: 'configuration',
  INVITATIONS: 'invitations',
  NOTIFICATIONS: 'notifications',
  BRANCH_INFORMATION: 'branch_information',
  VISITOR: 'visitor',
  START: 'start',
};

export const home = () => ({
  key: PAGE_TITLE_KEY.DASHBOARD,
  name: lang.navMenu.dashboard,
  to: '/dashboard',
  exact: false,
  Icon: IconHome,
});

export const booking = () => ({
  key: PAGE_TITLE_KEY.BOOKINGS,
  name: lang.navMenu.appointments,
  to: '/appointments',
  exact: true,
  Icon: IconCalendarCheck,
});

export const schedule = () => ({
  key: PAGE_TITLE_KEY.SCHEDULE,
  name: lang.navMenu.schedule,
  to: '/schedule',
  exact: true,
  Icon: IconCalendar,
});

const moderatorManagment = () => ({
  key: PAGE_TITLE_KEY.MODERATOR,
  to: '/moderators',
  name: lang.navMenu.moderatorManagments,
  Icon: IconUserTie,
  exact: true,
});

const staffManagment = () => ({
  key: PAGE_TITLE_KEY.STAFFS,
  to: '/staffs',
  name: lang.navMenu.staffManagments,
  Icon: IconUsers,
  exact: true,
});

const guardManagment = () => ({
  key: PAGE_TITLE_KEY.GUARD,
  to: '/guards',
  name: lang.navMenu.guardianManagment,
  exact: true,
  Icon: IconUserShield,
});

const storesManagment = () => ({
  key: PAGE_TITLE_KEY.STORES,
  name: lang.navMenu.storesManagment,
  to: '/stores',
  exact: true,
  Icon: IconCity,
});

const servicesManagment = () => ({
  key: PAGE_TITLE_KEY.SERVICES,
  to: '/services',
  name: lang.navMenu.services,
  Icon: IconShapes,
  exact: true,
});

const configuration = () => ({
  key: PAGE_TITLE_KEY.CONFIGURATION,
  to: '/configuration',
  name: lang.navMenu.configuration,
  Icon: IconCog,
  exact: true,
});

const invitationsManagment = () => ({
  to: '/invitations',
  name: lang.navMenu.invitations,
  Icon: IconEnvelopeOpen,
  exact: true,
});
const storedUser = localStorage.getItem('user');

const userObject = JSON.parse(storedUser);

const tenantId = userObject?.tenant?.id;

const visitorLink = () => ({
  key: PAGE_TITLE_KEY.VISITOR,
  name: lang.visitorsWebsite,
  to: `${process.env.REACT_APP_FRONTEND_URL}/ar/login?tenantId=${tenantId}`,
  isExternal: true,
  exact: true,
  Icon: IconEmployees,
});

const startLink = () => ({
  key: PAGE_TITLE_KEY.START,
  name: lang.employeesManagement,
  to: `${process.env.REACT_APP_START_FRONTEND_URL}`,
  isExternal: true,
  exact: true,
  Icon: IconStart,
});

const prepareAdminRoutes = () => [home(), storesManagment(), visitorLink(), startLink()];

const prepareManagerRoutes = () => [
  home(),
  booking(),
  schedule(),
  servicesManagment(),
  moderatorManagment(),
  staffManagment(),
  guardManagment(),
  invitationsManagment(),
  configuration(),
  visitorLink(),
  startLink(),
];

const prepareStaffRoutes = () => [home(), booking(), servicesManagment(), invitationsManagment(), visitorLink()];

const prepareGuardRoutes = () => [home(), booking(), visitorLink()];

export default (viewAs = ROLES.MANAGER) => {
  return viewAs === ROLES.ADMIN
    ? prepareAdminRoutes()
    : viewAs === ROLES.MANAGER
    ? prepareManagerRoutes()
    : viewAs === ROLES.STAFF
    ? prepareStaffRoutes()
    : prepareGuardRoutes();
};
