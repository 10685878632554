import React, { useEffect } from 'react';
import { isAR } from '../../lang';
import Header from './header';
import Footer from './footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const ar = () => {
    return (
      <>
        <Header />
        <div className="box mx-auto px-4 py-5 mb-5 mt-5">
          <h2 className="text-primary">السياسات والشروط</h2>
          <p>
            هذه الصفحة / هذا التطبيق هي/هو موقع/تطبيق " الموقع / موقعنا / التطبيق / تطبيقنا / مراجع" إلكتروني تعود
            ملكيته لشركة تمكين للتقنيات ("نحن") أو ("تمكين") ويدار من قبلها، ويستخدم للأغراض الإعلامية بهدف عرض خدمات
            المنتج والباقات المتاحة.
          </p>
          <h4 className="text-primary">سياسة الاستخدام:</h4>
          <p>
            يتم التسجيل في التطبيق/الموقع عن طريق ادخال البيانات المطلوبة ويتحمل كلاً من المستفيد والجهة المقدمة للخدمة
            أو أي أطراف أخرى تقوم باستخدام التطبيق/الموقع كامل المسؤولية عن عدم صحة أي مستندات أو بيانات أو معلومات تم
            ادخالها على موقع أو تطبيق مراجع، ولا تتحمل "تمكين" أدنى مسؤولية عن تلك البيانات المدخلة، ويحق لتمكين استخدام
            جميع الوسائل النظامية والقضائية تجاه جميع الأطراف المزودة للخدمة أو المستفيدة من الخدمة في أي وقت كان في حال
            تضررها من استخدام بيانات غير صحيحة أو معلومات أو مستندات مزورة أو غير صحيحة.
            <br />
            ويعد المستخدم مسؤولًا عن استخدام المناطق التفاعلية على التطبيق/ الموقع، وفي حال تم استخدام محتوى غير لائق أو
            غير قانوني أو مسيء للغير أو مضر به أو السعي للقيام بأي مما ذكر، فلنا حق اغلاق الحساب واتخاذ كافة الإجراءات
            النظامية بحق المخالف لهذه السياسة.
          </p>
          <h4 className="text-primary">التغييرات التي تطرأ على سياسة الخصوصية الخاصة بنا:</h4>
          <p>
            يوافق كلا من المستفيد والمستخدم لموقعنا أو لتطبيقنا على أي تغييرات قد ندخلها على سياسة الخصوصية الخاصة بنا
            في المستقبل، والتي سيتم نشرها على هذه الصفحة، وعند الاقتضاء، سيتم إخطاركم بها عن طريق البريد الإلكتروني.
          </p>
          <h4 className="text-primary">القوانين المطبقة والنزاعات:</h4>
          <p>
            إن جميع العلاقات الناشئة عن مراجع بين المستفيدين و/أو مزودي الخدمة أو أي أطراف أخرى تخضع للأنظمة السارية في
            المملكة العربية السعودية، وأي نزاع قد ينشأ عن هذه العلاقات فيتم حله بالطرق الودية، وإن تعذر ذلك فيحال الى
            الجهة القضائية المختصة في المملكة العربية السعودية ولا يجوز الاحتكام الى غيرها مالم يتم الاتفاق على خلاف
            ذلك.
          </p>
          <h4 className="text-primary">الاتصال:</h4>
          <p>
            نُرحب بكافة الأسئلة والتعليقات والطلبات بخصوص السياسات والشروط على العنوان التالي:{' '}
            <a href="mailto:Appointment@tamkeentech.sa">Appointment@tamkeentech.sa</a>
          </p>
          <h4 className="text-primary">سياسة الخصوصية:</h4>
          <p>
            عند استخدام خدمات تطبيق/موقع “مراجع” فإنك تأتمننا على معلوماتك لذا فإن المقصد من سياسة الخصوصية هو مساعدتك
            وحماية البيانات المتعلقة بك من خلال الامتثال لجميع الأنظمة واللوائح ذات الصلة.
          </p>

          <h3 className="text-primary">معلومات عامة</h3>
          <h4 className="text-primary">الرجاء قراءة سياسة الخصوصية بعناية قبل استخدام التطبيق</h4>
          <p>
            موقع وتطبيق مراجع تعود ملكيته لشركة تمكين للتقنيات ("نحن") ويدار من قبلها. نحن ملتزمون بحماية واحترام
            خصوصيتكم. هذه السياسة (سياسة الخصوصية الخاصة بـ " مراجع")، وغيرها من الوثائق المشار إليها في تلك الشروط تحدد
            الأساس الذي نقوم بناءً عليه بجمع أية معلومات شخصية منكم، أو توفرونها لنا، والتي ستتم معالجتها من قبلنا.
            فيرجى قراءة ما يلي بعناية لتفهم وجهات نظرنا والممارسات المتعلقة بمعلوماتكم الشخصية وكيفية التعامل معها. ومن
            خلال استخدام الموقع او التطبيق، فإنكم تتقبلون وتوافقون على الممارسات الوارد وصفها في هذه السياسة.
          </p>
          <h4 className="text-primary">المعلومات التي تقدمونها لنا:</h4>
          <p>
            قد تقدمون لنا معلومات أو وثائق ومستندات خاصة بكم أو لأطراف أخرى من خلال تعبئة النماذج المحددة من خلال الموقع
            أو التطبيق الخاص بنا أو الاتصال بنا عن طريق الهاتف أو البريد الإلكتروني أو غير ذلك من الطرق. وهذا يشمل
            المعلومات التي تقدمونها عند التسجيل لاستخدام التطبيق، أو عندما توجهون لنا رسالةً بالبريد الإلكتروني أو
            ترسلون لنا تقريرًا عن مشكلة تواجهكم في التعامل مع التطبيق/الموقع. فالمعلومات التي تقدمونها لنا قد تتضمن
            معلومات شخصية مثل الأسماء والعناوين وعنوان البريد الإلكتروني ورقم الهاتف والحصول على موقع المستفيد جغرافياً
            بدقة لاستخدامه بأغراض (التطبيق/ الموقع) وذلك من خلال الهاتف، والمعلومات المالية وبطاقات الائتمان. كما قد
            تتضمن تعليقات أو غيرها من المعلومات المنشورة من قبلكم في موقعنا او تطبيقنا التي تكون مصممة للتواصل العام أو
            التواصل فيما بين مستخدمين آخرين والتي يمكن الاطلاع عليها وتحميلها من قبل أطراف أخرى ممن يزورون موقعنا أو
            تطبيقنا ولديهم الصلاحيات للاطلاع على المعلومات التي تقدمونها لنا.
          </p>
          <h4 className="text-primary">المعلومات التي نجمعها عنكم:</h4>
          <div>
            <p>
              فيما يتعلق بكل زيارة من زياراتكم لموقعنا أو لتطبيقنا، قد نقوم بجمع المعلومات التالية عنكم بصورة تلقائية:
            </p>
            <ul>
              <li>
                المعلومات التقنية، بما في ذلك عنوان بروتوكول الانترنت (IP) المستخدم لربط جهاز الكمبيوتر الخاص بكم بشبكة
                الانترنت، ومعلومات تسجيل الدخول الخاصة بكم، ونوع المتصفح والإصدار، وتحديد المنطقة الزمنية، وأنواع
                الوظائف الإضافية وإصدارات المتصفح، ونظام ومنصة تشغيل الانترنت؛
              </li>
              <li>
                معلومات حول زيارتكم، بما في ذلك مجرى النقر الكامل لمعرفات الموارد المنتظمة (URL)، من خلال الموقع أو
                التطبيق (شاملا التاريخ والوقت)؛ المنتجات التي قمتم باستعراضها أو البحث عنها؛ وأوقات الاستجابة في صفحات
                البحث؛ وأخطاء التحميل؛ ومدة فترات الزيارة لصفحات معينة، ومعلومات التفاعل مع صفحات البحث (مثل التمرير
                والنقرات ومرات التخاطب باستخدام الفأرة)، والأساليب المستخدمة في التصفح بعيداً عن الصفحة وأي رقم هاتف
                مستخدم للاتصال برقم خدمة العملاء.
              </li>
            </ul>
          </div>
          <h4 className="text-primary">المعلومات التي نتلقاها من مصادر أخرى:</h4>
          <p>
            قد نتلقى معلومات عنكم إذا كنتم تستخدمون أيًا من المواقع الأخرى التي نقوم بتشغيلها أو الاستفادة من الخدمات
            الأخرى التي نقدمها. كما أننا نعمل بشكل وثيق مع أطراف ثالثة (بما في ذلك، على سبيل المثال، الشركاء التجاريين،
            والمقاولين من الباطن في مجال تقديم الخدمات الفنية والدفع والتسليم وشبكات الإعلان ومقدمي الدراسات التحليلية،
            ومقدمي معلومات البحث، والوكالات المرجعية الائتمانية) التي بإمكاننا الحصول منها على المعلومات التي تتعلق بكم.
          </p>

          <h3 className="text-primary">تفاصيل سياسة الخصوصية</h3>
          <h4 className="text-primary">ملفات تعريف الارتباط (Cookies)</h4>
          <p>
            يستخدم موقعنا / تطبيقنا " الصفحة التعريفية للمنتج" ملفات تعريف الارتباط (Cookies) لتمييزكم عن المستخدمين
            الآخرين لموقعنا او لتطبيقنا. وهذا يساعدنا على تزويدكم بتجربة جيدة عند قيامكم بتصفح موقعنا أو تطبيقنا ويسمح
            لنا في الوقت ذاته بتحسين موقعنا او تطبيقنا. ومن خلال الاستمرار في تصفح موقعنا أو تطبيقنا، فإنكم توافقون على
            قيامنا باستخدام ملفات تعريف الارتباط (Cookies) في التعامل معكم. ملف تعريف الارتباط هو عبارة عن ملف صغير
            يتألف من حروف وأرقام نقوم بتخزينها في المتصفح الخاص بكم أو على القرص الصلب لجهاز الكمبيوتر الخاص بكم إذا
            كنتم توافقون على ذلك. تحتوي ملفات تعريف الارتباط (Cookies) على المعلومات التي يتم نقلها إلى القرص الصلب
            لجهاز الكمبيوتر الخاص بكم.
          </p>
          <h4 className="text-primary">نحن نستخدم ملفات تعريف الارتباط (Cookies) التالية:</h4>
          <div>
            <ul>
              <li>
                ملفات تعريف الارتباط (Cookies) التي يجب استخدامها في حالات الضرورة القصوى: هذه هي الملفات المطلوبة
                لتشغيل موقعنا أو تطبيقنا. وهي تشمل، على سبيل المثال، ملفات تعريف الارتباط (Cookies) التي تمكّنكم من
                تسجيل الدخول إلى المناطق الآمنة من الموقع أو التطبيق والاستفادة من خدمات الدفع عبر الانترنت.
              </li>
              <li>
                ملفات تعريف الارتباط (Cookies) التي تستخدم للأغراض التحليلية / لأغراض الأداء. فهي تسمح لنا بالتعرف وحساب
                عدد الزوّار والوقوف على الكيفية التي يتحرك بها الزوّار داخل موقعنا أو تطبيقنا أثناء استخدامه. وهذا
                يساعدنا على تحسين الطريقة التي يعمل بها موقعنا او تطبيقنا، وذلك على سبيل المثال، من خلال ضمان أن
                المستخدمين يعثرون على ما يبحثون عنه بسهولة ويسر.
              </li>
              <li>
                ملفات تعريف الارتباط (Cookies) التي تستخدم للأغراض الوظيفية. تستخدم هذه الملفات للتعرف عليكم عند العودة
                إلى موقعنا او تطبيقنا. وهذا يمكننا من تخصيص المحتوى الذي نقدمه لكم، ومن تقديم التحية لكم بالاسم وتذكّر
                نواحي التعامل التي تفضلونها (على سبيل المثال، اللغة أو الإقليم المختار).
              </li>
              <li>
                ملفات تعريف الارتباط (Cookies) التي تستخدم لأغراض الاستهداف: تستخدم هذه الملفات في تسجيل وقائع زيارتكم
                لموقعنا أو لتطبيقنا، والصفحات التي قمتم بتصفحها والروابط التي قمتم باتباعها. وسوف نستخدم هذه المعلومات
                لجعل موقعنا أو تطبيقنا والإعلانات المعروضة عليه أكثر ملاءمة وخدمة لمصالحكم. كما يجوز لنا إطلاع أطراف
                ثالثة على هذه المعلومات لهذا الغرض.{' '}
              </li>
            </ul>
            <p>
              يرجى ملاحظة أن الأطراف الثالثة (بما في ذلك، على سبيل المثال، شبكات الإعلان ومقدمي الخدمات الخارجية مثل
              خدمات تحليل حركة المرور على الشبكة) يمكنها أيضا استخدام ملفات تعريف الارتباط (Cookies)، والتي لا يكون
              لدينا أي سيطرة عليها. ومن المرجح أن تكون ملفات تعريف الارتباط (Cookies) التي تستخدمها هذه الأطراف هي من
              النوع الذي يستخدم للأغراض التحليلية أو لأغراض الأداء أو لأغراض الاستهداف كما هو موضح أعلاه.
              <br />
              يمكنكم منع استخدام ملفات تعريف الارتباط (Cookies) الخاصة بكم من خلال تفعيل الإعدادات على المتصفح الخاص بكم
              الذي يسمح لكم برفض أو تحديد كامل أو بعض ملفات تعريف الارتباط. ولكن يُراعى أنه في حال استخدامكم إعدادات
              المتصفح لمنع استخدام جميع ملفات تعريف الارتباط (بما في ذلك الملفات الأساسية منها) فقد لا يكون بإمكانكم
              الوصول إلى جميع أو بعض أجزاء موقعنا أو تطبيقنا. باستثناء ملفات تعريف الارتباط الأساسية، فإن جميع ملفات
              تعريف الارتباط ينتهي مفعولها بعد انقضاء ساعة واحدة.
            </p>
          </div>
          <h4 className="text-primary">القوة القاهرة:</h4>
          <p>
            شركة تمكين غير مسئولة أمام المستفيد أو المستخدم في حالة الفشل في تنفيذ أي التزام بموجب أي اتفاق الذي يكون
            حدث بأسباب خارجه عن نطاق سيطرتها (القوة القاهرة) مثل الإرهاب، حروب التمرد السياسي، والعصيان والشغب
            والاضطرابات المدنية، أعمال السلطة المدنية أو العسكرية، والانتفاضة، والزلازل، والفيضانات أو الحوادث الطبيعية
            الأخرى لاحتمالات خارج السيطرة، التي تتسبب في عدم مقدرة الشركة الالتزام بهذه الشروط والأحكام، وسوف تعمل
            "تمكين" على القيام بكافة الجهود والإجراءات المعقولة للامتثال لهذه الأحكام والشروط.
          </p>

          <h3 className="text-primary">الاستخدامات التطبيقية للمعلومات</h3>
          <h4 className="text-primary">نحن نستخدم المعلومات التي نحتفظ بها عنكم بالطرق التالية:</h4>
          <h4 className="text-primary">المعلومات التي تقدمونها لنا أو التي نجمعها عنكم، ستستخدم على النحو التالي:</h4>
          <div>
            <ul>
              <li>
                لتمكيننا، وتمكين شركائنا والموردين من طرف ثالث من تقديم وإدارة وتحسين ورفع مستوى أداء الخدمة، سواء على
                المستوى الفردي بشكل إجمالي، أو من خلال إضفاء الصفة الفردية على تجربتهم وتقييم إمكانيات الدخول واستخدام
                موقعنا أو تطبيقنا وأثر ذلك في المملكة العربية السعودية.
              </li>
              <li>لأغراض البحث والتطوير.</li>
              <li>
                مراقبة وكشف مخالفات (الشروط والأحكام الخاصة بـ مراجع) وكذلك حالات سوء الاستخدام المحتملة الأخرى لموقعنا
                او لتطبيقنا.
              </li>
              <li>
                نشر المعلومات، غير المعلومات الشخصية، التي تُجمع حول إمكانية الوصول والاستخدام، وأثر ذلك على أداء الجهات
                والأفراد في المملكة العربية السعودية.
              </li>
              <li>
                تزويدكم بآخر المستجدات حول الخدمات والمنتجات أو غيرها من الأحداث التي نقدمها نحن أو شركاؤنا أو الموردون
                من طرف ثالث التي قد تكون ذات فائدة لكم، والتي يمكن أن ترسل لكم عن طريق رسائل البريد الإلكتروني أو الهاتف
                المحمول.
              </li>
              <li>أرشفة هذه المعلومات و/أو استخدامها لإمكانية التواصل المستقبلي معكم.</li>
              <li>صيانة ورفع مستوى أداء وأمن موقعنا و تطبيقنا وبرامجنا والنظم والشبكات الخاصة بنا.</li>
              <li>
                للأغراض الوارد وصفها في موضع آخر من سياسة الخصوصية (بما في ذلك، على سبيل المثال، تبادل المعلومات مع
                أطراف ثالثة).
              </li>
              <li>بأية طريقة جرى وصفها بخلاف ذلك في مرحلة جمع المعلومات أو بناء على موافقتكم.</li>
              <li>لمصادقة هويتكم عند التسجيل في بعض الخدمات المقدمّة في موقعنا او تطبيقنا.</li>
              <li>
                لإدارة موقعنا وتطبيقنا وتنفيذ العمليات الداخلية، بما في ذلك اكتشاف الأعطال وتحليل البيانات، والاختبار
                والبحث والأغراض الإحصائية وأغراض الدراسات المسحية الشاملة.
              </li>
              <li>
                لتحسين مستوى خدماتنا لضمان عرض المحتوى بطريقة أكثر فعالية بالنسبة لكم وبالنسبة لجهاز الكمبيوتر الخاص
                بكم.
              </li>
              <li>
                للسماح لكم بالمشاركة في الميزات التفاعلية للخدمة التي نقدمها، في حال رغبتكم في ذلك. كجزء من جهودنا
                الرامية إلى الحفاظ على أمن وسلامة موقعنا وتطبيقنا.
              </li>
              <li>كجزء من سعينا لتحقيق سلامة وأمن موقعنا وتطبيقنا.</li>
              <li>لقياس أو فهم فعالية الإعلانات التي نقدمها لكم ولغيركم، وتقديم الإعلانات ذات الصلة بكم.</li>
              <li>
                لتقديم الاقتراحات والتوصيات لكم ولغيركم من مستخدمي موقعنا وتطبيقنا حول الخدمات التي قد تهمكم أو تعنيهم.
              </li>
            </ul>
          </div>
          <h4 className="text-primary">المعلومات التي نتلقاها من مصادر أخرى:</h4>
          <p>
            يجوز لنا الجمع بين هذه المعلومات وبين المعلومات التي تقدمونها لنا والمعلومات التي نجمعها عنكم. كما يجوز لنا
            استخدام هذه المعلومات والمعلومات التي جرى جمعها للأغراض المبيّنة أعلاه (اعتمادًا على أنواع المعلومات التي
            نتلقاها).
          </p>
          <h4 className="text-primary">الإفصاح عن المعلومات الخاصة بكم:</h4>
          <div>
            <p>
              قد نقوم بتبادل أي معلومات تقدمونها لنا أو نقوم بجمعها عنكم مع شركائنا والموردين من طرف ثالث، والجهات
              المختصة بإجراء التحليلات ومقدمي خدمات محرك البحث والمعلنين. وسنقوم بتبادل هذه المعلومات مع أطراف ثالثة على
              النحو التالي:
            </p>
            <ul>
              <li>
                إذا كان يتعين علينا الإفصاح أو تبادل المعلومات الشخصية الخاصة بكم في إطار الوفاء بأي التزام قانوني، أو
                من أجل تنفيذ أو تطبيق (الشروط والأحكام الخاصة بخدمات مراجع) وتنفيذ الاتفاقيات الأخرى؛ أو لحماية الحقوق،
                المِلكيّة، أو المحافظة على سلامة شركة تمكين للتقنيات، وسلامة عملائنا، أو غيرهم من الأطراف.
              </li>
              <li>
                مع مقدمي الخدمات أو الوكلاء الذين يؤدون وظائف معينة بالنيابة عنّا أو عن شركائنا أو بالنيابة عن البائعين،
                بما في ذلك معالجة المعلومات التي تقدمونها على موقعنا أو تطبيقنا، وتنفيذ المشتريات عن طريق طرف ثالث،
                وغيرها من العمليات التي تنفذ من خلال موقعنا أو تطبيقنا، أو التي تتعلق بتشغيل موقعنا وتطبيقنا أو أية
                أجزاء منه أو فيما يتعلق بأية جوانب أخرى من الخدمات التي نقدمها.
              </li>
              <li>
                لأغراض البحث والتطوير. ومع ذلك، فإننا سنقوم بتبادل المعلومات الشخصية الخاصة بكم لهذا الغرض بما لا يتعارض
                مع أحكام القوانين والأنظمة المرعيّة وفي حدود المعلومات الشخصية المطلوبة لتحقيق الأغراض المبيّنة في وقت
                جمعها.
              </li>
              <li>
                لتوفير فرص التواصل بينكم وبين المستخدمين الآخرين الذين قد يكون لهم مصالح أو أهداف مماثلة. على سبيل
                المثال، قد نوصي بروّاد أعمال محددين أو نربط بين مانحي الوكالات والوكلاء المحتملين. وفي مثل هذه الحالات،
                فإنه يجوز لنا أن نستخدم جميع المعلومات التي تم جمعها عنكم لتحديد الجهات التي قد تكون مهتمة في التواصل
                معكم.
              </li>
              <li>
                للرد على مذكرات الإحضار، أو أوامر المحكمة أو الإجراءات القانونية الأخرى. وللتحقيق، أو منع أو اتخاذ
                اللازم تجاه إجراءات تتعلق بأنشطة غير مشروعة، أو أنشطة الاحتيال المشتبه بها، أو القضايا الأمنية أو
                الفنية، أو لتنفيذ الشروط والأحكام الخاصة بنا أو سياسة الخصوصية، وكل ما يكون مطلوبًا بخلاف ذلك بموجب
                أحكام القوانين والأنظمة المرعيّة أو لحماية حقوقنا وممتلكاتنا، أو المحافظة على سلامة ومصالح الآخرين.
              </li>
              <li>
                مع الشركات التابعة لنا، أو مع خلفائهم في حالة الاندماج، أو الاستحواذ أو إعادة التنظيم، لاستخدامها بما
                يتفق مع سياسة الخصوصية.
              </li>
              <li>حسب الوصف الذي يقدم لكم بخلاف ذلك في نقاط الجمع أو بناء على موافقتكم.</li>
              <li>
                من أجل التكامل مع خدمات أطراف ثالثة. على سبيل المثال، إمكانية استضافة محتوى الفيديو وغيرها من المحتويات
                على موقع يوتيوب وغيرها من المواقع التي لا تخضع لسيطرتنا.
              </li>
              <li>
                تبادل المعلومات المجمّعة التي لا تُعرِّفكم شخصيا على الجمهور وعلى أطراف ثالثة، بما في ذلك على سبيل
                المثال لا الحصر، الباحثين والشركاء التجاريين.
              </li>
            </ul>
          </div>
          <h4 className="text-primary">الموافقة على معالجة البيانات خارج البلاد:</h4>
          <p>
            باستخدامكم لموقعنا / لتطبيقنا الخاص بمراجع، أو تقديم معلومات لنا، فإنكم تدركون وتوافقون بشكل لا لبس فيه على
            جمع واستخدام والإفصاح وحفظ المعلومات في المملكة العربية السعودية وبلدان ومناطق أخرى، للأغراض المنصوص عليها
            في سياسة الخصوصية وفقاً للشروط والأحكام. وبهذا فإنكم توافقون على جمع واستخدام والإفصاح والاحتفاظ من قبلنا
            بالمعلومات الشخصية الخاصة بكم كما هو موضح في سياسة الخصوصية، بما في ذلك دون حصر، تبادل المعلومات الشخصية
            الخاصة بكم فيما بيننا وبين أطراف ثالثة، ومع الشركات المنتسبة، والشركات التابعة لنا الوارد وصفها في سياسة
            الخصوصية. وتلافيًا للشك، فإن أي موافقة تتعلق بالحق في تبادل المعلومات المشار إليها في هذه الفقرة تشمل
            موافقتكم على تبادل المعلومات الشخصية الخاصة بكم مع أي ولاية قضائية قد توفر مستوى مختلفًا من حماية الخصوصية
            عن المستوى المتوفر في بلدكم.
            <br />
            هذا وسنتخذ جميع الخطوات اللازمة بحدود المعقول لضمان التعامل مع المعلومات الخاصة بكم بشكل آمن ووفقاً لسياسة
            الخصوصية. جميع المعلومات التي تقدمونها لنا يتم تخزينها في ذاكرة حاسبات خدمة آمنة خاصة بنا. وأي عمليات دفع
            سيتم تشفيرها باستخدام تقنية SSL. وفي حال قيامنا بتزويدكم (أو في حال اختياركم) كلمة سر لتمكينكم من الدخول على
            أجزاء معينة من موقعنا أو تطبيقنا، فستقع على عاتقكم مسؤولية المحافظة على سرية هذه الكلمة. ونحن ننصح بعدم
            إطلاع أي شخص على كلمة السر الخاصة بكم.
            <br />
            ومما يؤسف له أن عملية نقل المعلومات عبر شبكة الإنترنت ليست آمنة تمامًا. وعلى الرغم من أننا سنبذل قصارى جهدنا
            لحماية المعلومات الشخصية الخاصة بكم، فإننا لا نستطيع ضمان أمن المعلومات الخاصة بكم التي سيتم نقلها إلى
            موقعنا أو تطبيقنا. وعليه، يراعى أن أي عملية نقل من هذا القبيل ستكون على مسؤوليتكم الخاصة. ومن ناحيتنا، فإنه
            بمجرد تلقينا المعلومات الخاصة بكم، سنطبّق إجراءات صارمة وميزات الأمان في محاولة لمنع الوصول غير المصرح به
            إلى تلك المعلومات.
            <br />
            فإذا كنتم لا توافقون على هذه الشروط، فالرجاء عدم الدخول، أو تصفح، أو التسجيل للاشتراك في مراجع. وإذا اخترتم
            عدم تزويدنا بمعلومات معينة تكون مطلوبة لتزويدكم بمختلف الخدمات المقدمة على موقعنا و تطبيقنا، فسوف لن يكون
            بإمكانكم إنشاء حساب مستخدم خاص، وبالتالي فسوف لن نكون قادرين على تزويدكم بتلك الخدمات.
          </p>

          <h4 className="text-primary">حقوقكم:</h4>
          <p>
            إن لكم الحق في أن تطلبوا منّا عدم استخدام المعلومات الشخصية الخاصة بكم لأغراض التسويق. ونحن في العادة
            سنبلغكم (قبل المباشرة بجمع المعلومات الخاصة بكم) إذا كنا ننوي استخدام المعلومات الخاصة بكم لمثل هذه الأغراض
            أو إذا كنا نعتزم الإفصاح عن المعلومات الخاصة بكم إلى أي طرف ثالث لهذه الأغراض. يمكنكم ممارسة حقّكم في منع
            معالجة تلك المعلومات عن طريق التأشير في المربعات المخصصة لذلك على النماذج التي نستخدمها لجمع المعلومات
            الخاصة بكم. ويمكنكم أيضا ممارسة هذا الحق في أي وقت عن طريق الاتصال بنا على العنوان التالي:
            <br />
            <a href="mailto:support_Appointment@tamkeentech.sa">support_Appointment@tamkeentech.sa</a>
            <br />
            ربما يتضمّن موقعنا / تطبيقنا من وقت لآخر، روابط للاتصال من وإلى مواقع شبكات أو معلنين أو شركات تابعة لشريك
            من شركائنا. فإذا كنتم ترغبون في تتبع أي رابط للدخول على أي موقع من هذه المواقع، يرجى ملاحظة أن هذه المواقع
            لديها سياسات الخصوصية الخاصة بها، وأننا لا نتحمل أي مسؤولية أو تبعة فيما يتعلق بتلك السياسات. وعليه، يرجى
            الاطلاع على تلك السياسات قبل تقديم أي معلومات شخصية لتلك المواقع.
          </p>
        </div>
        <Footer />
      </>
    );
  };

  const en = () => {
    return (
      <>
        <Header />
        <div className="box mx-auto px-4 py-5 mb-5 mt-5">
          <h2 className="text-primary">Policy and Terms of Use:</h2>
          <p>
            This page/app is an electronic website / application (hereinafter referred to as “the website / our website
            / the app / our app / the timing system”) owned by Tamkeen Technologies Company (hereinafter referred to as
            “we” or “Tamkeen”), and operated thereby. It is used for media purposes to offer available product services
            and packages.
          </p>
          <h4 className="text-primary">Usage PolicyUsage Policy</h4>
          <p>
            Logging in our application / website shall be through entering the required data. Both the beneficiary and
            the service provider, or any third parties using the application / website, shall bear full responsibility
            on any invalid documents, data, or information entered into the timing system website or application.
            Tamkeen refrains from bearing any responsibility on such invalid entered data. Should Tamkeen be harmed from
            using invalid data, information, or forged or falsified documents, it is entitled, at all times, to use all
            statutory and judicial means towards all service providers and beneficiaries.
            <br />
            Users shall bear responsibility for using interactive areas on the website / application. In case of using
            inappropriate, unlawful, or otherwise harmful content or seeking to do any of the above mentioned, we have
            the right to close the account and take all legal measures against the policy violator.
          </p>
          <h4 className="text-primary">Changes to Our Privacy and Policy: </h4>
          <p>
            Both beneficiaries and users of our website / application undertake to accept any future changes or updates
            to our Privacy Policy, as posted on this page, and if applicable, we will notify you by mail.
          </p>
          <h4 className="text-primary">Disputes and Applicable Laws:</h4>
          <p>
            Any relations arising out of the timing system, among beneficiaries, service providers and/or third parties;
            shall be subject to the applicable laws and regulations of the Kingdom of Saudi Arabia. Any dispute arises
            from such relations shall be settled amicably. If it is not possible, such dispute shall be referred to the
            contempt judicial authority in the Kingdom of Saudi Arabia, unless otherwise being agreed.{' '}
          </p>
          <h4 className="text-primary">Contact:</h4>
          <p>
            We appreciate all questions, comments and requests with regard to our Privacy Policy sent at:{' '}
            <a href="mailto:appointment@tamkeentech.sa">appointment@tamkeentech.sa</a>
          </p>
          <h4 className="text-primary">Privacy Policy</h4>
          <p>
            When using “Timing System” application services, you trust us with your data. Thus, the purpose of Privacy
            Policy is to help you protect your personal data through compliance with all relevant laws and regulations.
          </p>

          <h3 className="text-primary">General Information</h3>
          <h4 className="text-primary">Please, read the Privacy Policy carefully before using the website:</h4>
          <p>
            The platform is a website created and run by Tamkeen Technologies Company, (hereinafter referred to as “We”
            or “Tamkeen”). We are committed to protect and respect your privacy. This policy (Timing System Privacy
            Policy) and other documents referred to in these terms determine the basis upon which personal data is
            collected from users, or provided by them to be processed by us. Please, read the following terms carefully
            to understand our points of view, practices concerning your personal data, and how to deal with it. By
            visiting our website, you acknowledge and accept the practices mentioned in this policy.
          </p>
          <h4 className="text-primary">Data You Provide to Us:</h4>
          <p>
            You need to provide us with your private data through filling specified forms on our website, contacting us
            via phone, email, or any other mean of communication. This shall include the data provided when you sign in
            to use our website, email us, or send a report on the problems you faced in using our website. The data you
            provide may include personal data, such as: your name, address, email, phone number, financial data and
            credit card number, personality description, and a photograph. The data may also include comments or any
            other data posted by you on the website, which is designed for public communication or communication among
            other users. Such data shall be available to the other authorized website users to access and download.
          </p>
          <h4 className="text-primary">Data Collected about You:</h4>
          <div>
            <p>With every visit of yours to our website, we may automatically collect the following data about you:</p>
            <ul>
              <li>
                Technological data, including your Internet Protocol address (IP address) used for connecting your
                computers to the internet, signing in data, browser type and version, time zone location, types of
                additional functions and browser versions, and internet operating system and platform;
              </li>
              <li>
                Data about your visit, including the Uniform Resource Locator (URL), through our website (including date
                and time), products you review or search for, response times in search pages, download errors, session
                durations for particular pages, interaction data in search pages (such as, scrolling, clicking, and
                conversational times by using the mouse), methods used in browsing off-page, and any phone number used
                to call customer service.
              </li>
            </ul>
          </div>
          <h4 className="text-primary">Data Received from Other Sources:</h4>
          <p>
            We may receive data about you when you use the other websites we operate, or utilize other services we
            provide. We also work closely with third parties (including, for example, business partners and
            sub-contractors in the field of providing technical services, payment and delivery services, advertising
            networks, analytical study providers, research data providers, and credit reference agencies) from whom the
            website may receive data about you.
          </p>

          <h3 className="text-primary">Privacy Policy Details</h3>
          <h4 className="text-primary">Cookies:</h4>
          <p>
            Our website uses cookies to distinguish you from other website users. This may help us provide you with a
            good experience while browsing our website along with improving our website. By continuing to browse our
            website, you agree on our use of cookies. A cookie is a small file consisted of letters and numbers stored
            by the website on your computer web browser or on your computer’s hard disk drive upon your consent to do
            so. Cookies contain the data transferred to your computer's hard drive.
          </p>
          <h4 className="text-primary">We Use the Following Types of Cookies:</h4>
          <div>
            <ul>
              <li>
                Essential Cookies: Files required for operating our website. They include, but are not limited to,
                cookies that allow you to log into secure areas of our website, attend a training course, obtain a
                certificate or make use of e-payment services.
              </li>
              <li>
                Analytical/Performance Cookies: Files that allow us to recognize our website visitors, count their
                numbers, and identify the mechanism used by visitors to move on our website within the course of usage.
                This improves our website performance by ensuring that visitors can find what they are searching for
                easily.
              </li>
              <li>
                Functionality Cookies: Files that allow us to recognize visitors when they return to the website. They
                help us to personalize content, greet you by name and remember your preferences (such as selected
                language or country).
              </li>
              <li>
                Targeting Advertisement Cookies: These cookies record your visit to the website, pages you have visited
                and links you have followed. We will use this data to target data and advertisements displayed on our
                website more convenient and relevant to your interests. We may share this data with third parties for
                this purpose.
              </li>
            </ul>
            <p>
              Please, note that third parties (including, for example, advertising networks and external service
              providers (ESP), such as online traffic analysis services) can also use cookies, which would be out of our
              control. Cookies used by these parties are likely of the type used for analytical/performance or targeting
              purposes as described above.
              <br />
              You can adjust your browser settings to disable or select all or some browser cookies. Please, note that
              if you adjust your browser settings to disable all cookies (including essential cookies), this may prevent
              you from accessing all or some of our website’s areas. Except essential cookies, all other cookies expire
              after one hour.
            </p>
          </div>
          <h4 className="text-primary">Force Majeure:</h4>
          <p>
            The Company shall not be liable to the client in case of failure to perform any obligation under any
            agreement, when an event beyond the Company’s control occurs. Force majeure includes terrorism, political
            rebellion wars, disobediences, riots, civil unrest, acts of civil or military authority, uprisings,
            earthquakes, floods, or other out of control natural events, which cause the Company’s inability to comply
            with these terms and conditions. The Company will endeavor to make all reasonable efforts and procedures to
            comply with these terms and conditions.{' '}
          </p>

          <h3 className="text-primary">Practical Use of Data:</h3>
          <h4 className="text-primary">We use the data collected about you in the following:</h4>
          <h4 className="text-primary">We use the data provided by or collected about visitors for the following:</h4>
          <div>
            <ul>
              <li>
                Enable us, our partners and third party suppliers to provide, manage, improve and upgrade the service
                performance, both individually in aggregate, or by individualizing users' experience, and assess the
                accessibility and use of our website, as well as the impact of that upon our provided services
                development.
              </li>
              <li>Research and development purposes.</li>
              <li>
                Monitor and detect violations to (the Timing System’s terms and conditions) as well as other potential
                misuse of our website.
              </li>
              <li>
                Data dissemination, non-personal data collected about accessibility and usage, and its impact on the
                performance of small and medium-sized enterprises (SMEs).
              </li>
              <li>
                Provide visitors with the latest updates about products, services or other events supplied by us, our
                partners, or third party suppliers. Such updates may be useful to visitors and can be sent to them via
                email or mobile phone.{' '}
              </li>
              <li>Archive data and/or use for the upcoming communicate with visitors.</li>
              <li>
                Maintain and improve performance and security levels of our website, programs, systems, and networks.{' '}
              </li>
              <li>
                For purposes described formerly in the Privacy Policy (including, for example information exchange with
                third parties).{' '}
              </li>
              <li>In any manner described otherwise at the information gathering stage or upon your consent.</li>
              <li>Verify visitors identities upon signing up for some of the services offered on our site.</li>
              <li>
                Manage our site and perform internal processes, including troubleshooting, statistical and comprehensive
                survey purposes.{' '}
              </li>
              <li>
                Improve our site level to ensure that the content is displayed in a more effective manner for visitors
                and their computers.{' '}
              </li>
              <li>
                Allow visitors to participate in the interactive features of our service, if they wish; as part of our
                efforts to keep our site safe and secure.
              </li>
              <li>As part of pursuing our site’s safety and security. </li>
              <li>
                Measure or understand the effectiveness of the advertisements presented to visitors or to others, and
                providing relevant advertising materials.{' '}
              </li>
              <li>
                Provide suggestions and recommendations for visitors and for other site users about your relevant and
                interesting services.{' '}
              </li>
            </ul>
          </div>
          <h4 className="text-primary">Information we receive from other sources:</h4>
          <p>
            We may combine this information with the information you provide and the information we collect about you.
            We may also use this collected information for the purposes mentioned above (depending on the types of
            information we receive).{' '}
          </p>
          <h4 className="text-primary">Disclosure of Your Information:</h4>
          <div>
            <p>
              We may share any information provided by our visitors or collected through our website with our partners,
              third-party suppliers, analytical service providers, search engine providers and advertisers. Share this
              information with third-parties shall be as follows:
            </p>
            <ul>
              <li>
                If we are required to disclose or share your personal information for any legal obligations, to
                implement or enforce (the Timing System's terms and conditions) and to implement other agreements; or to
                protect Tamkeen Technologies rights, property, or integrity, and the safety of our clients or third
                parties.{' '}
              </li>
              <li>
                With service providers or contractors performing certain functions on our behalf or our partners, or on
                behalf of vendors, including processing the information provided by visitors on our website, performing
                third-party purchases, and other operations carried out through our website, or related to it or any of
                its parts, and providing or managing training courses, or any other aspects of the services we provide.{' '}
              </li>
              <li>
                For research and development purposes. However, we will exchange your personal information for this
                purpose in a manner that does not contravene the provisions of applicable laws and regulations and to
                the extent of the personal information required to achieve the purposes described at the time of
                collection.
              </li>
              <li>
                Provide networking opportunities between visitors and other users who may have similar interests or
                goals. For example, we may recommend specific entrepreneurs or link agency donors with potential agents.
                In such cases, we may use all collected information about you to determine who may be interested in
                communicating with you.{' '}
              </li>
              <li>
                Respond to subpoenas, court orders or other legal proceedings. Investigate, prevent or take action
                against illegal activities, suspected fraud activities, security or technical issues, enforce our Terms
                and Conditions ( the Timing System’s Terms and Conditions) or the Privacy Policy, and all otherwise
                required by Provisions of laws and regulations in force, protect our rights and property, or preserve
                the safety and interests of others.
              </li>
              <li>
                With our affiliates, or with their successors in case of mergers, acquisitions or reorganizations, for
                use in accordance with our Privacy Policy.{' '}
              </li>
              <li>Depending on the provided description, otherwise at the collection points or upon your consent. </li>
              <li>
                For integration with third party services. For example, the ability to host video and other content on
                YouTube and other sites that are not under our control.{' '}
              </li>
              <li>
                Exchange collected information that does not personally identify you with the public and third parties,
                including, but not limited to, researchers and business partners.
              </li>
            </ul>
          </div>
          <h4 className="text-primary">Consent to Data Processing Abroad:</h4>
          <p>
            By using our website, or providing us with information you are unequivocally aware and agree to the
            collection, use, disclosure, and preservation of information in Saudi Arabia and other countries and
            regions, for the purposes set out in this Privacy Policy in accordance with the Terms and Conditions (the
            Timing System's terms and conditions). You agree to collect, use, disclose and retain your personal
            information by us, as described in our Privacy Policy, including without limitation, the exchange of your
            personal information among us and third-parties, and with affiliates described in privacy Policy.
            <br />
            For avoiding any doubt, any consent regarding the right to exchange the information referred to in this
            paragraph includes your agreement to share your personal information with any jurisdiction that may provide
            a different level of privacy protection than that available in your country.
            <br />
            We will take all reasonable steps to ensure that your information is handled securely in accordance with our
            Privacy Policy. All information you provide to us is stored in the memory of our secure servers. Any
            payments will be encrypted using SSL technology. If we provide you with (or if you choose) a password to
            enable you to access certain sections of our site, you are responsible for keeping the confidentiality of
            this word. We recommend that you do not share your password with anyone.
            <br />
            Unfortunately, the transmission of information over the internet is not entirely secure. Although we will do
            our utmost to protect your personal information, we cannot guarantee the security of your information that
            will be transferred to our site. Accordingly, any such transfer shall be at your own risk. On our part, we
            will apply strict procedures and security features in an attempt to prevent any unauthorized access to these
            information.
            <br />
            If you do not agree to these terms, please, do not log in; browse register to subscribe to our site. If
            choose not to provide us with required certain information to provide with various services offered on our
            site; you will not be able to create a private account, consequently we will not be able to provide you with
            those services.
          </p>

          <h4 className="text-primary">Visitor Rights:</h4>
          <p>
            Visitors have the right to ask us not to use their personal information for marketing purposes. Normally, we
            will inform them (before proceeding to collect their information) if we intend to use their information or
            disclose this information to any third party for such purposes. They may use their right to prevent the
            processing of this information by checking the specified boxes provided on the forms that used to collect
            their information. Also, they can use this right at any time by contacting us at:
            <br />
            <a href="mailto:support_Appointment@tamkeentech.sa">support_Appointment@tamkeentech.sa</a>
            <br />
            Occasionally, our website may include links to and from network sites, advertisers or affiliated companies
            of our partners; if you want to follow any link to access any of these sites, note that these websites have
            their own privacy policies and we do not bear any responsibility or consequence related to these policies.
            Thus, kindly, read these policies before providing any personal information to these websites.
          </p>
        </div>
        <Footer />
      </>
    );
  };

  return (
    <section className="privacy-policy pb-0">
      <div>{isAR ? ar() : en()}</div>
    </section>
  );
};

export default PrivacyPolicy;
