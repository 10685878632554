import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import toggleStylesheet from './utils/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './utils';
import { ROLES, DEFAULT_LANGUAGE } from './constants';
import { setLanguage } from './lang';
import { PAGE_TITLE_KEY } from './routes';

const Main = props => {
  const [currentPage, setCurrentPage] = useState(getItemFromStore('currentPage', PAGE_TITLE_KEY.DASHBOARD));
  const [brand, setBrand] = useState(getItemFromStore('brand', {}));
  const [stores, setStores] = useState(getItemFromStore('stores', []));
  const [selectedStore, setSelectedStore] = useState(getItemFromStore('selectedStore', {}));
  const [user, setUser] = useState(getItemFromStore('user', {}));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', true));
  const [lang, setLangInState] = useState(getItemFromStore('lang', DEFAULT_LANGUAGE));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
  const [isTopNav, setIsTopNav] = useState(getItemFromStore('isTopNav', false));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false)
  );
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);

  const toggleLang = () => {
    const nextLanguage = lang === 'ar' ? 'en' : 'ar';
    setIsRTL(nextLanguage === 'ar');
    setLangInState(nextLanguage);
    setLanguage(nextLanguage);
  };

  const clearContext = () => {
    setBrand({});
    setSelectedStore({});
    setStores([]);
    setUser({});
  };
  const value = {
    currentPage,
    user,
    isAdmin: user?.roles?.some(role => role?.name === ROLES.ADMIN),
    isRTL,
    toggleLang,
    isDark,
    brand,
    setBrand,
    stores,
    setStores,
    selectedStore,
    setCurrentPage,
    setSelectedStore,
    setUser,
    isTopNav,
    currency,
    setIsDark,
    setIsTopNav,
    setCurrency,
    toggleModal,
    showBurgerMenu,
    isOpenSidePanel,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    clearContext,
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setItemToStore('currentPage', currentPage || '""');
  }, [currentPage]);

  useEffect(() => {
    setItemToStore('user', user);
  }, [user]);

  useEffect(() => {
    setItemToStore('brand', brand);
  }, [brand]);

  useEffect(() => {
    setItemToStore('selectedStore', selectedStore);
  }, [selectedStore]);

  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    setItemToStore('isTopNav', isTopNav);
    // eslint-disable-next-line
  }, [isTopNav]);

  useEffect(() => {
    setItemToStore('lang', lang);
    // eslint-disable-next-line
  }, [lang]);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light,
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
