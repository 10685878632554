import React, { useState, useCallback } from "react";
import Shifts from "../../components/scheduleManagment/Shifts";
import Tabs from "../../components/common/tabs";
import Holidays from "../../components/scheduleManagment/Holidays";
import { lang } from "../../lang";

export default () => {
  const [tab, setTab] = useState(0);

  const onTabChange = useCallback((index) => {
    setTab(index);
  }, []);

  return (
    <div className="schedule">
      <Tabs tabs={[lang.shifts, lang.holidays]} selectedTabIndex={tab} className="mb-2" onTabChange={onTabChange} />
      <div className="line mb-4 mx-3" />
      {tab === 0 ? <Shifts /> : <Holidays />}
    </div>
  );
};
