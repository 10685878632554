import React, { Fragment, createRef } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { Link } from 'react-router-dom';
import { lang, isEN } from '../../lang';
import TableActionButton from './TableActionButton';

const StaffsTable = ({ staffs, onDeleteClicked, onResetClicked, staffsMeta, setPage, pageSize, setPageSize }) => {
  const prepareData = () =>
    staffs?.map(e => ({
      ...e,
      action: (
        <TableActionButton
          id={e?.id}
          email={e?.email}
          onDeleteClicked={onDeleteClicked}
          onResetClicked={onResetClicked}
        />
      ),
    }));

  const columns = [
    {
      dataField: 'first_name',
      text: lang.label.name,
      formatter: nameFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
      sort: true,
    },
    {
      dataField: 'mobile',
      text: lang.label.mobile,
      // formatter: badgeFormatter,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'email',
      text: lang.label.email,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'action',
      // text: lang.moderators.stores,
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
  ];

  const options = {
    custom: true,
    sizePerPage: pageSize,
    totalSize: staffsMeta?.total,
  };
  const handleNextPage = () => {
    setPage(staffsMeta?.current_page + 1);
  };

  const handlePrevPage = () => {
    setPage(staffsMeta?.current_page - 1);
  };

  const handleViewAll = () => {
    setPage(1);
    setPageSize(1000);
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => {
        const lastIndex = staffsMeta?.current_page * paginationProps.sizePerPage;

        return (
          <Fragment>
            <div className="table-responsive">
              <BootstrapTable
                ref={table}
                bootstrap4
                keyField="id"
                data={prepareData() || []}
                columns={columns}
                bordered={false}
                classes=" table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                rowClasses="btn-reveal-trigger border-top border-200"
                headerClasses="bg-200 text-900 border-y border-200"
                {...paginationTableProps}
              />
            </div>
            <Row noGutters className="px-1 py-3">
              <Col className="pl-3 fs--1">
                <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                <ButtonIcon
                  color="link"
                  size="sm"
                  icon={`chevron-${isEN ? 'right' : 'left'}`}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                  className="px-0 font-weight-semi-bold"
                  onClick={handleViewAll}
                >
                  {lang.btn.viewAll}
                </ButtonIcon>
              </Col>
              <Col xs="auto" className="pr-3">
                <Button
                  color={staffsMeta?.current_page === 1 ? 'light' : 'primary'}
                  size="sm"
                  onClick={handlePrevPage}
                  disabled={staffsMeta?.current_page === 1}
                  className="px-4"
                >
                  {lang.btn.previous}
                </Button>
                <Button
                  color={lastIndex >= staffsMeta?.total ? 'light' : 'primary'}
                  size="sm"
                  onClick={handleNextPage}
                  disabled={lastIndex >= staffsMeta?.total}
                  className="px-4 ml-2"
                >
                  {lang.btn.next}
                </Button>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </PaginationProvider>
  );
};

export default StaffsTable;

const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
  <span>
    {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
  </span>
);

const nameFormatter = (name, data) => (
  <Link to={`/staffs/edit/${data.id}`} className="font-weight-semi-bold">
    {name}
  </Link>
);

let table = createRef();
