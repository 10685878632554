import React, { useCallback } from 'react';
import { IconSearch } from '../../utils/icons';

export const Search = ({ onChange, value }) => {
  const onChangeText = useCallback(
    e => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div className="position-relative d-flex align-items-center">
      <input
        value={value}
        onChange={onChangeText}
        className="search-input border-0"
        type-="text"
        placeholder={'Search'}
      />
      <IconSearch className="search-icon position-absolute" size={12} />
    </div>
  );
};
