import React from 'react';
import PropTypes, { array, string } from 'prop-types';
import { isIterableArray } from '../../utils';
import { isAR } from '../../lang';

const defaultSort = () => (isAR ? -1 : 1);

const TagsSorter = ({ children, sort = defaultSort, ...props }) => {
  return <div {...props}>{isIterableArray(children) ? children.slice().sort(sort) : children}</div>;
};

TagsSorter.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([array, string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
};

TagsSorter.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false,
};

export default TagsSorter;
