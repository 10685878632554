import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ tabs = [], closeTab, addTab, onTabChange, selectedTab, className }) => {
  return (
    <ul className={`nav mx-4 ${className || ''}`} id="myTab" role="tablist">
      {tabs.map((tab, index) => (
        <li key={tab.name + index} className={classNames('nav-item tab-item ', { active: selectedTab === index })}>
          <button className="nav-link border-0" onClick={() => onTabChange(index)}>
            {tab.name}
          </button>
          {!tab.hideCloseButton && tabs.length > 1 ? (
            <button
              className="cross position-absolute text-muted border-0 h-100 d-flex justify-content-center align-items-center"
              onClick={() => closeTab(index)}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          ) : null}
        </li>
      ))}
      <li className="tab-item">
        <button className="nav-link border-0" onClick={addTab}>
          <h4 className="m-0">+</h4>
        </button>
      </li>
    </ul>
  );
};
