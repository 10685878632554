import React, { useEffect, useCallback, useRef, memo, useMemo } from 'react';
import { isAR, lang } from '../../lang';
import { d3, useWindowRatio } from '../../utils';
import { theme } from '../../constants';
import { useState } from 'react';

const graph_width_init = 600;
const graph_height_init = 230;
const bar_height = 12;
const bar_indent = 5;
const tooltip_width = 180;
const tooltip_height = 60;
const margin = { left: 70, bottom: 15, right: 15, top: 0 };

export const ResponseTimeGraph = memo(({ data = [], min = 0, barColor = theme.primaryColor }) => {
  const svgRef = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  const margin_left = useMemo(
    () => (Math.max(...data.map(d => d[`name${isAR ? '' : '_en'}`].length)) > 18 ? 100 : 70),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isAR]
  );
  const [graph_width, setGraphWidth] = useState(graph_width_init);
  const [graph_height, setGraphHeight] = useState(graph_height_init);

  const info = useWindowRatio();

  useEffect(() => {
    if (info.ratio === '21:9') {
      setGraphWidth(550);
      info.height > 750 ? setGraphHeight(190) : setGraphHeight(170);
    }
    if (info.ratio === '16:9') {
      info.height > 750 ? setGraphWidth(600) : setGraphWidth(500);
      setGraphHeight(200);
    }
    if (info.ratio === '16:10') {
      setGraphWidth(400);
      setGraphHeight(160);
    }
  }, [info]);

  const max = useMemo(() => {
    let max = d3.max(data, d => d.value);
    return Math.max(min, max);
  }, [data, min]);

  const xScale = useCallback(
    d3
      .scaleLinear()
      .domain([0, max])
      .range([10, graph_width - (margin_left + margin.right)]),
    [max, graph_width, margin_left]
  );

  const yScale = useCallback(
    d3
      .scaleLinear()
      .domain([0, data.length])
      .range([0, graph_height]),
    [data, graph_height]
  );

  const opacityScale = useCallback(
    d3
      .scaleLinear()
      .domain([0, max])
      .range([0.2, 0.7]),
    [max]
  );

  const xAxis = useCallback(
    d3
      .axisBottom()
      .scale(xScale)
      .tickFormat((d, i) => {
        return `${d} ${lang.dashboardItems.hr}`;
      }),
    [xScale]
  );

  const yAxis = useCallback(
    d3
      .axisLeft()
      .scale(yScale)
      .tickFormat(i => {
        const name = (data[i] ? (isAR ? data[i].name : data[i].name_en.toLowerCase()) : '').split(' ');
        return (name[0]) + (name.length > 2 ? '...' : ' ') + (name.length > 1 ? name[name.length - 1] : '');
      }),
    [yScale, data]
  );

  const showTip = useCallback((e, d) => {
    d3.select('#tooltip')
      .html(
        `<div class="small m-0"><small>${lang.dashboardItems.autoAccept} </small><div class="circle d-inline-block ${
          d.auto_accept ? 'enabled' : ''
        }" > 
        </div>
        </div>
        <div class="small m-0">
          <small class="font-weight-bold">${Math.floor(d.value)} ${lang.dashboardItems.hr} ${Math.round(
          (d.value - Math.floor(d.value)) * 60
        )} ${lang.dashboardItems.min}</small>
        </div>
        <div class="small m-0">
        <small>${isAR ? d.name : d.name_en}</small>
        </div>
        `
      )
      .style('left', `${e.pageX - tooltip_width / 2}px`)
      .style('top', `${e.pageY - tooltip_height - 15}px`)
      .style('display', 'block');
  }, []);

  const hideTip = useCallback(() => d3.select('#tooltip').style('display', 'none'), []);

  useEffect(() => {
    // init tootip
    d3.select('body')
      .append('div')
      .attr('id', 'tooltip')
      .style('display', 'none')
      .style('width', `${tooltip_width}px`)
      .style('height', `${tooltip_height}px`);
  }, []);

  useEffect(() => {
    const trans = d3
      .select(svgRef.current)
      .transition()
      .duration(1000);

    d3.select(svgRef.current)
      .selectAll('rect.data')
      .data(data, d => d.id)
      .join(
        enter => {
          return enter
            .append('rect')
            .attr('transform', (d, i) => `translate(${margin_left}, ${yScale(i) - bar_indent})`)
            .attr('height', bar_height)
            .attr('width', 0)
            .attr('fill', barColor)
            .attr('fill-opacity', opacityScale(0));
        },
        update => update,
        exit => {
          exit
            .transition(trans)
            .attr('width', 0)
            .attr('transform', (d, i) => `translate(${margin_left}, ${yScale(i) - bar_indent})`)
            .attr('fill', barColor)
            .attr('fill-opacity', opacityScale(0));
        }
      )
      .classed('data', true)
      .on('mouseover', showTip)
      .on('mousemove', showTip)
      .on('mouseout', hideTip)
      .transition(trans)
      .attr('transform', (d, i) => `translate(${margin_left}, ${yScale(i) - bar_indent})`)
      .attr('height', bar_height)
      .attr('width', d => xScale(d.value))
      .attr('fill', barColor)
      .attr('fill-opacity', d => opacityScale(d.value))
      .attr('ry', 3)
      .attr('rx', 3);

    /* this is to hide the border radius from the left of the bars */
    d3.select(svgRef.current)
      .selectAll('rect.indent')
      .remove();

    d3.select(svgRef.current)
      .append('rect')
      .classed('indent', true)
      .attr('transform', (d, i) => `translate(${margin_left}, ${-bar_indent})`)
      .attr('width', 3)
      .attr('height', graph_height)
      .attr('fill', '#FFF');
    /* *** */

    xAxis.ticks(8);

    d3.select(xAxisRef.current).call(xAxis);
    d3.select(yAxisRef.current)
      .call(yAxis)
      .selectAll('.tick text')
      .style('text-anchor', 'center')
      .call(d3.helpers.wrapYLabel, margin_left - 12);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yAxis, xAxis, data, xScale, yScale, margin_left]);

  return (
    <svg
      style={{ transform: `rotateY(${isAR ? '180deg' : '0deg'})` }}
      viewBox={`0 0 ${graph_width} ${graph_height}`}
      ref={svgRef}
      className="response_time_graph"
    >
      <g className="x-axis" ref={xAxisRef} transform={`translate(${margin_left}, ${graph_height - margin.bottom})`} />
      <g className="y-axis" ref={yAxisRef} transform={`translate(${isAR ? 0 : -(margin_left - 23)}, 0)`} />
    </svg>
  );
});
