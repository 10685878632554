export default {
  STORES_HOURLY_APS: "{BASE}/owners/dashboard/stores/{id}/hourly_appointments",
  STORES_DAILY_APS: "{BASE}/owners/dashboard/stores/{id}/daily_appointments",
  STORES_MONTHLY_APS: "{BASE}/owners/dashboard/stores/{id}/monthly_appointments?month={month}",
  STORES_MOST_APS: "{BASE}/owners/dashboard/appointments?brand_id={brand_id}&from={from}",
  BRANDS_HOURLY_APS: "{BASE}/owners/dashboard/brands/{id}/hourly_appointments",
  BRANDS_DAILY_APS: "{BASE}/owners/dashboard/brands/{id}/daily_appointments",
  BRANDS_MONTHLY_APS: "{BASE}/owners/dashboard/brands/{id}/monthly_appointments?month={month}",
  BRANDS_MOST_STORES: "{BASE}/owners/dashboard/brands/{id}/most_stores_aps",
};
