import React from 'react';
import {
  faHome,
  faCity,
  faUserTie,
  faUserShield,
  faUsers,
  faShapes,
  faEnvelopeOpen,
  faCog,
  faFileAlt,
  faCheck,
  faEllipsisH,
  faSearch,
  faCaretRight,
  faCaretLeft,
  faSort,
  faSortUp,
  faSortDown,
  faTimes,
  faCaretDown,
  faUserFriends,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoutImg from '../assets/img/logout.png';
import ksaImg from '../assets/img/KSA.svg';
import usaImg from '../assets/img/USA.svg';
import GPS from '../assets/img/icons/gps.png';
import bellSVG from '../assets/img/icons/bell.svg';
import storeActivatedSVG from '../assets/img/icons/store_activated.svg';
import storeDeactivatedSVG from '../assets/img/icons/store_deactivated.svg';
import serviceActivatedSVG from '../assets/img/icons/service_activated.svg';
import serviceDeactivatedSVG from '../assets/img/icons/service_deactivated.svg';
import autoAcceptOnSVG from '../assets/img/icons/auto_accept_on.svg';
import autoAcceptOffSVG from '../assets/img/icons/auto_accept_off.svg';
import { isAR } from '../lang';

// FONTAWESOME
export const IconHome = () => <FontAwesomeIcon icon={faHome} size="1x" className="mx-2" />;
export const IconCity = () => <FontAwesomeIcon icon={faCity} size="1x" className="mx-2" />;
export const IconUserTie = () => <FontAwesomeIcon icon={faUserTie} size="1x" className="mx-2" />;
export const IconUsers = () => <FontAwesomeIcon icon={faUsers} size="1x" className="mx-2" />;
export const IconUserShield = () => <FontAwesomeIcon icon={faUserShield} size="1x" className="mx-2" />;
export const IconShapes = () => <FontAwesomeIcon icon={faShapes} size="1x" className="mx-2" />;
export const IconEnvelopeOpen = () => <FontAwesomeIcon icon={faEnvelopeOpen} size="1x" className="mx-2" />;
export const IconCog = () => <FontAwesomeIcon icon={faCog} size="1x" className="mx-2" />;
export const IconDocument = () => <FontAwesomeIcon icon={faFileAlt} size="1x" className="mx-2" />;
export const IconCalendarCheck = () => <FontAwesomeIcon icon={faCalendarCheck} size="1x" className="mx-2" />;
export const IconCalendar = () => <FontAwesomeIcon icon={faCalendarAlt} size="1x" className="mx-2" />;
export const IconCheck = () => <FontAwesomeIcon icon={faCheck} size="1x" className="mx-2" />;
export const IconSort = props => <FontAwesomeIcon icon={faSort} size="sm" {...props} />;
export const IconSortUp = props => <FontAwesomeIcon icon={faSortUp} size="sm" {...props} />;
export const IconSortDown = props => <FontAwesomeIcon icon={faSortDown} size="sm" {...props} />;
export const IconSearch = props => <FontAwesomeIcon icon={faSearch} size="1x" className="mx-2" {...props} />;
export const IconDown = props => <FontAwesomeIcon icon={faCaretDown} size="1x" className="mx-2" {...props} />;
export const IconEmployees = () => (
  <FontAwesomeIcon icon={faArrowUp} size="1x" className="mx-2" transform={{ rotate: isAR ? 30 : -30 }} />
);
export const IconStart = () => <FontAwesomeIcon icon={faUserFriends} size="1x" className="mx-2" />;
export const IconEllipses = ({ className, props }) => (
  <FontAwesomeIcon icon={faEllipsisH} size="1x" className={` ${className}`} {...props} />
);
export const IconNext = props => (
  <FontAwesomeIcon icon={isAR ? faCaretLeft : faCaretRight} size="1x" className="mx-2" {...props} />
);
export const IconClose = props => <FontAwesomeIcon icon={faTimes} size="1x" className="mx-2" {...props} />;
export const IconJumpNext = props => (
  <>
    <IconNext props={props} className="mx-0" size="sm" />
    <IconNext props={props} className="mx-0" size="sm" />
  </>
);
export const IconPrev = props => (
  <FontAwesomeIcon icon={isAR ? faCaretRight : faCaretLeft} size="1x" className="mx-2" {...props} />
);
export const IconJumpPrev = props => (
  <>
    <IconPrev props={props} className="mx-0" size="sm" />
    <IconPrev props={props} className="mx-0" size="sm" />
  </>
);

// ICOMOON
export const Icomoon = ({ name, style = {}, size, className = '' }) => {
  return <i className={`icon-${name} ${className}`} style={{ display: 'inline-block', fontSize: size, ...style }} />;
};

// SVG IMG
export const LogoutIcon = props => <img src={LogoutImg} alt="logout" {...props} />;
export const KSAIcon = props => <img src={ksaImg} alt="KSA" {...props} />;
export const USAIcon = props => <img src={usaImg} alt="USA" {...props} />;
export const GPSIcon = props => <img src={GPS} alt="GPS" {...props} />;

export const NotificationIcon = props => <img src={bellSVG} alt="autoOff" {...props} />;

export const StoreActivatedIcon = props => <img src={storeActivatedSVG} alt="storeActivated" {...props} />;
export const StoreDeactivatedIcon = props => <img src={storeDeactivatedSVG} alt="storeDeactivated" {...props} />;
export const ServiceActivatedIcon = props => <img src={serviceActivatedSVG} alt="serviceActivated" {...props} />;
export const ServiceDeactivatedIcon = props => <img src={serviceDeactivatedSVG} alt="serviceDeactivated" {...props} />;
export const AutoAcceptOnIcon = props => <img src={autoAcceptOnSVG} alt="autoOn" {...props} />;
export const AutoAcceptOffIcon = props => <img src={autoAcceptOffSVG} alt="autoOff" {...props} />;
