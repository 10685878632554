import React, { Fragment, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { isAR, lang } from '../../lang';
import logo from '../../assets/img/nawart_logo.png';
import app_store_en from '../../assets/img/badges/app_store_en.svg';
import google_play_en from '../../assets/img/badges/google_play_en.svg';
import app_store_ar from '../../assets/img/badges/app_store_ar.svg';
import google_play_ar from '../../assets/img/badges/google_play_ar.svg';
import { IS_DEVELOP, ENDUSER_DEVELOP_LINK, ENDUSER_STAGING_LINK, IS_PRODUCTION } from '../../constants';
import { useRouteMatch } from 'react-router-dom';

const Invitation = () => {
  const { params } = useRouteMatch();

  let applink = IS_DEVELOP ? ENDUSER_DEVELOP_LINK : ENDUSER_STAGING_LINK;
  applink = applink.replace('PATH', `invitation/${params.id}`);
  useEffect(() => {
    if (!IS_PRODUCTION) {
      try {
        window.location.replace(applink);
      } catch (err) {}
    }
  }, [applink]);

  return (
    <Fragment>
      <Card id="invitation-card" className="m-auto px-4 py-2 bg-primary">
        <CardBody className="fs--1">
          <>
            <div>
              <img className="logo" height={50} src={logo} alt="logo" />
            </div>
            <h4 className="text-white mt-4">الرجاء تحميل تطبيق مراجع لعرض الدعوة.</h4>
            <div className="flex-grow-1">
              <h5 className="text-white">{lang.yasser.landing.download.body}</h5>
              <div className="d-flex mt-6 justify-content-center">
                {/* <a
                  href={
                    IS_PRODUCTION ? 'https://play.google.com/store/apps/details?id=sa.gov.my.appointments' : applink
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                  <img src={isAR ? google_play_ar : google_play_en} alt="play" />
                {/* </a> */}
                {/* <a
                  href={IS_PRODUCTION ? 'https://apps.apple.com/sa/app/gov-sa-national-platform/id1516850299' : applink}
                  target="_blank"
                  rel="noopener noreferrer"
                > */}
                  <img
                    src={isAR ? app_store_ar : app_store_en}
                    className="ml-xl-4 ml-md-2 ml-sm-2 play"
                    alt="ios-app"
                  />
                {/* </a> */}
              </div>
            </div>
          </>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Invitation;
