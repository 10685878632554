import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import AppContext from '../context/Context';
import ManagerDashboard from '../screens/dashboard/ManagerDashboard';
import owner_dashboard from '../screens/dashboard/OwnerDashboard';
import AddStore from '../screens/stores/AddStore';
import Branches from '../screens/stores/Stores';
import EditStore from '../screens/stores/EditStore';
import Appointments from '../screens/appointments/Appointments';
import BrandInformation from '../screens/brand-information/BrandInformation';
import Schedule from '../screens/scheduleManagment/Schedule';
import Moderators from '../screens/moderators/Moderators';
import AddModerator from '../screens/moderators/AddModerator';
import EditModerator from '../screens/moderators/EditModeratorInfo';
import ResetModeratorAccount from '../screens/guards/ResetModeratorAccount';
import Guards from '../screens/guards/Guards';
import AddGuard from '../screens/guards/AddGuard';
import EditGuard from '../screens/guards/EditGuard';
import ProtectedRoute from './ProtectedRoute';
import Services from '../screens/services/Services';
import AddService from '../screens/services/AddService';
import EditService from '../screens/services/EditService';
import Invitations from '../screens/invitations/Invitations';
import AddInvitation from '../screens/invitations/AddInvitation';
import Configuration from '../screens/configuration/Configuration';
import UserManuals from '../screens/user-manuals/UserManuals';
import branchesActivity from '../screens/branchesActivity/BranchesActivityDetails';
import NotificationPage from '../screens/notifications/NotificationPage';
import ManagerInformation from '../screens/manager-information/ManagerInformation';
import Staffs from "../screens/staffs/Staffs"
import AddStaff from "../screens/staffs/AddStaff"
import EditStaff from '../screens/staffs/EditStaffInfo';

const DashboardLayout = ({ location }) => {
  const { isAdmin, selectedStore } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div className={'container'}>
        <NavbarVertical />
        <div className="content">
          <NavbarTop />
          <div className="routes-container pt-4">
            <Switch>
              <ProtectedRoute
                path="/dashboard"
                exact
                component={isAdmin && !selectedStore.id ? owner_dashboard : ManagerDashboard}
              />
              {/* Branch */}
              <ProtectedRoute path="/stores" exact component={Branches} />
              <ProtectedRoute path="/stores/edit/:id" exact component={EditStore} />
              <ProtectedRoute path="/store" exact component={EditStore} />
              <ProtectedRoute path="/stores/add" exact component={AddStore} />
              {/* Services */}
              <ProtectedRoute path="/services" exact component={Services} />
              <ProtectedRoute path="/services/add" exact component={AddService} />
              <ProtectedRoute path="/services/edit/:id" exact component={EditService} />
              {/* Moderators */}
              <ProtectedRoute path="/moderators" exact component={Moderators} />
              <ProtectedRoute path="/moderators/add" exact component={AddModerator} />
              <ProtectedRoute path="/moderators/edit/:id" exact component={EditModerator} />
              {/* Staff  */}
              <ProtectedRoute path="/staffs" exact component={Staffs} />
              <ProtectedRoute path="/staffs/add" exact component={AddStaff} />
              <ProtectedRoute path="/staffs/edit/:id" exact component={EditStaff} />
              {/* Guards */}
              <ProtectedRoute path="/guards" exact component={Guards} />
              <ProtectedRoute path="/guards/add" exact component={AddGuard} />
              <ProtectedRoute path="/guards/edit/:id" exact component={EditGuard} />
              <ProtectedRoute path="/guards/reset/:id" exact component={ResetModeratorAccount} />
              {/* <Route path="/guards/reset/:id" exact component={ResetModeratorAccount} /> */}
              {/* Schedule */}
              <ProtectedRoute path="/schedule" exact component={Schedule} />
              {/* <Route path="/schedule/update" exact component={UpdateBranch} />
              <Route path="/schedule/add" exact component={WizardLayoutSchedule} /> */}
              {/* Brand Information */}
              <ProtectedRoute path="/brand-information" exact component={BrandInformation} />
              <ProtectedRoute path="/manager-information" exact component={ManagerInformation} />
              {/* Manager */}
              {/* <Route path='/overview' exact component={SchedulesOverview} />
                <Route path='/schedule' exact component={CreateSchedule} />
                <Route path='/schedule/update' exact component={ScheduleSummary} /> */}
              {/* Schedule */}
              <ProtectedRoute path="/appointments" exact component={Appointments} />
              {/* invitations */}

              <ProtectedRoute path="/invitations" exact component={Invitations} />
              <ProtectedRoute path="/invitations/add" exact component={AddInvitation} />
              <ProtectedRoute path="/configuration" exact component={Configuration} />
              <ProtectedRoute path="/dashboard/branches-activity" exact component={branchesActivity} />
              {/*  ْUser-manual */}
              <ProtectedRoute path="/user-manuals" exact component={UserManuals} />
              {/*  Notification page */}
              <ProtectedRoute path="/notifications" exact component={NotificationPage} />
              {/*Redirect*/}
              <Redirect to="/errors/404" />
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
