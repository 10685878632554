import React, { Fragment } from "react";
import WizardInput from "../auth/wizard/WizardInput";
import { lang } from "../../lang";

const GuardCredentials = ({ register, errors }) => {
  return (
    <Fragment>
      {/* Clearing Chrome Auto Complete */}
      <div style={{ position: "absolute", top: -100000 }}>
        <input type="email" id="email" />
        <input id="password" type="password" />
      </div>
      <WizardInput
        label={`${lang.label.email}*`}
        name="guard_email"
        id="guard_email"
        innerRef={register({
          required: lang.requiredField,
          pattern: {
            value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
            message: lang.validationMessage.entervalidEmail,
          },
        })}
        errors={errors}
      />
      <WizardInput
        label={`${lang.label.password}*`}
        name="guard_password"
        id="guard_password"
        type="password"
        innerRef={register({
          required: lang.requiredField,
          minLength: {
            value: 8,
            message: lang.validationMessage.passwordLength,
          },
        })}
        errors={errors}
      />
    </Fragment>
  );
};

export default GuardCredentials;
