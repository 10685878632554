export * from './config';
export * from './theme';

export const DEFAULT_LANGUAGE = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : 'ar';

export const SORT_TYPES = { ASC: 'ASC', DESC: 'DESC', NONE: 'NONE' };

export const ROLES = { ADMIN: 'visitors-owner', MANAGER: 'Project Manager', STAFF: 'Staff', GUARD: 'Guard' };

export const APPOINTMENT_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CONFIRMED: 'confirmed',
  ARRIVED: 'arrived',
  NOT_ARRIVED: 'not_arrived',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  WALK_IN: 'walk_in',
};

export const INVITATION_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
};

export const REVALIDATE_TIME = 30000;
