const ENVIRONMENTS = {
  DEVELOP: "develop",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const IS_LOCAL = !process.env.REACT_APP_NODE_ENV;
export const IS_DEVELOP = process.env.REACT_APP_NODE_ENV === ENVIRONMENTS.DEVELOP;
export const IS_STAGING = process.env.REACT_APP_NODE_ENV === ENVIRONMENTS.STAGING;
export const IS_PRODUCTION = process.env.REACT_APP_NODE_ENV === ENVIRONMENTS.PRODUCTION;

export const ENDUSER_LOCAL_LINK = process.env.LOCAL_APP_URL;
export const ENDUSER_DEVELOP_LINK =
  ENDUSER_LOCAL_LINK || "exp://exp.host/@tamkeen/National-Platform/--/PATH?release-channel=develop";
export const ENDUSER_STAGING_LINK = "exp://exp.host/@tamkeen/National-Platform/--/PATH?release-channel=staging";
