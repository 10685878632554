import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RedirectToSSOLogin } from '../lib/auth/redirectToSSOLogin';
import { getSession } from '../lib/auth/session/session';
import { AuthHelpers } from '../utils';
export const useAuthMiddleware = () => {
  const history = useHistory();
  const location = useLocation();

  const handleAuth = async () => {
    const session = await getSession();
    const isExpired = new Date(session?.token_expires_at) < new Date();

    const isLoggingOut = localStorage.getItem('isLoggingOut') === 'true';
    if (isLoggingOut) {
      localStorage.removeItem('isLoggingOut');
      return;
    }
    if (!session || isExpired) {
      isExpired && AuthHelpers.logout();
      const redirectUrl = await RedirectToSSOLogin();
      window.location.href = redirectUrl;
      return;
    }

    if (location.pathname === '/') {
      history.push('/dashboard');
    }
  };
  useEffect(() => {
    handleAuth();
  }, [location, history]);

  return null;
};
