// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Input } from 'reactstrap';
import { ROLES } from '../../constants';
import AppContext from '../../context/Context';
import { lang, isAR } from '../../lang';
import { IconDown, search } from '../../utils';

const StoresDropdown = ({ stores }) => {
  stores.map(store => {
    store.name_en = store.name.en;
    store.name_ar = store.name.ar;
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedStores, setSearchedStores] = useState([]);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { setSelectedStore, selectedStore, user } = useContext(AppContext);
  const history = useHistory();

  const handleSearch = useCallback(
    e => {
      setSearchedStores(search(stores, ['name_ar', 'name_en'], e.target.value));
      setSearchText(e.target.value);
    },
    [stores]
  );

  const result = searchedStores.length ? searchedStores : stores;
  const title = selectedStore.id
    ? `${user?.role == ROLES.STAFF ? lang.staff : user?.role == ROLES.GUARD ? lang.guard : lang.manager} - ${
        selectedStore.name[isAR ? 'ar' : 'en']
      }`
    : lang.admin;
  return (
    <div>
      <span style={{ fontSize: 12 }}>
        {lang.loggedInAs + ' '}
        {title}
      </span>
      <Dropdown
        nav
        inNavbar
        isOpen={dropdownOpen}
        toggle={toggle}
        onMouseOver={() => {
          let windowWidth = window.innerWidth;
          windowWidth > 992 && setDropdownOpen(true);
        }}
        onMouseLeave={() => {
          let windowWidth = window.innerWidth;
          windowWidth > 992 && setDropdownOpen(false);
        }}
        className="d-inline"
        id="change-mode-dropdown"
      >
        {stores.length ? (
          <DropdownToggle nav className="d-inline mx-1 px-0">
            <IconDown size={7} className="appt-primary mx-2" />
          </DropdownToggle>
        ) : null}
        <DropdownMenu id="stores-dropdown">
          <div className="bg-white rounded-soft pb-2">
            {user?.roles?.some(role => role?.name === ROLES.ADMIN) ? (
              <>
                <Input className="search mb-2" placeholder={lang.search} onChange={handleSearch} value={searchText} />
                <DropdownItem
                  onClick={() => {
                    history.push('/dashboard');
                    setSelectedStore({});
                  }}
                  style={{ fontSize: '0.8rem' }}
                  active={!selectedStore.id}
                >
                  {lang.admin}
                </DropdownItem>
                <DropdownItem divider />
              </>
            ) : null}
            {result.map((store, index) => (
              <div key={store.id}>
                <DropdownItem
                  onClick={() => {
                    history.push('/dashboard');
                    setSelectedStore(store);
                  }}
                  style={{ fontSize: '0.8rem' }}
                  active={store.id === selectedStore.id}
                  id={index}
                >
                  {lang.manager} - {store.name[isAR ? 'ar' : 'en']}
                </DropdownItem>
                {index !== result.length - 1 ? <DropdownItem divider /> : null}
              </div>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default StoresDropdown;
