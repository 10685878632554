import React, { useCallback, useEffect, useState } from 'react';
import { lang } from '../../lang';
import { SectionTitle } from '../common/sectionTitle';
import { InformationItem } from './information_item';

export const PersonalInformationSection = ({ editMode, info }) => {

  return (
    <div>
      <SectionTitle className="mb-4">{lang.managerInformation.personalInformation}</SectionTitle>
      <InformationItem label={lang.label.email} value={info.email} className="mb-2" editMode={editMode} disabled />
      <InformationItem
        label={lang.label.mobile}
        value={info?.phone}
        className="mb-2"
        editMode={editMode}
        disabled
      />
    </div>
  );
};
