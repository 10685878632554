import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';

const NavbarVerticalMenuItem = ({ route }) => {
  const Icon = route.Icon;
  return (
    <Flex align="center">
      {Icon && (
        <span className="nav-link-icon mr-2 text-center d-flex align-items-center py-2">
          <Icon style={route.style} />
        </span>
      )}
      <span className="nav-link-text">{route.name}</span>
      {!!route.badge && (
        <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
          {route.badge.text}
        </Badge>
      )}
    </Flex>
  );
};

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.func,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
